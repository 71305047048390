/*! Core v3.0.7 */
@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #377dff;
  --indigo: #533be2;
  --purple: #4c2c92;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #fad776;
  --green: #6dbb30;
  --teal: #20c997;
  --cyan: #00dffc;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #377dff;
  --secondary: #6c757d;
  --success: #6dbb30;
  --info: #00dffc;
  --warning: #fad776;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #000000;
  --indigo: #533be2;
  --purple: #4c2c92;
  --pink: #e83e8c;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: $bodyFont;
  --font-family-monospace: $titleFont }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-weight: 400;
  color: #212529;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #377dff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0052ea;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.65625rem; }

h2, .h2 {
  font-size: 2.125rem; }

h3, .h3 {
  font-size: 1.85938rem; }

h4, .h4 {
  font-size: 1.59375rem; }

h5, .h5 {
  font-size: 1.32812rem; }

h6, .h6 {
  font-size: 1.0625rem; }

.lead {
  font-size: 1.32812rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.32812rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

.row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9fafb; }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #fbfcfc; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c7dbff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #97bbff; }

.table-hover .table-primary:hover {
  background-color: #aecbff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #aecbff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d6ecc5; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b3dc93; }

.table-hover .table-success:hover {
  background-color: #c8e6b2; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c8e6b2; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f6fe; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7aeefd; }

.table-hover .table-info:hover {
  background-color: #9ff3fe; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #9ff3fe; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef4d9; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fceab8; }

.table-hover .table-warning:hover {
  background-color: #fdedc0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fdedc0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8b8b8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-dark:hover {
  background-color: #ababab; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #ababab; }

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #cfc8f7; }

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #a699f0; }

.table-hover .table-indigo:hover {
  background-color: #bcb2f4; }
  .table-hover .table-indigo:hover > td,
  .table-hover .table-indigo:hover > th {
    background-color: #bcb2f4; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #cdc4e0; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #a291c6; }

.table-hover .table-purple:hover {
  background-color: #bfb3d7; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #bfb3d7; }

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #f9c9df; }

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #f39bc3; }

.table-hover .table-pink:hover {
  background-color: #f6b2d1; }
  .table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #f6b2d1; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #fbfcfc; }

.table-hover .table-active:hover {
  background-color: #eceff2; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #eceff2; }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e9ecef; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.56rem + 2px);
  padding: 0.78rem 1rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: rgba(55, 125, 255, 0.5);
    outline: 0;
    box-shadow: 0 0 25px rgba(55, 125, 255, 0.1); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.78rem + 1px);
  padding-bottom: calc(0.78rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(1.09rem + 1px);
  padding-bottom: calc(1.09rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.46rem + 1px);
  padding-bottom: calc(0.46rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.78rem 0;
  margin-bottom: 0;
  font-size: 1.0625rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.92rem + 2px);
  padding: 0.46rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 2.18rem + 2px);
  padding: 1.09rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6dbb30; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 0.92969rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #6dbb30;
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #6dbb30; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #6dbb30;
    box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.25); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #6dbb30; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #6dbb30;
    box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #6dbb30; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #6dbb30; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #6dbb30; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #87d14d;
  background-color: #87d14d; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6dbb30; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #6dbb30; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #6dbb30;
  box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 0.92969rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #dc3545;
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: flex;
      -webkit-box-flex: 0;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-flow: row wrap;
      -webkit-box-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.78rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.25s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #377dff;
  border-color: #377dff; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #1164ff;
    border-color: #045cff; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #1164ff;
    border-color: #045cff;
    box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #377dff;
    border-color: #377dff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #045cff;
    border-color: #0056f6; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 145, 255, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #6dbb30;
  border-color: #6dbb30; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #5b9d28;
    border-color: #559226; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #5b9d28;
    border-color: #559226;
    box-shadow: 0 0 0 0.2rem rgba(131, 197, 79, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #6dbb30;
    border-color: #6dbb30; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #559226;
    border-color: #4f8823; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(131, 197, 79, 0.5); }

.btn-info {
  color: #212529;
  background-color: #00dffc;
  border-color: #00dffc; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #00bdd6;
    border-color: #00b2c9; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #00bdd6;
    border-color: #00b2c9;
    box-shadow: 0 0 0 0.2rem rgba(5, 195, 220, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #00dffc;
    border-color: #00dffc; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #00b2c9;
    border-color: #00a7bc; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(5, 195, 220, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #fad776;
  border-color: #fad776; }
  .btn-warning:hover {
    color: #212529;
    background-color: #f9cc51;
    border-color: #f8c945; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #f9cc51;
    border-color: #f8c945;
    box-shadow: 0 0 0 0.2rem rgba(217, 188, 106, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fad776;
    border-color: #fad776; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #f8c945;
    border-color: #f8c538; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 188, 106, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: black;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-indigo {
  color: #ffffff;
  background-color: #533be2;
  border-color: #533be2; }
  .btn-indigo:hover {
    color: #ffffff;
    background-color: #3a20d7;
    border-color: #371ecc; }
  .btn-indigo:focus, .btn-indigo.focus {
    color: #ffffff;
    background-color: #3a20d7;
    border-color: #371ecc;
    box-shadow: 0 0 0 0.2rem rgba(109, 88, 230, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #ffffff;
    background-color: #533be2;
    border-color: #533be2; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #371ecc;
    border-color: #341dc1; }
    .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 88, 230, 0.5); }

.btn-purple {
  color: #ffffff;
  background-color: #4c2c92;
  border-color: #4c2c92; }
  .btn-purple:hover {
    color: #ffffff;
    background-color: #3d2375;
    border-color: #38206b; }
  .btn-purple:focus, .btn-purple.focus {
    color: #ffffff;
    background-color: #3d2375;
    border-color: #38206b;
    box-shadow: 0 0 0 0.2rem rgba(103, 76, 162, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #ffffff;
    background-color: #4c2c92;
    border-color: #4c2c92; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #38206b;
    border-color: #331d61; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 76, 162, 0.5); }

.btn-pink {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c; }
  .btn-pink:hover {
    color: #ffffff;
    background-color: #e41c78;
    border-color: #d91a72; }
  .btn-pink:focus, .btn-pink.focus {
    color: #ffffff;
    background-color: #e41c78;
    border-color: #d91a72;
    box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #ffffff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #d91a72;
    border-color: #ce196c; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5); }

.btn-outline-primary {
  color: #377dff;
  border-color: #377dff; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #377dff;
    border-color: #377dff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #377dff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #377dff;
    border-color: #377dff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #6dbb30;
  border-color: #6dbb30; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #6dbb30;
    border-color: #6dbb30; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #6dbb30;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #6dbb30;
    border-color: #6dbb30; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.5); }

.btn-outline-info {
  color: #00dffc;
  border-color: #00dffc; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #00dffc;
    border-color: #00dffc; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00dffc;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #00dffc;
    border-color: #00dffc; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5); }

.btn-outline-warning {
  color: #fad776;
  border-color: #fad776; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fad776;
    border-color: #fad776; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 215, 118, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fad776;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fad776;
    border-color: #fad776; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 215, 118, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #000000;
  border-color: #000000; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #000000;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-indigo {
  color: #533be2;
  border-color: #533be2; }
  .btn-outline-indigo:hover {
    color: #ffffff;
    background-color: #533be2;
    border-color: #533be2; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(83, 59, 226, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #533be2;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #533be2;
    border-color: #533be2; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(83, 59, 226, 0.5); }

.btn-outline-purple {
  color: #4c2c92;
  border-color: #4c2c92; }
  .btn-outline-purple:hover {
    color: #ffffff;
    background-color: #4c2c92;
    border-color: #4c2c92; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 44, 146, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #4c2c92;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #4c2c92;
    border-color: #4c2c92; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 44, 146, 0.5); }

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c; }
  .btn-outline-pink:hover {
    color: #ffffff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.btn-link {
  font-weight: 400;
  color: #377dff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0052ea;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.09rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.46rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.0625rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 25px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #377dff;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 25px;
  margin-bottom: 0;
  font-size: 0.92969rem;
  color: inherit;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 8px 25px;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
            flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.78rem 1rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2.18rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.09rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.92rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.46rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.59375rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.29688rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #377dff;
    background-color: #377dff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 25px rgba(55, 125, 255, 0.1); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: rgba(55, 125, 255, 0.5); }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #eaf1ff;
    border-color: #eaf1ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.29688rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.29688rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #377dff;
  background-color: #377dff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.29688rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(55, 125, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.56rem + 2px);
  padding: 0.78rem 2rem 0.78rem 1rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: rgba(55, 125, 255, 0.5);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.92rem + 2px);
  padding-top: 0.46rem;
  padding-bottom: 0.46rem;
  padding-left: 1rem;
  font-size: 1.0625rem; }

.custom-select-lg {
  height: calc(1.5em + 2.18rem + 2px);
  padding-top: 1.09rem;
  padding-bottom: 1.09rem;
  padding-left: 1rem;
  font-size: 1.0625rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.56rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.56rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: rgba(55, 125, 255, 0.5);
    box-shadow: 0 0 25px rgba(55, 125, 255, 0.1); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input:lang(ro) ~ .custom-file-label::after {
    content: "CautÄ"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.56rem + 2px);
  padding: 0.78rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.56rem);
    padding: 0.78rem 1rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 25px rgba(55, 125, 255, 0.1); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 25px rgba(55, 125, 255, 0.1); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 25px rgba(55, 125, 255, 0.1); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #eaf1ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #eaf1ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #377dff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #eaf1ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #5b94ff;
  background-color: #f4f8ff; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 0 0; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.30078rem;
  padding-bottom: 0.30078rem;
  margin-right: 0;
  font-size: 1.32812rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  -webkit-box-flex: 1;
          flex-grow: 1;
  -webkit-box-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.32812rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -webkit-box-flex: 1;
              flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-box-flex: 1;
              flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #dee2e6;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #002e84;
    text-decoration: none;
    background-color: #fbfcfc;
    border-color: #d0e0ff; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #377dff;
  background-color: #eaf1ff;
  border-color: #dae7ff; }

.page-item.disabled .page-link {
  color: #ced4da;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.32812rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.92969rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.25s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #377dff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #045cff; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 125, 255, 0.5); }

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #ffffff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #6dbb30; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #559226; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(109, 187, 48, 0.5); }

.badge-info {
  color: #212529;
  background-color: #00dffc; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #00b2c9; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #fad776; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #f8c945; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 215, 118, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #000000; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-indigo {
  color: #ffffff;
  background-color: #533be2; }
  a.badge-indigo:hover, a.badge-indigo:focus {
    color: #ffffff;
    background-color: #371ecc; }
  a.badge-indigo:focus, a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(83, 59, 226, 0.5); }

.badge-purple {
  color: #ffffff;
  background-color: #4c2c92; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #ffffff;
    background-color: #38206b; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(76, 44, 146, 0.5); }

.badge-pink {
  color: #ffffff;
  background-color: #e83e8c; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #ffffff;
    background-color: #d91a72; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.09375rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #1d4185;
  background-color: #d7e5ff;
  border-color: #c7dbff; }
  .alert-primary hr {
    border-top-color: #aecbff; }
  .alert-primary .alert-link {
    color: #142d5b; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #396119;
  background-color: #e2f1d6;
  border-color: #d6ecc5; }
  .alert-success hr {
    border-top-color: #c8e6b2; }
  .alert-success .alert-link {
    color: #21380f; }

.alert-info {
  color: #007483;
  background-color: #ccf9fe;
  border-color: #b8f6fe; }
  .alert-info hr {
    border-top-color: #9ff3fe; }
  .alert-info .alert-link {
    color: #004750; }

.alert-warning {
  color: #82703d;
  background-color: #fef7e4;
  border-color: #fef4d9; }
  .alert-warning hr {
    border-top-color: #fdedc0; }
  .alert-warning .alert-link {
    color: #5f522d; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-dark hr {
    border-top-color: #ababab; }
  .alert-dark .alert-link {
    color: black; }

.alert-indigo {
  color: #2b1f76;
  background-color: #ddd8f9;
  border-color: #cfc8f7; }
  .alert-indigo hr {
    border-top-color: #bcb2f4; }
  .alert-indigo .alert-link {
    color: #1c144e; }

.alert-purple {
  color: #28174c;
  background-color: #dbd5e9;
  border-color: #cdc4e0; }
  .alert-purple hr {
    border-top-color: #bfb3d7; }
  .alert-purple .alert-link {
    color: #130b25; }

.alert-pink {
  color: #792049;
  background-color: #fad8e8;
  border-color: #f9c9df; }
  .alert-pink hr {
    border-top-color: #f6b2d1; }
  .alert-pink .alert-link {
    color: #511531; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.79688rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #377dff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
          flex: 1; }

.list-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f4f8ff; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #7eabff;
    background-color: #f4f8ff;
    border-color: #e4eeff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #1d4185;
  background-color: #c7dbff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1d4185;
    background-color: #aecbff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1d4185;
    border-color: #1d4185; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #396119;
  background-color: #d6ecc5; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #396119;
    background-color: #c8e6b2; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #396119;
    border-color: #396119; }

.list-group-item-info {
  color: #007483;
  background-color: #b8f6fe; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #007483;
    background-color: #9ff3fe; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #007483;
    border-color: #007483; }

.list-group-item-warning {
  color: #82703d;
  background-color: #fef4d9; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #82703d;
    background-color: #fdedc0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #82703d;
    border-color: #82703d; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: black;
  background-color: #b8b8b8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: black;
    background-color: #ababab; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.list-group-item-indigo {
  color: #2b1f76;
  background-color: #cfc8f7; }
  .list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
    color: #2b1f76;
    background-color: #bcb2f4; }
  .list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #2b1f76;
    border-color: #2b1f76; }

.list-group-item-purple {
  color: #28174c;
  background-color: #cdc4e0; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #28174c;
    background-color: #bfb3d7; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #28174c;
    border-color: #28174c; }

.list-group-item-pink {
  color: #792049;
  background-color: #f9c9df; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #792049;
    background-color: #f6b2d1; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #792049;
    border-color: #792049; }

.close {
  float: right;
  font-size: 1.59375rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 300px;
  overflow: hidden;
  font-size: 0.935rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.3rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.92969rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: transparent; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: transparent; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: transparent; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: transparent; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 1rem;
  color: #ffffff;
  text-align: center;
  background-color: #6c757d;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: $bodyFont;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.92969rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: transparent; }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: transparent; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: transparent; }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: transparent; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: transparent; }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: transparent; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: transparent; }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: transparent; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -webkit-box-flex: 0;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #377dff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #045cff !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #6dbb30 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #559226 !important; }

.bg-info {
  background-color: #00dffc !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00b2c9 !important; }

.bg-warning {
  background-color: #fad776 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f8c945 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #000000 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-indigo {
  background-color: #533be2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #371ecc !important; }

.bg-purple {
  background-color: #4c2c92 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #38206b !important; }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #377dff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #6dbb30 !important; }

.border-info {
  border-color: #00dffc !important; }

.border-warning {
  border-color: #fad776 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #000000 !important; }

.border-indigo {
  border-color: #533be2 !important; }

.border-purple {
  border-color: #4c2c92 !important; }

.border-pink {
  border-color: #e83e8c !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important; }

.shadow {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #377dff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0052ea !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #6dbb30 !important; }

a.text-success:hover, a.text-success:focus {
  color: #4a7e20 !important; }

.text-info {
  color: #00dffc !important; }

a.text-info:hover, a.text-info:focus {
  color: #009bb0 !important; }

.text-warning {
  color: #fad776 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f7c12c !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #000000 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-indigo {
  color: #533be2 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #311bb6 !important; }

.text-purple {
  color: #4c2c92 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #2d1a57 !important; }

.text-pink {
  color: #e83e8c !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e9ecef; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e9ecef; } }

html, body {
  direction: ltr;
  /*text-align: left;*/
  min-height: 100vh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
    //noinspection CssInvalidPropertyValue
    -webkit-font-smoothing: "antialiased";
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0; }

a, textarea, input, button, *:focus, *:active {
  outline: none !important; }

textarea {
  resize: vertical;
  min-height: 36px;
  -webkit-transition: none;
  transition: none; }

section {
  word-break: break-word;
  position: relative; }

section table,
section .table-responsive {
  word-break: normal; }

select {
  /* keep it separated, do not combine */
  text-indent: 0.01px;
  text-overflow: ''; }

select,
input[type=number],
input[type=time],
input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none; }

/**

    Various Fixes

	This file should be as little as possible!
	If not, means "fixing own bugs using two files"

	:)

**/
.col {
  min-width: 0; }

/*

    Paragraphs

/* resets */
.bg-dark p {
  color: #adb5bd; }

.text-white p {
  color: #fff; }

.alert p {
  color: inherit; }

.bg-white-alt {
  /* used by sortable or other js that add/remove background */
  background-color: #fff; }

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2; }

.hr {
  display: block;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.article-format h2,
.article-format h3 {
  margin-bottom: 20px; }

.article-format blockquote + h2,
.article-format blockquote + h3,
.article-format p:not(:empty) + h2,
.article-format p:not(:empty) + h3 {
  margin-top: 50px; }

.article-format iframe,
.article-format .embed-responsive,
.article-format p,
.article-format img {
  margin-bottom: 25px; }

.article-format blockquote {
  font-style: italic;
  font-weight: 300;
  padding: 1.5rem;
  background-color: #f8f9fa;
  font-size: 1.25rem;
  margin: 0 0 2rem 0;
  border-radius: 0.25rem; }

.article-format blockquote p {
  margin-bottom: 0; }

/*

    Placeholder

*/
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #a1a8ae !important; }

:focus::-webkit-input-placeholder {
  color: #9dbfff !important; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a1a8ae !important; }

:focus:-moz-placeholder {
  color: #9dbfff !important; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a1a8ae !important; }

:focus::-moz-placeholder {
  color: #9dbfff !important; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #a1a8ae !important; }

:focus:-ms-input-placeholder {
  color: #9dbfff !important; }

textarea.text-white::-webkit-input-placeholder,
input.text-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important; }

textarea.text-white::-moz-placeholder,
input.text-white::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important; }

/**

	Example - set:
		--main-padding: 15px;

	Class Use:
		.theclass {
			padding: var(--main-padding);
		}

**/
/**

		- Width 					.w--*, .w-inherit, .w-auto
									.mw-100, .min-w-0,
									.w-*vh, .min-w-*vh, .max-w-*vh

**/
.w--1 {
  width: 1px !important; }

.w--2 {
  width: 2px !important; }

.w--3 {
  width: 3px !important; }

.w--4 {
  width: 4px !important; }

.w--5 {
  width: 5px !important; }

.w--6 {
  width: 6px !important; }

.w--8 {
  width: 8px !important; }

.w--10 {
  width: 10px !important; }

.w--15 {
  width: 15px !important; }

.w--20 {
  width: 20px !important; }

.w--25 {
  width: 25px !important; }

.w--30 {
  width: 30px !important; }

.w--35 {
  width: 35px !important; }

.w--40 {
  width: 40px !important; }

.w--50 {
  width: 50px !important; }

.w--60 {
  width: 60px !important; }

.w--70 {
  width: 70px !important; }

.w--80 {
  width: 80px !important; }

.w--90 {
  width: 90px !important; }

.w--100 {
  width: 100px !important; }

.max-w-100 {
  max-width: 100px !important; }

.w--120 {
  width: 120px !important; }

.max-w-120 {
  max-width: 120px !important; }

.w--150 {
  width: 150px !important; }

.max-w-150 {
  max-width: 150px !important; }

.w--180 {
  width: 180px !important; }

.max-w-180 {
  max-width: 180px !important; }

.w--200 {
  width: 200px !important; }

.max-w-200 {
  max-width: 200px !important; }

.w--220 {
  width: 220px !important; }

.max-w-220 {
  max-width: 220px !important; }

.w--250 {
  width: 250px !important; }

.max-w-250 {
  max-width: 250px !important; }

.w--280 {
  width: 280px !important; }

.max-w-280 {
  max-width: 280px !important; }

.w--300 {
  width: 300px !important; }

.max-w-300 {
  max-width: 300px !important; }

.w--350 {
  width: 350px !important; }

.max-w-350 {
  max-width: 350px !important; }

.w--400 {
  width: 400px !important; }

.max-w-400 {
  max-width: 400px !important; }

.w--450 {
  width: 450px !important; }

.max-w-450 {
  max-width: 450px !important; }

.w--500 {
  width: 500px !important; }

.max-w-500 {
  max-width: 500px !important; }

.w--600 {
  width: 600px !important; }

.max-w-600 {
  max-width: 600px !important; }

.w--700 {
  width: 700px !important; }

.max-w-700 {
  max-width: 700px !important; }

.w--800 {
  width: 800px !important; }

.max-w-800 {
  max-width: 800px !important; }

.w-inherit {
  width: inherit !important; }

.w-auto {
  min-width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.min-w-0 {
  /* used on .d-flex by text-truncate */
  min-width: 0; }

.w-33 {
  width: 33% !important; }

/*

		Viewport

	*/
.w-25vw {
  width: 25vw !important; }

.min-w-25vh {
  min-width: 25vw !important; }

.max-w-25vh {
  max-width: 25vw !important; }

.w-33vw {
  width: 33vw !important; }

.min-w-33vh {
  min-width: 33vw !important; }

.max-w-33vh {
  max-width: 33vw !important; }

.w-50vw {
  width: 50vw !important; }

.min-w-50vh {
  min-width: 50vw !important; }

.max-w-50vh {
  max-width: 50vw !important; }

.w-75vw {
  width: 75vw !important; }

.min-w-75vh {
  min-width: 75vw !important; }

.max-w-75vh {
  max-width: 75vw !important; }

.w-100vw {
  width: 100vw !important; }

.min-w-100vh {
  min-width: 100vw !important; }

.max-w-100vh {
  max-width: 100vw !important; }

/**

		- Height 					.h--*, .mh--*, .h-inherit, .h-auto, .mh-* (> 90)
									.h-*vh, .min-h-*vh, .max-h-*vh

**/
.h--1 {
  height: 1px !important; }

.max-h-1 {
  max-height: 1px !important; }

.h--2 {
  height: 2px !important; }

.max-h-2 {
  max-height: 2px !important; }

.h--3 {
  height: 3px !important; }

.max-h-3 {
  max-height: 3px !important; }

.h--4 {
  height: 4px !important; }

.max-h-4 {
  max-height: 4px !important; }

.h--5 {
  height: 5px !important; }

.max-h-5 {
  max-height: 5px !important; }

.h--6 {
  height: 6px !important; }

.max-h-6 {
  max-height: 6px !important; }

.h--8 {
  height: 8px !important; }

.max-h-8 {
  max-height: 8px !important; }

.h--10 {
  height: 10px !important; }

.max-h-10 {
  max-height: 10px !important; }

.h--15 {
  height: 15px !important; }

.max-h-15 {
  max-height: 15px !important; }

.h--20 {
  height: 20px !important; }

.max-h-20 {
  max-height: 20px !important; }

.h--25 {
  height: 25px !important; }

.max-h-25 {
  max-height: 25px !important; }

.h--30 {
  height: 30px !important; }

.max-h-30 {
  max-height: 30px !important; }

.h--35 {
  height: 35px !important; }

.max-h-35 {
  max-height: 35px !important; }

.h--40 {
  height: 40px !important; }

.max-h-40 {
  max-height: 40px !important; }

.h--50 {
  height: 50px !important; }

.max-h-50 {
  max-height: 50px !important; }

.h--60 {
  height: 60px !important; }

.max-h-60 {
  max-height: 60px !important; }

.h--70 {
  height: 70px !important; }

.max-h-70 {
  max-height: 70px !important; }

.h--80 {
  height: 80px !important; }

.max-h-80 {
  max-height: 80px !important; }

.h--90 {
  height: 90px !important; }

.max-h-90 {
  max-height: 90px !important; }

.h--100 {
  height: 100px !important; }

.max-h-100 {
  max-height: 100px !important; }

.h--120 {
  height: 120px !important; }

.max-h-120 {
  max-height: 120px !important; }

.h--150 {
  height: 150px !important; }

.max-h-150 {
  max-height: 150px !important; }

.h--180 {
  height: 180px !important; }

.max-h-180 {
  max-height: 180px !important; }

.h--200 {
  height: 200px !important; }

.max-h-200 {
  max-height: 200px !important; }

.h--220 {
  height: 220px !important; }

.max-h-220 {
  max-height: 220px !important; }

.h--250 {
  height: 250px !important; }

.max-h-250 {
  max-height: 250px !important; }

.h--280 {
  height: 280px !important; }

.max-h-280 {
  max-height: 280px !important; }

.h--300 {
  height: 300px !important; }

.max-h-300 {
  max-height: 300px !important; }

.h--350 {
  height: 350px !important; }

.max-h-350 {
  max-height: 350px !important; }

.h--400 {
  height: 400px !important; }

.max-h-400 {
  max-height: 400px !important; }

.h--450 {
  height: 450px !important; }

.max-h-450 {
  max-height: 450px !important; }

.h--500 {
  height: 500px !important; }

.max-h-500 {
  max-height: 500px !important; }

.h--600 {
  height: 600px !important; }

.max-h-600 {
  max-height: 600px !important; }

.h--700 {
  height: 700px !important; }

.max-h-700 {
  max-height: 700px !important; }

.h--800 {
  height: 800px !important; }

.max-h-800 {
  max-height: 800px !important; }

.min-h-100 {
  min-height: 100px !important; }

.min-h-150 {
  min-height: 150px !important; }

.min-h-200 {
  min-height: 200px !important; }

.min-h-250 {
  min-height: 250px !important; }

.min-h-300 {
  min-height: 300px !important; }

.h-inherit {
  height: inherit !important; }

.h-auto {
  min-height: auto !important; }

.h-25vh {
  height: 25vh !important; }

.min-h-25vh {
  min-height: 25vh !important; }

.max-h-25vh {
  max-height: 25vh !important; }

.h-33vh {
  height: 33vh !important; }

.min-h-33vh {
  min-height: 33vh !important; }

.max-h-33vh {
  max-height: 33vh !important; }

.h-50vh {
  height: 50vh !important; }

.min-h-50vh {
  min-height: 50vh !important; }

.max-h-50vh {
  max-height: 50vh !important; }

.h-75vh {
  height: 75vh !important; }

.min-h-75vh {
  min-height: 75vh !important; }

.max-h-75vh {
  max-height: 75vh !important; }

.h-100vh {
  height: 100vh !important; }

.min-h-100vh {
  min-height: 100vh !important; }

.max-h-100vh {
  max-height: 100vh !important; }

@media only screen and (max-width: 768px) {
  .max-h-1-xs {
    max-height: 1px !important; }
  .max-h-2-xs {
    max-height: 2px !important; }
  .max-h-3-xs {
    max-height: 3px !important; }
  .max-h-4-xs {
    max-height: 4px !important; }
  .max-h-5-xs {
    max-height: 5px !important; }
  .max-h-6-xs {
    max-height: 6px !important; }
  .max-h-8-xs {
    max-height: 8px !important; }
  .max-h-10-xs {
    max-height: 10px !important; }
  .max-h-15-xs {
    max-height: 15px !important; }
  .max-h-20-xs {
    max-height: 20px !important; }
  .max-h-25-xs {
    max-height: 25px !important; }
  .max-h-30-xs {
    max-height: 30px !important; }
  .max-h-35-xs {
    max-height: 35px !important; }
  .max-h-40-xs {
    max-height: 40px !important; }
  .max-h-50-xs {
    max-height: 50px !important; }
  .max-h-60-xs {
    max-height: 60px !important; }
  .max-h-70-xs {
    max-height: 70px !important; }
  .max-h-80-xs {
    max-height: 80px !important; }
  .max-h-90-xs {
    max-height: 90px !important; }
  .max-h-100-xs {
    max-height: 100px !important; }
  .max-h-120-xs {
    max-height: 120px !important; }
  .max-h-150-xs {
    max-height: 150px !important; }
  .max-h-180-xs {
    max-height: 180px !important; }
  .max-h-200-xs {
    max-height: 200px !important; }
  .max-h-220-xs {
    max-height: 220px !important; }
  .max-h-250-xs {
    max-height: 250px !important; }
  .max-h-280-xs {
    max-height: 280px !important; }
  .max-h-300-xs {
    max-height: 300px !important; }
  .max-h-350-xs {
    max-height: 350px !important; }
  .max-h-400-xs {
    max-height: 400px !important; }
  .max-h-450-xs {
    max-height: 450px !important; }
  .max-h-500-xs {
    max-height: 500px !important; }
  .max-h-600-xs {
    max-height: 600px !important; }
  .max-h-700-xs {
    max-height: 700px !important; }
  .max-h-800-xs {
    max-height: 800px !important; } }

/**

		- Paddings 					.p--*, .pt--*, .pr--*, .pb--*, .pl--*
									.p-auto, .p-inherit

**/
.p--0 {
  padding: 0px !important; }

.p--1 {
  padding: 1px !important; }

.p--2 {
  padding: 2px !important; }

.p--3 {
  padding: 3px !important; }

.p--4 {
  padding: 4px !important; }

.p--5 {
  padding: 5px !important; }

.p--6 {
  padding: 6px !important; }

.p--7 {
  padding: 7px !important; }

.p--8 {
  padding: 8px !important; }

.p--9 {
  padding: 9px !important; }

.p--10 {
  padding: 10px !important; }

.p--12 {
  padding: 12px !important; }

.p--15 {
  padding: 15px !important; }

.p--18 {
  padding: 18px !important; }

.p--20 {
  padding: 20px !important; }

.p--22 {
  padding: 22px !important; }

.p--25 {
  padding: 25px !important; }

.p--30 {
  padding: 30px !important; }

.p--35 {
  padding: 35px !important; }

.p--40 {
  padding: 40px !important; }

.p--45 {
  padding: 45px !important; }

.p--50 {
  padding: 50px !important; }

.p--60 {
  padding: 60px !important; }

.p--70 {
  padding: 70px !important; }

.p--80 {
  padding: 80px !important; }

.p--90 {
  padding: 90px !important; }

.p--100 {
  padding: 100px !important; }

.p--120 {
  padding: 120px !important; }

.p--150 {
  padding: 150px !important; }

.p--180 {
  padding: 180px !important; }

.p--200 {
  padding: 200px !important; }

.p--250 {
  padding: 250px !important; }

.p--280 {
  padding: 280px !important; }

.p--300 {
  padding: 300px !important; }

.p--350 {
  padding: 350px !important; }

.p--400 {
  padding: 400px !important; }

.p--450 {
  padding: 450px !important; }

.p--500 {
  padding: 500px !important; }

.pt--0 {
  padding-top: 0px !important; }

.pt--1 {
  padding-top: 1px !important; }

.pt--2 {
  padding-top: 2px !important; }

.pt--3 {
  padding-top: 3px !important; }

.pt--4 {
  padding-top: 4px !important; }

.pt--5 {
  padding-top: 5px !important; }

.pt--6 {
  padding-top: 6px !important; }

.pt--7 {
  padding-top: 7px !important; }

.pt--8 {
  padding-top: 8px !important; }

.pt--9 {
  padding-top: 9px !important; }

.pt--10 {
  padding-top: 10px !important; }

.pt--12 {
  padding-top: 12px !important; }

.pt--15 {
  padding-top: 15px !important; }

.pt--18 {
  padding-top: 18px !important; }

.pt--20 {
  padding-top: 20px !important; }

.pt--22 {
  padding-top: 22px !important; }

.pt--25 {
  padding-top: 25px !important; }

.pt--30 {
  padding-top: 30px !important; }

.pt--35 {
  padding-top: 35px !important; }

.pt--40 {
  padding-top: 40px !important; }

.pt--45 {
  padding-top: 45px !important; }

.pt--50 {
  padding-top: 50px !important; }

.pt--60 {
  padding-top: 60px !important; }

.pt--70 {
  padding-top: 70px !important; }

.pt--80 {
  padding-top: 80px !important; }

.pt--90 {
  padding-top: 90px !important; }

.pt--100 {
  padding-top: 100px !important; }

.pt--120 {
  padding-top: 120px !important; }

.pt--150 {
  padding-top: 150px !important; }

.pt--180 {
  padding-top: 180px !important; }

.pt--200 {
  padding-top: 200px !important; }

.pt--250 {
  padding-top: 250px !important; }

.pt--280 {
  padding-top: 280px !important; }

.pt--300 {
  padding-top: 300px !important; }

.pt--350 {
  padding-top: 350px !important; }

.pt--400 {
  padding-top: 400px !important; }

.pt--450 {
  padding-top: 450px !important; }

.pt--500 {
  padding-top: 500px !important; }

.pr--0 {
  padding-right: 0px !important; }

.pr--1 {
  padding-right: 1px !important; }

.pr--2 {
  padding-right: 2px !important; }

.pr--3 {
  padding-right: 3px !important; }

.pr--4 {
  padding-right: 4px !important; }

.pr--5 {
  padding-right: 5px !important; }

.pr--6 {
  padding-right: 6px !important; }

.pr--7 {
  padding-right: 7px !important; }

.pr--8 {
  padding-right: 8px !important; }

.pr--9 {
  padding-right: 9px !important; }

.pr--10 {
  padding-right: 10px !important; }

.pr--12 {
  padding-right: 12px !important; }

.pr--15 {
  padding-right: 15px !important; }

.pr--18 {
  padding-right: 18px !important; }

.pr--20 {
  padding-right: 20px !important; }

.pr--22 {
  padding-right: 22px !important; }

.pr--25 {
  padding-right: 25px !important; }

.pr--30 {
  padding-right: 30px !important; }

.pr--35 {
  padding-right: 35px !important; }

.pr--40 {
  padding-right: 40px !important; }

.pr--45 {
  padding-right: 45px !important; }

.pr--50 {
  padding-right: 50px !important; }

.pr--60 {
  padding-right: 60px !important; }

.pr--70 {
  padding-right: 70px !important; }

.pr--80 {
  padding-right: 80px !important; }

.pr--90 {
  padding-right: 90px !important; }

.pr--100 {
  padding-right: 100px !important; }

.pr--120 {
  padding-right: 120px !important; }

.pr--150 {
  padding-right: 150px !important; }

.pr--180 {
  padding-right: 180px !important; }

.pr--200 {
  padding-right: 200px !important; }

.pr--250 {
  padding-right: 250px !important; }

.pr--280 {
  padding-right: 280px !important; }

.pr--300 {
  padding-right: 300px !important; }

.pr--350 {
  padding-right: 350px !important; }

.pr--400 {
  padding-right: 400px !important; }

.pr--450 {
  padding-right: 450px !important; }

.pr--500 {
  padding-right: 500px !important; }

.pb--0 {
  padding-bottom: 0px !important; }

.pb--1 {
  padding-bottom: 1px !important; }

.pb--2 {
  padding-bottom: 2px !important; }

.pb--3 {
  padding-bottom: 3px !important; }

.pb--4 {
  padding-bottom: 4px !important; }

.pb--5 {
  padding-bottom: 5px !important; }

.pb--6 {
  padding-bottom: 6px !important; }

.pb--7 {
  padding-bottom: 7px !important; }

.pb--8 {
  padding-bottom: 8px !important; }

.pb--9 {
  padding-bottom: 9px !important; }

.pb--10 {
  padding-bottom: 10px !important; }

.pb--12 {
  padding-bottom: 12px !important; }

.pb--15 {
  padding-bottom: 15px !important; }

.pb--18 {
  padding-bottom: 18px !important; }

.pb--20 {
  padding-bottom: 20px !important; }

.pb--22 {
  padding-bottom: 22px !important; }

.pb--25 {
  padding-bottom: 25px !important; }

.pb--30 {
  padding-bottom: 30px !important; }

.pb--35 {
  padding-bottom: 35px !important; }

.pb--40 {
  padding-bottom: 40px !important; }

.pb--45 {
  padding-bottom: 45px !important; }

.pb--50 {
  padding-bottom: 50px !important; }

.pb--60 {
  padding-bottom: 60px !important; }

.pb--70 {
  padding-bottom: 70px !important; }

.pb--80 {
  padding-bottom: 80px !important; }

.pb--90 {
  padding-bottom: 90px !important; }

.pb--100 {
  padding-bottom: 100px !important; }

.pb--120 {
  padding-bottom: 120px !important; }

.pb--150 {
  padding-bottom: 150px !important; }

.pb--180 {
  padding-bottom: 180px !important; }

.pb--200 {
  padding-bottom: 200px !important; }

.pb--250 {
  padding-bottom: 250px !important; }

.pb--280 {
  padding-bottom: 280px !important; }

.pb--300 {
  padding-bottom: 300px !important; }

.pb--350 {
  padding-bottom: 350px !important; }

.pb--400 {
  padding-bottom: 400px !important; }

.pb--450 {
  padding-bottom: 450px !important; }

.pb--500 {
  padding-bottom: 500px !important; }

.pl--0 {
  padding-left: 0px !important; }

.pl--1 {
  padding-left: 1px !important; }

.pl--2 {
  padding-left: 2px !important; }

.pl--3 {
  padding-left: 3px !important; }

.pl--4 {
  padding-left: 4px !important; }

.pl--5 {
  padding-left: 5px !important; }

.pl--6 {
  padding-left: 6px !important; }

.pl--7 {
  padding-left: 7px !important; }

.pl--8 {
  padding-left: 8px !important; }

.pl--9 {
  padding-left: 9px !important; }

.pl--10 {
  padding-left: 10px !important; }

.pl--12 {
  padding-left: 12px !important; }

.pl--15 {
  padding-left: 15px !important; }

.pl--18 {
  padding-left: 18px !important; }

.pl--20 {
  padding-left: 20px !important; }

.pl--22 {
  padding-left: 22px !important; }

.pl--25 {
  padding-left: 25px !important; }

.pl--30 {
  padding-left: 30px !important; }

.pl--35 {
  padding-left: 35px !important; }

.pl--40 {
  padding-left: 40px !important; }

.pl--45 {
  padding-left: 45px !important; }

.pl--50 {
  padding-left: 50px !important; }

.pl--60 {
  padding-left: 60px !important; }

.pl--70 {
  padding-left: 70px !important; }

.pl--80 {
  padding-left: 80px !important; }

.pl--90 {
  padding-left: 90px !important; }

.pl--100 {
  padding-left: 100px !important; }

.pl--120 {
  padding-left: 120px !important; }

.pl--150 {
  padding-left: 150px !important; }

.pl--180 {
  padding-left: 180px !important; }

.pl--200 {
  padding-left: 200px !important; }

.pl--250 {
  padding-left: 250px !important; }

.pl--280 {
  padding-left: 280px !important; }

.pl--300 {
  padding-left: 300px !important; }

.pl--350 {
  padding-left: 350px !important; }

.pl--400 {
  padding-left: 400px !important; }

.pl--450 {
  padding-left: 450px !important; }

.pl--500 {
  padding-left: 500px !important; }

.p-auto {
  padding: auto !important; }

.p-inherit {
  padding: inherit !important; }

.p-6 {
  padding: 4.5rem !important; }

.pt-6 {
  padding-top: 4.5rem !important; }

.pb-6 {
  padding-bottom: 4.5rem !important; }

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.pl-6 {
  padding-left: 4.5rem !important; }

.pr-6 {
  padding-right: 4.5rem !important; }

.px-6 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.pt-7 {
  padding-top: 6rem !important; }

.pb-7 {
  padding-bottom: 6rem !important; }

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.pl-7 {
  padding-left: 6rem !important; }

.pr-7 {
  padding-right: 6rem !important; }

.px-7 {
  padding-left: 6rem !important;
  padding-right: 6rem !important; }

/**

		- Margins 					.m--*, .mt--*, .mr--*, .mb--*, .ml--*
									.m-auto, .m-inherit

**/
.m-6 {
  margin: 4.5rem !important; }

.mt-6 {
  margin-top: 4.5rem !important; }

.mb-6 {
  margin-bottom: 4.5rem !important; }

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.ml-6 {
  margin-left: 4.5rem !important; }

.mr-6 {
  margin-right: 4.5rem !important; }

.mx-6 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.mt-7 {
  margin-top: 6rem !important; }

.mb-7 {
  margin-bottom: 6rem !important; }

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.ml-7 {
  margin-left: 6rem !important; }

.mr-7 {
  margin-right: 6rem !important; }

.mx-7 {
  margin-left: 6rem !important;
  margin-right: 6rem !important; }

.m--0 {
  margin: 0px !important; }

.mt--0 {
  margin-top: 0px !important; }

.mb--0 {
  margin-bottom: 0px !important; }

.ml--0 {
  margin-left: 0px !important; }

.mr--0 {
  margin-right: 0px !important; }

.mt--n0 {
  margin-top: -0px !important; }

.mb--n0 {
  margin-bottom: -0px !important; }

.ml--n0 {
  margin-left: -0px !important; }

.mr--n0 {
  margin-right: -0px !important; }

.m--1 {
  margin: 1px !important; }

.mt--1 {
  margin-top: 1px !important; }

.mb--1 {
  margin-bottom: 1px !important; }

.ml--1 {
  margin-left: 1px !important; }

.mr--1 {
  margin-right: 1px !important; }

.mt--n1 {
  margin-top: -1px !important; }

.mb--n1 {
  margin-bottom: -1px !important; }

.ml--n1 {
  margin-left: -1px !important; }

.mr--n1 {
  margin-right: -1px !important; }

.m--2 {
  margin: 2px !important; }

.mt--2 {
  margin-top: 2px !important; }

.mb--2 {
  margin-bottom: 2px !important; }

.ml--2 {
  margin-left: 2px !important; }

.mr--2 {
  margin-right: 2px !important; }

.mt--n2 {
  margin-top: -2px !important; }

.mb--n2 {
  margin-bottom: -2px !important; }

.ml--n2 {
  margin-left: -2px !important; }

.mr--n2 {
  margin-right: -2px !important; }

.m--3 {
  margin: 3px !important; }

.mt--3 {
  margin-top: 3px !important; }

.mb--3 {
  margin-bottom: 3px !important; }

.ml--3 {
  margin-left: 3px !important; }

.mr--3 {
  margin-right: 3px !important; }

.mt--n3 {
  margin-top: -3px !important; }

.mb--n3 {
  margin-bottom: -3px !important; }

.ml--n3 {
  margin-left: -3px !important; }

.mr--n3 {
  margin-right: -3px !important; }

.m--4 {
  margin: 4px !important; }

.mt--4 {
  margin-top: 4px !important; }

.mb--4 {
  margin-bottom: 4px !important; }

.ml--4 {
  margin-left: 4px !important; }

.mr--4 {
  margin-right: 4px !important; }

.mt--n4 {
  margin-top: -4px !important; }

.mb--n4 {
  margin-bottom: -4px !important; }

.ml--n4 {
  margin-left: -4px !important; }

.mr--n4 {
  margin-right: -4px !important; }

.m--5 {
  margin: 5px !important; }

.mt--5 {
  margin-top: 5px !important; }

.mb--5 {
  margin-bottom: 5px !important; }

.ml--5 {
  margin-left: 5px !important; }

.mr--5 {
  margin-right: 5px !important; }

.mt--n5 {
  margin-top: -5px !important; }

.mb--n5 {
  margin-bottom: -5px !important; }

.ml--n5 {
  margin-left: -5px !important; }

.mr--n5 {
  margin-right: -5px !important; }

.m--6 {
  margin: 6px !important; }

.mt--6 {
  margin-top: 6px !important; }

.mb--6 {
  margin-bottom: 6px !important; }

.ml--6 {
  margin-left: 6px !important; }

.mr--6 {
  margin-right: 6px !important; }

.mt--n6 {
  margin-top: -6px !important; }

.mb--n6 {
  margin-bottom: -6px !important; }

.ml--n6 {
  margin-left: -6px !important; }

.mr--n6 {
  margin-right: -6px !important; }

.m--7 {
  margin: 7px !important; }

.mt--7 {
  margin-top: 7px !important; }

.mb--7 {
  margin-bottom: 7px !important; }

.ml--7 {
  margin-left: 7px !important; }

.mr--7 {
  margin-right: 7px !important; }

.mt--n7 {
  margin-top: -7px !important; }

.mb--n7 {
  margin-bottom: -7px !important; }

.ml--n7 {
  margin-left: -7px !important; }

.mr--n7 {
  margin-right: -7px !important; }

.m--8 {
  margin: 8px !important; }

.mt--8 {
  margin-top: 8px !important; }

.mb--8 {
  margin-bottom: 8px !important; }

.ml--8 {
  margin-left: 8px !important; }

.mr--8 {
  margin-right: 8px !important; }

.mt--n8 {
  margin-top: -8px !important; }

.mb--n8 {
  margin-bottom: -8px !important; }

.ml--n8 {
  margin-left: -8px !important; }

.mr--n8 {
  margin-right: -8px !important; }

.m--9 {
  margin: 9px !important; }

.mt--9 {
  margin-top: 9px !important; }

.mb--9 {
  margin-bottom: 9px !important; }

.ml--9 {
  margin-left: 9px !important; }

.mr--9 {
  margin-right: 9px !important; }

.mt--n9 {
  margin-top: -9px !important; }

.mb--n9 {
  margin-bottom: -9px !important; }

.ml--n9 {
  margin-left: -9px !important; }

.mr--n9 {
  margin-right: -9px !important; }

.m--10 {
  margin: 10px !important; }

.mt--10 {
  margin-top: 10px !important; }

.mb--10 {
  margin-bottom: 10px !important; }

.ml--10 {
  margin-left: 10px !important; }

.mr--10 {
  margin-right: 10px !important; }

.mt--n10 {
  margin-top: -10px !important; }

.mb--n10 {
  margin-bottom: -10px !important; }

.ml--n10 {
  margin-left: -10px !important; }

.mr--n10 {
  margin-right: -10px !important; }

.m--12 {
  margin: 12px !important; }

.mt--12 {
  margin-top: 12px !important; }

.mb--12 {
  margin-bottom: 12px !important; }

.ml--12 {
  margin-left: 12px !important; }

.mr--12 {
  margin-right: 12px !important; }

.mt--n12 {
  margin-top: -12px !important; }

.mb--n12 {
  margin-bottom: -12px !important; }

.ml--n12 {
  margin-left: -12px !important; }

.mr--n12 {
  margin-right: -12px !important; }

.m--15 {
  margin: 15px !important; }

.mt--15 {
  margin-top: 15px !important; }

.mb--15 {
  margin-bottom: 15px !important; }

.ml--15 {
  margin-left: 15px !important; }

.mr--15 {
  margin-right: 15px !important; }

.mt--n15 {
  margin-top: -15px !important; }

.mb--n15 {
  margin-bottom: -15px !important; }

.ml--n15 {
  margin-left: -15px !important; }

.mr--n15 {
  margin-right: -15px !important; }

.m--18 {
  margin: 18px !important; }

.mt--18 {
  margin-top: 18px !important; }

.mb--18 {
  margin-bottom: 18px !important; }

.ml--18 {
  margin-left: 18px !important; }

.mr--18 {
  margin-right: 18px !important; }

.mt--n18 {
  margin-top: -18px !important; }

.mb--n18 {
  margin-bottom: -18px !important; }

.ml--n18 {
  margin-left: -18px !important; }

.mr--n18 {
  margin-right: -18px !important; }

.m--20 {
  margin: 20px !important; }

.mt--20 {
  margin-top: 20px !important; }

.mb--20 {
  margin-bottom: 20px !important; }

.ml--20 {
  margin-left: 20px !important; }

.mr--20 {
  margin-right: 20px !important; }

.mt--n20 {
  margin-top: -20px !important; }

.mb--n20 {
  margin-bottom: -20px !important; }

.ml--n20 {
  margin-left: -20px !important; }

.mr--n20 {
  margin-right: -20px !important; }

.m--22 {
  margin: 22px !important; }

.mt--22 {
  margin-top: 22px !important; }

.mb--22 {
  margin-bottom: 22px !important; }

.ml--22 {
  margin-left: 22px !important; }

.mr--22 {
  margin-right: 22px !important; }

.mt--n22 {
  margin-top: -22px !important; }

.mb--n22 {
  margin-bottom: -22px !important; }

.ml--n22 {
  margin-left: -22px !important; }

.mr--n22 {
  margin-right: -22px !important; }

.m--25 {
  margin: 25px !important; }

.mt--25 {
  margin-top: 25px !important; }

.mb--25 {
  margin-bottom: 25px !important; }

.ml--25 {
  margin-left: 25px !important; }

.mr--25 {
  margin-right: 25px !important; }

.mt--n25 {
  margin-top: -25px !important; }

.mb--n25 {
  margin-bottom: -25px !important; }

.ml--n25 {
  margin-left: -25px !important; }

.mr--n25 {
  margin-right: -25px !important; }

.m--30 {
  margin: 30px !important; }

.mt--30 {
  margin-top: 30px !important; }

.mb--30 {
  margin-bottom: 30px !important; }

.ml--30 {
  margin-left: 30px !important; }

.mr--30 {
  margin-right: 30px !important; }

.mt--n30 {
  margin-top: -30px !important; }

.mb--n30 {
  margin-bottom: -30px !important; }

.ml--n30 {
  margin-left: -30px !important; }

.mr--n30 {
  margin-right: -30px !important; }

.m--35 {
  margin: 35px !important; }

.mt--35 {
  margin-top: 35px !important; }

.mb--35 {
  margin-bottom: 35px !important; }

.ml--35 {
  margin-left: 35px !important; }

.mr--35 {
  margin-right: 35px !important; }

.mt--n35 {
  margin-top: -35px !important; }

.mb--n35 {
  margin-bottom: -35px !important; }

.ml--n35 {
  margin-left: -35px !important; }

.mr--n35 {
  margin-right: -35px !important; }

.m--40 {
  margin: 40px !important; }

.mt--40 {
  margin-top: 40px !important; }

.mb--40 {
  margin-bottom: 40px !important; }

.ml--40 {
  margin-left: 40px !important; }

.mr--40 {
  margin-right: 40px !important; }

.mt--n40 {
  margin-top: -40px !important; }

.mb--n40 {
  margin-bottom: -40px !important; }

.ml--n40 {
  margin-left: -40px !important; }

.mr--n40 {
  margin-right: -40px !important; }

.m--45 {
  margin: 45px !important; }

.mt--45 {
  margin-top: 45px !important; }

.mb--45 {
  margin-bottom: 45px !important; }

.ml--45 {
  margin-left: 45px !important; }

.mr--45 {
  margin-right: 45px !important; }

.mt--n45 {
  margin-top: -45px !important; }

.mb--n45 {
  margin-bottom: -45px !important; }

.ml--n45 {
  margin-left: -45px !important; }

.mr--n45 {
  margin-right: -45px !important; }

.m--50 {
  margin: 50px !important; }

.mt--50 {
  margin-top: 50px !important; }

.mb--50 {
  margin-bottom: 50px !important; }

.ml--50 {
  margin-left: 50px !important; }

.mr--50 {
  margin-right: 50px !important; }

.mt--n50 {
  margin-top: -50px !important; }

.mb--n50 {
  margin-bottom: -50px !important; }

.ml--n50 {
  margin-left: -50px !important; }

.mr--n50 {
  margin-right: -50px !important; }

.m--60 {
  margin: 60px !important; }

.mt--60 {
  margin-top: 60px !important; }

.mb--60 {
  margin-bottom: 60px !important; }

.ml--60 {
  margin-left: 60px !important; }

.mr--60 {
  margin-right: 60px !important; }

.mt--n60 {
  margin-top: -60px !important; }

.mb--n60 {
  margin-bottom: -60px !important; }

.ml--n60 {
  margin-left: -60px !important; }

.mr--n60 {
  margin-right: -60px !important; }

.m--70 {
  margin: 70px !important; }

.mt--70 {
  margin-top: 70px !important; }

.mb--70 {
  margin-bottom: 70px !important; }

.ml--70 {
  margin-left: 70px !important; }

.mr--70 {
  margin-right: 70px !important; }

.mt--n70 {
  margin-top: -70px !important; }

.mb--n70 {
  margin-bottom: -70px !important; }

.ml--n70 {
  margin-left: -70px !important; }

.mr--n70 {
  margin-right: -70px !important; }

.m--80 {
  margin: 80px !important; }

.mt--80 {
  margin-top: 80px !important; }

.mb--80 {
  margin-bottom: 80px !important; }

.ml--80 {
  margin-left: 80px !important; }

.mr--80 {
  margin-right: 80px !important; }

.mt--n80 {
  margin-top: -80px !important; }

.mb--n80 {
  margin-bottom: -80px !important; }

.ml--n80 {
  margin-left: -80px !important; }

.mr--n80 {
  margin-right: -80px !important; }

.m--90 {
  margin: 90px !important; }

.mt--90 {
  margin-top: 90px !important; }

.mb--90 {
  margin-bottom: 90px !important; }

.ml--90 {
  margin-left: 90px !important; }

.mr--90 {
  margin-right: 90px !important; }

.mt--n90 {
  margin-top: -90px !important; }

.mb--n90 {
  margin-bottom: -90px !important; }

.ml--n90 {
  margin-left: -90px !important; }

.mr--n90 {
  margin-right: -90px !important; }

.m--100 {
  margin: 100px !important; }

.mt--100 {
  margin-top: 100px !important; }

.mb--100 {
  margin-bottom: 100px !important; }

.ml--100 {
  margin-left: 100px !important; }

.mr--100 {
  margin-right: 100px !important; }

.mt--n100 {
  margin-top: -100px !important; }

.mb--n100 {
  margin-bottom: -100px !important; }

.ml--n100 {
  margin-left: -100px !important; }

.mr--n100 {
  margin-right: -100px !important; }

.m--120 {
  margin: 120px !important; }

.mt--120 {
  margin-top: 120px !important; }

.mb--120 {
  margin-bottom: 120px !important; }

.ml--120 {
  margin-left: 120px !important; }

.mr--120 {
  margin-right: 120px !important; }

.mt--n120 {
  margin-top: -120px !important; }

.mb--n120 {
  margin-bottom: -120px !important; }

.ml--n120 {
  margin-left: -120px !important; }

.mr--n120 {
  margin-right: -120px !important; }

.m--150 {
  margin: 150px !important; }

.mt--150 {
  margin-top: 150px !important; }

.mb--150 {
  margin-bottom: 150px !important; }

.ml--150 {
  margin-left: 150px !important; }

.mr--150 {
  margin-right: 150px !important; }

.mt--n150 {
  margin-top: -150px !important; }

.mb--n150 {
  margin-bottom: -150px !important; }

.ml--n150 {
  margin-left: -150px !important; }

.mr--n150 {
  margin-right: -150px !important; }

.m--180 {
  margin: 180px !important; }

.mt--180 {
  margin-top: 180px !important; }

.mb--180 {
  margin-bottom: 180px !important; }

.ml--180 {
  margin-left: 180px !important; }

.mr--180 {
  margin-right: 180px !important; }

.mt--n180 {
  margin-top: -180px !important; }

.mb--n180 {
  margin-bottom: -180px !important; }

.ml--n180 {
  margin-left: -180px !important; }

.mr--n180 {
  margin-right: -180px !important; }

.m--200 {
  margin: 200px !important; }

.mt--200 {
  margin-top: 200px !important; }

.mb--200 {
  margin-bottom: 200px !important; }

.ml--200 {
  margin-left: 200px !important; }

.mr--200 {
  margin-right: 200px !important; }

.mt--n200 {
  margin-top: -200px !important; }

.mb--n200 {
  margin-bottom: -200px !important; }

.ml--n200 {
  margin-left: -200px !important; }

.mr--n200 {
  margin-right: -200px !important; }

.m--250 {
  margin: 250px !important; }

.mt--250 {
  margin-top: 250px !important; }

.mb--250 {
  margin-bottom: 250px !important; }

.ml--250 {
  margin-left: 250px !important; }

.mr--250 {
  margin-right: 250px !important; }

.mt--n250 {
  margin-top: -250px !important; }

.mb--n250 {
  margin-bottom: -250px !important; }

.ml--n250 {
  margin-left: -250px !important; }

.mr--n250 {
  margin-right: -250px !important; }

.m--280 {
  margin: 280px !important; }

.mt--280 {
  margin-top: 280px !important; }

.mb--280 {
  margin-bottom: 280px !important; }

.ml--280 {
  margin-left: 280px !important; }

.mr--280 {
  margin-right: 280px !important; }

.mt--n280 {
  margin-top: -280px !important; }

.mb--n280 {
  margin-bottom: -280px !important; }

.ml--n280 {
  margin-left: -280px !important; }

.mr--n280 {
  margin-right: -280px !important; }

.m--300 {
  margin: 300px !important; }

.mt--300 {
  margin-top: 300px !important; }

.mb--300 {
  margin-bottom: 300px !important; }

.ml--300 {
  margin-left: 300px !important; }

.mr--300 {
  margin-right: 300px !important; }

.mt--n300 {
  margin-top: -300px !important; }

.mb--n300 {
  margin-bottom: -300px !important; }

.ml--n300 {
  margin-left: -300px !important; }

.mr--n300 {
  margin-right: -300px !important; }

.m--350 {
  margin: 350px !important; }

.mt--350 {
  margin-top: 350px !important; }

.mb--350 {
  margin-bottom: 350px !important; }

.ml--350 {
  margin-left: 350px !important; }

.mr--350 {
  margin-right: 350px !important; }

.mt--n350 {
  margin-top: -350px !important; }

.mb--n350 {
  margin-bottom: -350px !important; }

.ml--n350 {
  margin-left: -350px !important; }

.mr--n350 {
  margin-right: -350px !important; }

.m--400 {
  margin: 400px !important; }

.mt--400 {
  margin-top: 400px !important; }

.mb--400 {
  margin-bottom: 400px !important; }

.ml--400 {
  margin-left: 400px !important; }

.mr--400 {
  margin-right: 400px !important; }

.mt--n400 {
  margin-top: -400px !important; }

.mb--n400 {
  margin-bottom: -400px !important; }

.ml--n400 {
  margin-left: -400px !important; }

.mr--n400 {
  margin-right: -400px !important; }

.m--450 {
  margin: 450px !important; }

.mt--450 {
  margin-top: 450px !important; }

.mb--450 {
  margin-bottom: 450px !important; }

.ml--450 {
  margin-left: 450px !important; }

.mr--450 {
  margin-right: 450px !important; }

.mt--n450 {
  margin-top: -450px !important; }

.mb--n450 {
  margin-bottom: -450px !important; }

.ml--n450 {
  margin-left: -450px !important; }

.mr--n450 {
  margin-right: -450px !important; }

.m--500 {
  margin: 500px !important; }

.mt--500 {
  margin-top: 500px !important; }

.mb--500 {
  margin-bottom: 500px !important; }

.ml--500 {
  margin-left: 500px !important; }

.mr--500 {
  margin-right: 500px !important; }

.mt--n500 {
  margin-top: -500px !important; }

.mb--n500 {
  margin-bottom: -500px !important; }

.ml--n500 {
  margin-left: -500px !important; }

.mr--n500 {
  margin-right: -500px !important; }

.m-auto {
  margin: auto !important; }

.m-inherit {
  margin: inherit !important; }

/**

		- Borders 					.bw--*
									.border--solid, .bborder--dashed, .bborder--dotted, .border-transparent

**/
.bw--0 {
  border-width: 0px !important; }

.bw--1 {
  border-width: 1px !important; }

.bw--2 {
  border-width: 2px !important; }

.bw--3 {
  border-width: 3px !important; }

.b-0,
.b--0 {
  border: 0 !important; }

.bl-0,
.bl--0 {
  border-left: 0 !important; }

.br-0,
.br--0 {
  border-right: 0 !important; }

.bt-0,
.bt--0 {
  border-top: 0 !important; }

.bb-0,
.bb--0 {
  border-bottom: 0 !important; }

.bx-0 {
  border-left: 0 !important;
  border-right: 0 !important; }

.by-0 {
  border-top: 0 !important;
  border-top: 0 !important; }

.border-solid {
  border-style: solid !important;
  border-width: 1px; }

.border-dashed {
  border-style: dashed !important;
  border-width: 1px; }

.border-dotted {
  border-style: dotted !important;
  border-width: 1px; }

.border-transparent {
  border-color: transparent !important; }

.border-theme-color-light {
  border-color: rgba(55, 125, 255, 0.04) !important; }

.border-gray-400 {
  border: #ced4da 1px solid; }

/*

	Soft borders
		.border-*-soft

*/
.border-primary-soft {
  border-color: #eaf1ff !important; }

.border-secondary-soft {
  border-color: #f0f2f2 !important; }

.border-success-soft {
  border-color: #def3cf !important; }

.border-info-soft {
  border-color: #ddfbff !important; }

.border-warning-soft {
  border-color: #fef4d8 !important; }

.border-danger-soft {
  border-color: #fbe7e9 !important; }

.border-light-soft {
  border-color: white !important; }

.border-dark-soft {
  border-color: #d6d6d6 !important; }

.border-indigo-soft {
  border-color: #efedfc !important; }

.border-purple-soft {
  border-color: #b6a2e3 !important; }

.border-pink-soft {
  border-color: #f7bed8 !important; }

/*

	Pill rows
		1. container & first image
		<div class="row-pill">
			<img src="...">
		</div>

		2. container & first background image acting as avatar
		<div class="row-pill">
			<div class="bg-cover w--50 h--50 m--3 d-inline-block" style="background-url:(...)">
		</div>

*/
.row-pill,
.row-pill > img,
.row-pill > .bg-cover {
  border-radius: 50rem !important; }

/**

		- Radius 					.rounded-xl, .rounded-xxl,
									.rounded-top-0, .rounded-bottom-0, .rounded-left-0, .rounded-right-0
									.rounded-start-0, .rounded-end-0

**/
.rounded-xl {
  border-radius: 0.8rem !important; }

.rounded-xxl {
  border-radius: 1.3rem !important; }

.rounded-top-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important; }

.rounded-bottom-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.rounded-left-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.rounded-right-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.rounded-start-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.rounded-end-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

/**

		- Font Size 				.fs--*, .font-weight-medium

**/
.fs--10 {
  font-size: 10px !important; }

.fs--11 {
  font-size: 11px !important; }

.fs--12 {
  font-size: 12px !important; }

.fs--13 {
  font-size: 13px !important; }

.fs--14 {
  font-size: 14px !important; }

.fs--15 {
  font-size: 15px !important; }

.fs--16 {
  font-size: 16px !important; }

.fs--17 {
  font-size: 17px !important; }

.fs--18 {
  font-size: 18px !important; }

.fs--19 {
  font-size: 19px !important; }

.fs--20 {
  font-size: 20px !important; }

.fs--25 {
  font-size: 25px !important; }

.fs--30 {
  font-size: 30px !important; }

.fs--35 {
  font-size: 35px !important; }

.fs--40 {
  font-size: 40px !important; }

.fs--45 {
  font-size: 45px !important; }

.fs--50 {
  font-size: 50px !important; }

.fs--60 {
  font-size: 60px !important; }

.font-weight-medium {
  font-weight: 500; }

/**

		- Box Shadows 				.shadow (default) , .shadow-*, .shadow-*-hover,
									[[.shadow-*-hover can be used in combination with .transition-all-ease-*]]

**/
.shadow-none {
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.shadow-hover:hover {
  -ms-box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15) !important;
  -o-box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15) !important;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15) !important; }

.shadow-xs,
.shadow-xs-hover:hover {
  -ms-box-shadow: 0 0 25px rgba(140, 152, 164, 0.1) !important;
  -o-box-shadow: 0 0 25px rgba(140, 152, 164, 0.1) !important;
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1) !important; }

.shadow-sm-hover:hover {
  -ms-box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
  -o-box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important; }

.shadow-md,
.shadow-md-hover:hover {
  -ms-box-shadow: 0 0 35px rgba(140, 152, 164, 0.15) !important;
  -o-box-shadow: 0 0 35px rgba(140, 152, 164, 0.15) !important;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.15) !important; }

.shadow-lg-hover:hover {
  -ms-box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
  -o-box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important; }

.shadow-xlg,
.shadow-xlg-hover:hover {
  -ms-box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.3) !important;
  -o-box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.3) !important;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.3) !important; }

.shadow-3d-up,
.shadow-3d-up-hover:hover {
  box-shadow: 0rem -2rem 3.5rem rgba(22, 28, 45, 0.1) !important; }

.shadow-3d-down,
.shadow-3d-down-hover:hover,
.shadow-3d,
.shadow-3d-hover:hover {
  box-shadow: 0 2.5rem 4rem rgba(22, 28, 45, 0.1) !important; }

/*

	.shadow-primary-*
	.shadow-secondary-*
	.....
	.shadow-*-*

*/
.shadow-primary-xs,
.shadow-primary-xs-hover:hover {
  box-shadow: 0 0 25px rgba(55, 125, 255, 0.1) !important; }

.shadow-primary-sm,
.shadow-primary-sm-hover:hover {
  box-shadow: 0 0 35px rgba(55, 125, 255, 0.125) !important; }

.shadow-primary-md,
.shadow-primary-md-hover:hover {
  box-shadow: 0 0 35px rgba(55, 125, 255, 0.15) !important; }

.shadow-primary-lg,
.shadow-primary-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(55, 125, 255, 0.175) !important; }

.shadow-primary-xlg,
.shadow-primary-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(55, 125, 255, 0.3) !important; }

.shadow-secondary-xs,
.shadow-secondary-xs-hover:hover {
  box-shadow: 0 0 25px rgba(108, 117, 125, 0.1) !important; }

.shadow-secondary-sm,
.shadow-secondary-sm-hover:hover {
  box-shadow: 0 0 35px rgba(108, 117, 125, 0.125) !important; }

.shadow-secondary-md,
.shadow-secondary-md-hover:hover {
  box-shadow: 0 0 35px rgba(108, 117, 125, 0.15) !important; }

.shadow-secondary-lg,
.shadow-secondary-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(108, 117, 125, 0.175) !important; }

.shadow-secondary-xlg,
.shadow-secondary-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(108, 117, 125, 0.3) !important; }

.shadow-success-xs,
.shadow-success-xs-hover:hover {
  box-shadow: 0 0 25px rgba(109, 187, 48, 0.1) !important; }

.shadow-success-sm,
.shadow-success-sm-hover:hover {
  box-shadow: 0 0 35px rgba(109, 187, 48, 0.125) !important; }

.shadow-success-md,
.shadow-success-md-hover:hover {
  box-shadow: 0 0 35px rgba(109, 187, 48, 0.15) !important; }

.shadow-success-lg,
.shadow-success-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(109, 187, 48, 0.175) !important; }

.shadow-success-xlg,
.shadow-success-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(109, 187, 48, 0.3) !important; }

.shadow-info-xs,
.shadow-info-xs-hover:hover {
  box-shadow: 0 0 25px rgba(0, 223, 252, 0.1) !important; }

.shadow-info-sm,
.shadow-info-sm-hover:hover {
  box-shadow: 0 0 35px rgba(0, 223, 252, 0.125) !important; }

.shadow-info-md,
.shadow-info-md-hover:hover {
  box-shadow: 0 0 35px rgba(0, 223, 252, 0.15) !important; }

.shadow-info-lg,
.shadow-info-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(0, 223, 252, 0.175) !important; }

.shadow-info-xlg,
.shadow-info-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(0, 223, 252, 0.3) !important; }

.shadow-warning-xs,
.shadow-warning-xs-hover:hover {
  box-shadow: 0 0 25px rgba(250, 215, 118, 0.1) !important; }

.shadow-warning-sm,
.shadow-warning-sm-hover:hover {
  box-shadow: 0 0 35px rgba(250, 215, 118, 0.125) !important; }

.shadow-warning-md,
.shadow-warning-md-hover:hover {
  box-shadow: 0 0 35px rgba(250, 215, 118, 0.15) !important; }

.shadow-warning-lg,
.shadow-warning-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(250, 215, 118, 0.175) !important; }

.shadow-warning-xlg,
.shadow-warning-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(250, 215, 118, 0.3) !important; }

.shadow-danger-xs,
.shadow-danger-xs-hover:hover {
  box-shadow: 0 0 25px rgba(220, 53, 69, 0.1) !important; }

.shadow-danger-sm,
.shadow-danger-sm-hover:hover {
  box-shadow: 0 0 35px rgba(220, 53, 69, 0.125) !important; }

.shadow-danger-md,
.shadow-danger-md-hover:hover {
  box-shadow: 0 0 35px rgba(220, 53, 69, 0.15) !important; }

.shadow-danger-lg,
.shadow-danger-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(220, 53, 69, 0.175) !important; }

.shadow-danger-xlg,
.shadow-danger-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(220, 53, 69, 0.3) !important; }

.shadow-light-xs,
.shadow-light-xs-hover:hover {
  box-shadow: 0 0 25px rgba(248, 249, 250, 0.1) !important; }

.shadow-light-sm,
.shadow-light-sm-hover:hover {
  box-shadow: 0 0 35px rgba(248, 249, 250, 0.125) !important; }

.shadow-light-md,
.shadow-light-md-hover:hover {
  box-shadow: 0 0 35px rgba(248, 249, 250, 0.15) !important; }

.shadow-light-lg,
.shadow-light-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(248, 249, 250, 0.175) !important; }

.shadow-light-xlg,
.shadow-light-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(248, 249, 250, 0.3) !important; }

.shadow-dark-xs,
.shadow-dark-xs-hover:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important; }

.shadow-dark-sm,
.shadow-dark-sm-hover:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.125) !important; }

.shadow-dark-md,
.shadow-dark-md-hover:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.15) !important; }

.shadow-dark-lg,
.shadow-dark-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.175) !important; }

.shadow-dark-xlg,
.shadow-dark-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.3) !important; }

.shadow-indigo-xs,
.shadow-indigo-xs-hover:hover {
  box-shadow: 0 0 25px rgba(83, 59, 226, 0.1) !important; }

.shadow-indigo-sm,
.shadow-indigo-sm-hover:hover {
  box-shadow: 0 0 35px rgba(83, 59, 226, 0.125) !important; }

.shadow-indigo-md,
.shadow-indigo-md-hover:hover {
  box-shadow: 0 0 35px rgba(83, 59, 226, 0.15) !important; }

.shadow-indigo-lg,
.shadow-indigo-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(83, 59, 226, 0.175) !important; }

.shadow-indigo-xlg,
.shadow-indigo-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(83, 59, 226, 0.3) !important; }

.shadow-purple-xs,
.shadow-purple-xs-hover:hover {
  box-shadow: 0 0 25px rgba(76, 44, 146, 0.1) !important; }

.shadow-purple-sm,
.shadow-purple-sm-hover:hover {
  box-shadow: 0 0 35px rgba(76, 44, 146, 0.125) !important; }

.shadow-purple-md,
.shadow-purple-md-hover:hover {
  box-shadow: 0 0 35px rgba(76, 44, 146, 0.15) !important; }

.shadow-purple-lg,
.shadow-purple-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(76, 44, 146, 0.175) !important; }

.shadow-purple-xlg,
.shadow-purple-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(76, 44, 146, 0.3) !important; }

.shadow-pink-xs,
.shadow-pink-xs-hover:hover {
  box-shadow: 0 0 25px rgba(232, 62, 140, 0.1) !important; }

.shadow-pink-sm,
.shadow-pink-sm-hover:hover {
  box-shadow: 0 0 35px rgba(232, 62, 140, 0.125) !important; }

.shadow-pink-md,
.shadow-pink-md-hover:hover {
  box-shadow: 0 0 35px rgba(232, 62, 140, 0.15) !important; }

.shadow-pink-lg,
.shadow-pink-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(232, 62, 140, 0.175) !important; }

.shadow-pink-xlg,
.shadow-pink-xlg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(232, 62, 140, 0.3) !important; }

/**

		- Transitions 				.transition-all-ease-*, .transition-bg-ease-*

		- Hover Transitions 		.transition-hover-top, .transition-hover-right, .transition-hover-bottom, .transition-hover-left
									.transition-hover-zoom
									.transition-none

**/
/**

	Transitions
		.transition-all-ease-*
		.transition-bg-ease-*


	Bug Fixes
		Chrome known bug: will fire transitons on page load if <script> tag does not exist!
		Fix: Add a script tag with a simple space like this:
			<script> </script>

**/
.transition-all-ease-150 {
  -webkit-transition: all 150ms ease,-webkit-transform 150ms ease;
  transition: all 150ms ease,-webkit-transform 150ms ease;
  transition: all 150ms ease,transform 150ms ease;
  transition: all 150ms ease,transform 150ms ease,-webkit-transform 150ms ease; }

.transition-all-ease-200 {
  -webkit-transition: all 200ms ease,-webkit-transform 250ms ease;
  transition: all 200ms ease,-webkit-transform 250ms ease;
  transition: all 200ms ease,transform 250ms ease;
  transition: all 200ms ease,transform 250ms ease,-webkit-transform 250ms ease; }

.transition-all-ease-250 {
  -webkit-transition: all 250ms ease,-webkit-transform 250ms ease;
  transition: all 250ms ease,-webkit-transform 250ms ease;
  transition: all 250ms ease,transform 250ms ease;
  transition: all 250ms ease,transform 250ms ease,-webkit-transform 250ms ease; }

.transition-all-ease-500 {
  -webkit-transition: all 500ms ease,-webkit-transform 500ms ease;
  transition: all 500ms ease,-webkit-transform 500ms ease;
  transition: all 500ms ease,transform 500ms ease;
  transition: all 500ms ease,transform 500ms ease,-webkit-transform 500ms ease; }

.transition-bg-ease-150 {
  -webkit-transition: background-color 150ms linear;
  transition: background-color 150ms linear; }

.transition-bg-ease-200 {
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear; }

.transition-bg-ease-250 {
  -webkit-transition: background-color 250ms linear;
  transition: background-color 250ms linear; }

.transition-bg-ease-500 {
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear; }

/**

	Hover Transitions
		.transition-hover-top, .transition-hover-right, .transition-hover-bottom, .transition-hover-left

**/
.transition-hover-top,
.transition-hover-right,
.transition-hover-bottom,
.transition-hover-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.transition-hover-top:hover,
.transition-hover-top:focus {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px); }

.transition-hover-right:hover,
.transition-hover-right:focus {
  -webkit-transform: translateX(3px);
          transform: translateX(3px); }

.transition-hover-bottom:hover,
.transition-hover-bottom:focus {
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.transition-hover-left:hover,
.transition-hover-left:focus {
  -webkit-transform: translateX(-3px);
          transform: translateX(-3px); }

.transition-hover-start:hover,
.transition-hover-start:focus {
  -webkit-transform: translateX(-3px);
          transform: translateX(-3px); }

.transition-hover-end:hover,
.transition-hover-end:focus {
  -webkit-transform: translateX(3px);
          transform: translateX(3px); }

.transition-none {
  -webkit-transition: none !important;
  transition: none !important; }

/* transition hover zoom */
.transition-hover-zoom-img img,
.transition-hover-zoom {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

.transition-hover-zoom-img:hover img,
.transition-hover-zoom:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

/**

		- Transform 3D 				.transform-3d-left, .transform-3d-right

**/
.transform-3d-right,
.transform-3d-left {
  -webkit-perspective-origin: center;
          perspective-origin: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1024px;
          perspective: 1024px; }

.transform-3d-left > img,
.transform-3d-left > figure,
.transform-3d-left > div {
  -webkit-transform: rotateY(35deg) rotateX(15deg) translateZ(0);
          transform: rotateY(35deg) rotateX(15deg) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.transform-3d-right > img,
.transform-3d-right > figure,
.transform-3d-right > div {
  -webkit-transform: rotateY(-35deg) rotateX(15deg) translateZ(0);
          transform: rotateY(-35deg) rotateX(15deg) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*

	Block

*/
.block-over-start-top {
  -webkit-transform: translate(25px, -25px);
          transform: translate(25px, -25px); }

.block-over-start-bottom {
  -webkit-transform: translate(25px, 25px);
          transform: translate(25px, 25px); }

.block-over-end-top {
  -webkit-transform: translate(-25px, -25px);
          transform: translate(-25px, -25px); }

.block-over-end-bottom {
  -webkit-transform: translate(-25px, 25px);
          transform: translate(-25px, 25px); }

/**

		- Rotate 					.rotate-90  	(rotate text)
		@Rewrite needed!
**/
.text-rotate {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr; }

/**

	Right in the center

**/
.transform-center-fixed {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  z-index: 100; }

/**

		- Positions 				.absolute-full, .fixed-full
									.top-0, .right-0, .bottom-0, .left-0

**/
.absolute-full {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.fixed-full {
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.top-0 {
  top: 0 !important; }

.bottom-0 {
  bottom: 0 !important; }

.left-0 {
  left: 0 !important;
  right: auto; }

.right-0 {
  right: 0 !important;
  left: auto; }

.start-0 {
  left: 0 !important;
  right: auto !important; }

.end-0 {
  right: 0 !important;
  left: auto !important; }

/* out of viewport */
.viewport-out {
  position: fixed !important;
  left: -9999px !important;
  top: -9999px !important; }

/* vertical align to middle */
.valign-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

/* better than .valign-middle */
.d-middle {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center; }

/**

		- Overlays & Opacity 		.overlay-light, .overlay-dark, .overlay-opacity-* [0-9]
									.opacity-* [0-9], .opacity-none

**/
/* default overlay used on various places (mostly on admin) */
#overlay-default,
.overlay-default,
.overlay-light-hover:after,
.overlay-dark-hover:after,
.overlay-light,
.overlay-dark {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

#overlay-default,
.overlay-default {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10; }

.overlay-light-hover,
.overlay-dark-hover,
.overlay-light,
.overlay-dark {
  position: relative; }

.overlay-light-hover > *,
.overlay-dark-hover > * {
  z-index: 0;
  position: relative; }

.overlay-light > *,
.overlay-dark > * {
  z-index: 1;
  position: relative; }

/* used in few situations, like refreshing ajax content */
.overlay-over:after {
  z-index: 10 !important; }

.overlay-light-hover:after,
.overlay-dark-hover:after {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.rounded.overlay-light-hover:hover,
.rounded.overlay-dark-hover:hover,
.rounded.overlay-light:after,
.rounded.overlay-dark:after {
  border-radius: 0.25rem; }

.overlay-light-hover:after,
.overlay-dark-hover:after,
.overlay-light:after,
.overlay-dark:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.overlay-light-hover:hover:after,
.overlay-light:after {
  background-color: #ffffff; }

.overlay-dark-hover:hover:after,
.overlay-dark:after {
  background-color: #000000; }

/* overlay|opacity [0-9] */
.opacity-0,
.overlay-opacity-0:after {
  opacity: 0; }

.opacity-1,
.overlay-opacity-1:after {
  opacity: 0.1; }

.opacity-2,
.overlay-opacity-2:after {
  opacity: 0.2; }

.opacity-3,
.overlay-opacity-3:after {
  opacity: 0.3; }

.opacity-4,
.overlay-opacity-4:after {
  opacity: 0.4; }

.opacity-5,
.overlay-opacity-5:after {
  opacity: 0.5; }

.opacity-6,
.overlay-opacity-6:after {
  opacity: 0.6; }

.opacity-7,
.overlay-opacity-7:after {
  opacity: 0.7; }

.opacity-8,
.overlay-opacity-8:after {
  opacity: 0.8; }

.opacity-9,
.overlay-opacity-9:after {
  opacity: 0.9; }

.opacity-none {
  opacity: 1 !important; }

.opacity-99 {
  opacity: .99; }

/**
		- Misc

		- Gradients 				.bg-gradient-light-top, .bg-gradient-light-bottom
									.bg-gradient-light-left, .bg-gradient-light-right
									.bg-gradient-* (primary, danger, etc)


		- Backgrounds 				.bg-gray-* [100-900], .bg-darkblue .bg-diff,
									.bg-light-hover, .bg-light-active
									.bg-theme-color-light
**/
/**

	Misc

**/
.bg-cover {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important; }

.bg-fixed {
  background-attachment: fixed !important; }

.bg-blur {
  -webkit-filter: blur(5px);
          filter: blur(5px); }

.bg-suprime {
  /* Used on image to remove background. IE is not responding */
  mix-blend-mode: multiply; }

.bg-inherit {
  background: inherit !important; }

.bg-gradient-default {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #2f2e80), to(#4c4bb5));
  background: linear-gradient(180deg, #2f2e80 0, #4c4bb5); }

/**

	Gradients

	Note: can be used together with any .bg-*
**/
.bg-gradient-fake:after {
  /* applied toraw color, to slightly look like a gradient - is actualy a white gradient! */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(-25%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.19))) !important;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) -25%, rgba(255, 255, 255, 0.19) 100%) !important; }

.bg-gradient-light-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(55, 125, 255, 0.05)), to(transparent));
  background-image: linear-gradient(0deg, rgba(55, 125, 255, 0.05) 0%, transparent 100%); }

.bg-gradient-light-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), to(rgba(55, 125, 255, 0.05)));
  background-image: linear-gradient(0deg, #fff 0, rgba(55, 125, 255, 0.05)); }

.bg-gradient-light-left {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(55, 125, 255, 0.05)), to(#fff));
  background-image: linear-gradient(90deg, rgba(55, 125, 255, 0.05) 0, #fff); }

.bg-gradient-light-right {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, #fff), to(rgba(55, 125, 255, 0.05)));
  background-image: linear-gradient(270deg, #fff 0, rgba(55, 125, 255, 0.05)); }

.bg-gradient-dark {
  color: #ffffff;
  background: #313131 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#454545), to(#313131)) !important;
  background: linear-gradient(to bottom, #454545 0%, #313131 100%) !important; }

.bg-gradient-dark p {
  color: #adb5bd; }

.bg-gradient-primary {
  background: #377dff;
  background: -webkit-gradient(linear, left top, left bottom, from(#6098ff), to(#377dff)) !important;
  background: linear-gradient(to bottom, #6098ff 0%, #377dff 100%) !important; }

.bg-gradient-secondary {
  background: #6c757d;
  background: -webkit-gradient(linear, left top, left bottom, from(#808992), to(#6c757d)) !important;
  background: linear-gradient(to bottom, #808992 0%, #6c757d 100%) !important; }

.bg-gradient-success {
  background: #6dbb30;
  background: -webkit-gradient(linear, left top, left bottom, from(#81cf45), to(#6dbb30)) !important;
  background: linear-gradient(to bottom, #81cf45 0%, #6dbb30 100%) !important; }

.bg-gradient-info {
  background: #00dffc;
  background: -webkit-gradient(linear, left top, left bottom, from(#26e6ff), to(#00dffc)) !important;
  background: linear-gradient(to bottom, #26e6ff 0%, #00dffc 100%) !important; }

.bg-gradient-warning {
  background: #fad776;
  background: -webkit-gradient(linear, left top, left bottom, from(#fbe29d), to(#fad776)) !important;
  background: linear-gradient(to bottom, #fbe29d 0%, #fad776 100%) !important; }

.bg-gradient-danger {
  background: #dc3545;
  background: -webkit-gradient(linear, left top, left bottom, from(#e25865), to(#dc3545)) !important;
  background: linear-gradient(to bottom, #e25865 0%, #dc3545 100%) !important; }

.bg-gradient-light {
  background: #f8f9fa;
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(#f8f9fa)) !important;
  background: linear-gradient(to bottom, white 0%, #f8f9fa 100%) !important; }

.bg-gradient-indigo {
  background: #533be2;
  background: -webkit-gradient(linear, left top, left bottom, from(#725fe7), to(#533be2)) !important;
  background: linear-gradient(to bottom, #725fe7 0%, #533be2 100%) !important; }

.bg-gradient-purple {
  background: #4c2c92;
  background: -webkit-gradient(linear, left top, left bottom, from(#5c35b1), to(#4c2c92)) !important;
  background: linear-gradient(to bottom, #5c35b1 0%, #4c2c92 100%) !important; }

.bg-gradient-pink {
  background: #e83e8c;
  background: -webkit-gradient(linear, left top, left bottom, from(#ec62a2), to(#e83e8c)) !important;
  background: linear-gradient(to bottom, #ec62a2 0%, #e83e8c 100%) !important; }

/**

	Backgrounds
		.bg-*

**/
.bg-light-active,
.bg-light-hover:hover {
  background: #f8f9fa; }

.bg-theme-color-light {
  background-color: rgba(55, 125, 255, 0.04) !important; }

.bg-gray-100 {
  background-color: #f8f9fa !important; }

.bg-gray-200 {
  background-color: #e9ecef !important; }

.bg-gray-300 {
  background-color: #dee2e6 !important; }

.bg-gray-400 {
  background-color: #ced4da !important; }

.bg-gray-500 {
  color: #ffffff;
  background-color: #adb5bd !important; }

.bg-gray-600 {
  color: #ffffff;
  background-color: #6c757d !important; }

.bg-gray-700 {
  color: #ffffff;
  background-color: #495057 !important; }

.bg-gray-800 {
  color: #ffffff;
  background-color: #343a40 !important; }

.bg-gray-900 {
  color: #ffffff;
  background-color: #212529 !important; }

.bg-darkblue {
  color: #ffffff;
  background: #2b354f !important; }

.bg-diff {
  background-color: rgba(0, 0, 0, 0.07); }

input.bg-diff:focus,
textarea.bg-diff:focus {
  background-color: rgba(0, 0, 0, 0.11); }

/*

	Soft backgrounds
		.bg-*-soft

*/
.active.bg-primary-active-soft,
.bg-primary-soft {
  color: #00379d !important;
  background-color: #eaf1ff !important; }

.bg-primary-soft-hover:hover {
  background-color: #eaf1ff !important; }

.active.bg-secondary-active-soft,
.bg-secondary-soft {
  color: #25282b !important;
  background-color: #f0f2f2 !important; }

.bg-secondary-soft-hover:hover {
  background-color: #f0f2f2 !important; }

.active.bg-success-active-soft,
.bg-success-soft {
  color: #264111 !important;
  background-color: #def3cf !important; }

.bg-success-soft-hover:hover {
  background-color: #def3cf !important; }

.active.bg-info-active-soft,
.bg-info-soft {
  color: #005863 !important;
  background-color: #ddfbff !important; }

.bg-info-soft-hover:hover {
  background-color: #ddfbff !important; }

.active.bg-warning-active-soft,
.bg-warning-soft {
  color: #cf9a08 !important;
  background-color: #fef4d8 !important; }

.bg-warning-soft-hover:hover {
  background-color: #fef4d8 !important; }

.active.bg-danger-active-soft,
.bg-danger-soft {
  color: #66121a !important;
  background-color: #fbe7e9 !important; }

.bg-danger-soft-hover:hover {
  background-color: #fbe7e9 !important; }

.active.bg-light-active-soft,
.bg-light-soft {
  color: #9fadba !important;
  background-color: white !important; }

.bg-light-soft-hover:hover {
  background-color: white !important; }

.active.bg-dark-active-soft,
.bg-dark-soft {
  color: black !important;
  background-color: #d6d6d6 !important; }

.bg-dark-soft-hover:hover {
  background-color: #d6d6d6 !important; }

.active.bg-indigo-active-soft,
.bg-indigo-soft {
  color: #1f1173 !important;
  background-color: #efedfc !important; }

.bg-indigo-soft-hover:hover {
  background-color: #efedfc !important; }

.active.bg-purple-active-soft,
.bg-purple-soft {
  color: #0f091c !important;
  background-color: #b6a2e3 !important; }

.bg-purple-soft-hover:hover {
  background-color: #b6a2e3 !important; }

.active.bg-pink-active-soft,
.bg-pink-soft {
  color: #7e0f42 !important;
  background-color: #f7bed8 !important; }

.bg-pink-soft-hover:hover {
  background-color: #f7bed8 !important; }

/*

	Active backgrounds
		.active.bg-*-active

*/
.active.bg-primary-active {
  color: #ffffff !important;
  background-color: #377dff !important; }

.active.bg-secondary-active {
  color: #ffffff !important;
  background-color: #6c757d !important; }

.active.bg-success-active {
  color: #ffffff !important;
  background-color: #6dbb30 !important; }

.active.bg-info-active {
  color: #ffffff !important;
  background-color: #00dffc !important; }

.active.bg-warning-active {
  color: #ffffff !important;
  background-color: #fad776 !important; }

.active.bg-danger-active {
  color: #ffffff !important;
  background-color: #dc3545 !important; }

.active.bg-light-active {
  color: #ffffff !important;
  background-color: #f8f9fa !important; }

.active.bg-dark-active {
  color: #ffffff !important;
  background-color: #000000 !important; }

.active.bg-indigo-active {
  color: #ffffff !important;
  background-color: #533be2 !important; }

.active.bg-purple-active {
  color: #ffffff !important;
  background-color: #4c2c92 !important; }

.active.bg-pink-active {
  color: #ffffff !important;
  background-color: #e83e8c !important; }

/*

	Hover backgrounds
		.bg-*-hover

*/
.bg-primary-hover:hover {
  color: #fff !important;
  background-color: #377dff !important; }

.bg-primary-hover:hover p {
  color: #fff !important; }

.bg-secondary-hover:hover {
  color: #fff !important;
  background-color: #6c757d !important; }

.bg-secondary-hover:hover p {
  color: #fff !important; }

.bg-success-hover:hover {
  color: #fff !important;
  background-color: #6dbb30 !important; }

.bg-success-hover:hover p {
  color: #fff !important; }

.bg-info-hover:hover {
  color: #fff !important;
  background-color: #00dffc !important; }

.bg-info-hover:hover p {
  color: #fff !important; }

.bg-warning-hover:hover {
  color: #fff !important;
  background-color: #fad776 !important; }

.bg-warning-hover:hover p {
  color: #fff !important; }

.bg-danger-hover:hover {
  color: #fff !important;
  background-color: #dc3545 !important; }

.bg-danger-hover:hover p {
  color: #fff !important; }

.bg-light-hover:hover {
  background-color: #f8f9fa !important; }

.bg-dark-hover:hover {
  color: #fff !important;
  background-color: #000000 !important; }

.bg-dark-hover:hover p {
  color: #fff !important; }

.bg-indigo-hover:hover {
  color: #fff !important;
  background-color: #533be2 !important; }

.bg-indigo-hover:hover p {
  color: #fff !important; }

.bg-purple-hover:hover {
  color: #fff !important;
  background-color: #4c2c92 !important; }

.bg-purple-hover:hover p {
  color: #fff !important; }

.bg-pink-hover:hover {
  color: #fff !important;
  background-color: #e83e8c !important; }

.bg-pink-hover:hover p {
  color: #fff !important; }

.text-white-hover:hover {
  color: #fff !important; }

.text-dark-hover:hover {
  color: #000 !important; }

/*

	Radial backgrounds

*/
.bg-light-radial {
  background: radial-gradient(#ffffff, #f8f9fa) !important; }

/**

		- Social backgrounds

**/
.bg-facebook {
  background-color: #3B5998 !important;
  color: #fff; }

.bg-pinterest {
  background-color: #C8232C !important;
  color: #fff; }

.bg-paypal {
  background-color: #00588B !important;
  color: #fff; }

.bg-linkedin {
  background-color: #0E76A8 !important;
  color: #fff; }

.bg-skype {
  background-color: #00AFF0 !important;
  color: #fff; }

.bg-twitter {
  background-color: #00ACEE !important;
  color: #fff; }

.bg-youtube {
  background-color: #C4302B !important;
  color: #fff; }

.bg-vimeo {
  background-color: #86C9EF !important;
  color: #fff; }

.bg-googleplay {
  background-color: #DD4B39 !important;
  color: #fff; }

.bg-snapchat {
  background-color: #FFFC00 !important;
  color: #111 !important; }

.bg-instagram {
  background-color: #3F729B !important;
  color: #fff; }

/*
.bg-delicious { background-color: #205CC0!important; color: #fff; }
.bg-flattr { background-color: #F67C1A!important; color: #fff; }
.bg-android { background-color: #A4C639!important; color: #fff; }
.bg-smashmag { background-color: #E53B2C!important; color: #fff; }
.bg-gplus, .bg-googleplus { background-color: #DD4B39!important; color: #fff; }
.bg-wikipedia { background-color: #333!important; color: #fff; }
.bg-stumbleupon { background-color: #F74425!important; color: #fff; }
.bg-foursquare { background-color: #25A0CA!important; color: #fff; }
.bg-call { background-color: #444!important; color: #fff; }
.bg-ninetyninedesigns { background-color: #F26739!important; color: #fff; }
.bg-forrst { background-color: #5B9A68!important; color: #fff; }
.bg-digg { background-color: #191919!important; color: #fff; }
.bg-spotify { background-color: #81B71A!important; color: #fff; }
.bg-reddit { background-color: #C6C6C6!important; color: #fff; }
.bg-blogger { background-color: #FC4F08!important; color: #fff; }
.bg-cc { background-color: #688527!important; color:#fff; }
.bg-dribbble { background-color: #EA4C89!important; color:#fff; }
.bg-evernote { background-color: #5BA525!important; color:#fff; }
.bg-flickr { background-color: #FF0084!important; color:#fff; }
.bg-google { background-color: #DD4B39!important; color:#fff; }
.bg-instapaper { background-color: #333!important; color:#fff; }
.bg-klout { background-color: #FF5F52!important; color:#fff; }
.bg-vk { background-color: #2B587A!important; color:#fff; }
.bg-rss { background-color: #EE802F!important; color:#fff; }
.bg-aim { background-color: #FCD20B!important; color:#fff; }
.bg-yahoo { background-color: #720E9E!important; color:#fff; }
.bg-email3 { background-color: #6567A5!important; color:#fff; }
.bg-macstore { background-color: #333333!important; color:#fff; }
.bg-myspace { background-color: #666666!important; color:#fff; }
.bg-podcast { background-color: #E4B21B!important; color:#fff; }
.bg-cloudapp { background-color: #525557!important; color:#fff; }
.bg-dropbox { background-color: #3D9AE8!important; color:#fff; }
.bg-ebay { background-color: #89C507!important; color:#fff; }
.bg-github { background-color: #171515!important; color:#fff; }
.bg-itunes { background-color: #222!important; color:#fff; }
.bg-plurk { background-color: #CF5A00!important; color:#fff; }
.bg-pinboard { background-color: #0000E6!important; color:#fff; }
.bg-soundcloud { background-color: #FF7700!important; color:#fff; }
.bg-tumblr { background-color: #34526F!important; color:#fff; }
.bg-wordpress { background-color: #1E8CBE!important; color:#fff; }
.bg-yelp { background-color: #C41200!important; color:#fff; }
.bg-intensedebate { background-color: #009EE4!important; color:#fff; }
.bg-eventbrite { background-color: #F16924!important; color:#fff; }
.bg-scribd { background-color: #666666!important; color:#fff; }
.bg-stripe { background-color: #008CDD!important; color:#fff; }
.bg-print { background-color: #111!important; color:#fff; }
.bg-dwolla { background-color: #FF5C03!important; color:#fff; }
.bg-statusnet { background-color: #131A30!important; color:#fff; }
.bg-acrobat { background-color: #D3222A!important; color:#fff; }
.bg-drupal { background-color: #27537A!important; color:#fff; }
.bg-buffer { background-color: #333333!important; color:#fff; }
.bg-pocket { background-color: #EE4056!important; color:#fff; }
.bg-bitbucket { background-color: #0E4984!important; color:#fff; }
.bg-stackoverflow { background-color: #EF8236!important; color:#fff; }
.bg-hackernews { background-color: #FF6600!important; color:#fff; }
.bg-xing { background-color: #126567!important; color:#fff; }
.bg-quora { background-color: #A82400!important; color:#fff; }
.bg-openid { background-color: #E16309!important; color:#fff; }
.bg-steam { background-color: #111!important; color:#fff; }
.bg-amazon { background-color: #E47911!important; color:#fff; }
.bg-disqus { background-color: #E4E7EE!important; color:#fff; }
.bg-plancast { background-color: #222!important; color:#fff; }
.bg-appstore { background-color: #000!important; color:#fff; }
*/
/**

		- SVG Fills
			.fill-primary, .fill-secondary, etc
			.fill-gray-*

**/
.fill-primary {
  fill: #377dff !important; }

.bg-primary-soft .fill-primary {
  fill: #518eff !important; }

.fill-secondary {
  fill: #6c757d !important; }

.bg-secondary-soft .fill-secondary {
  fill: #78828a !important; }

.fill-success {
  fill: #6dbb30 !important; }

.bg-success-soft .fill-success {
  fill: #79cc38 !important; }

.fill-info {
  fill: #00dffc !important; }

.bg-info-soft .fill-info {
  fill: #17e4ff !important; }

.fill-warning {
  fill: #fad776 !important; }

.bg-warning-soft .fill-warning {
  fill: #f8c945 !important; }

.fill-danger {
  fill: #dc3545 !important; }

.bg-danger-soft .fill-danger {
  fill: #e04b59 !important; }

.fill-light {
  fill: #f8f9fa !important; }

.bg-light-soft .fill-light {
  fill: white !important; }

.fill-dark {
  fill: #000000 !important; }

.bg-dark-soft .fill-dark {
  fill: #0d0d0d !important; }

.fill-indigo {
  fill: #533be2 !important; }

.bg-indigo-soft .fill-indigo {
  fill: #6651e5 !important; }

.fill-purple {
  fill: #4c2c92 !important; }

.bg-purple-soft .fill-purple {
  fill: #5632a6 !important; }

.fill-pink {
  fill: #e83e8c !important; }

.bg-pink-soft .fill-pink {
  fill: #eb559a !important; }

.fill-gray-100 {
  fill: #f8f9fa !important; }

.fill-gray-200 {
  fill: #e9ecef !important; }

.fill-gray-300 {
  fill: #dee2e6 !important; }

.fill-gray-400 {
  fill: #ced4da !important; }

.fill-gray-500 {
  fill: #adb5bd !important; }

.fill-gray-600 {
  fill: #6c757d !important; }

.fill-gray-700 {
  fill: #495057 !important; }

.fill-gray-800 {
  fill: #343a40 !important; }

.fill-gray-900 {
  fill: #212529 !important; }

/**

		- Scroll 				.scrollable-vertical
								.scrollable-horizontal

**/
/* scrollable vertical */
.scrollable-vertical {
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  /* mobile smooth scroll */
  height: auto;
  max-height: 100%; }

/*

	scrollable horizontal
	Do not use dropdowns inside

	@Dependencies: 		_responsive.scss
						scroll styling : desktop only

*/
.scrollable-horizontal {
  /*overflow: hidden;*/
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  width: auto;
  white-space: nowrap;
  position: relative;
  z-index: 100;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .scrollable-horizontal::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    background: transparent !important; }
  .scrollable-horizontal::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background: transparent !important; }
  .scrollable-horizontal::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none !important;
    background: transparent !important;
    width: 0px !important;
    height: 0px !important;
    outline: none !important; }
  .scrollable-horizontal::-webkit-scrollbar-button {
    background: transparent !important;
    width: 0px !important;
    height: 0px !important;
    outline: none !important; }

@media only screen and (min-width: 768px) {
  /* webkit scrollbar style */
  .scrollable-vertical {
    scrollbar-color: #121212 #eeeeee;
    scrollbar-width: thin; }
  .scrollable-vertical::-webkit-scrollbar-track,
  .scrollable-vertical::-webkit-scrollbar,
  .scrollable-vertical::-webkit-scrollbar-thumb,
  .scrollable-styled-dark::-webkit-scrollbar-track,
  .scrollable-styled-light::-webkit-scrollbar-track,
  .scrollable-styled-dark::-webkit-scrollbar,
  .scrollable-styled-light::-webkit-scrollbar,
  .scrollable-styled-dark::-webkit-scrollbar-thumb,
  .scrollable-styled-light::-webkit-scrollbar-thumb {
    margin: 3px 0;
    border-radius: 0.25rem; }
  .scrollable-vertical::-webkit-scrollbar,
  .scrollable-styled-dark::-webkit-scrollbar,
  .scrollable-styled-light::-webkit-scrollbar {
    width: 3px; }
  /* hidden, shown on mouse over */
  .scrollable-styled-light::-webkit-scrollbar,
  .scrollable-styled-light::-webkit-scrollbar-thumb,
  .scrollable-styled-light::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0); }
  .scrollable-styled-dark::-webkit-scrollbar-track,
  .scrollable-styled-light::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0); }
  /* dark vertical : default */
  .scrollable-vertical:hover::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.1); }
  .scrollable-vertical:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); }
  .scrollable-vertical:hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.1); }
  /* light */
  .scrollable-styled-light:hover::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.1); }
  .scrollable-styled-light:hover::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4); }
  .scrollable-styled-light:hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1); }
  /* dark */
  .scrollable-styled-dark:hover::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.1); }
  .scrollable-styled-dark:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); }
  .scrollable-styled-dark:hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.1); } }

/**

		- Hide

**/
.hide {
  display: none; }

.hide-force {
  display: none !important; }

/* autohide element if empty (no spaces, nothing) */
.hide-empty:empty {
  display: none !important; }

/*

	Items are hidden but visible on mouse over.
	Example: seful to hide "delete" button but visible on hover
	** Always visible on mobile!

	<div class="show-hover-container">

		<div class="show-hover-item">...</div>

	</div>

*/
@media only screen and (min-width: 992px) {
  .show-hover-container:not(:hover) .show-hover-item {
    visibility: hidden;
    -webkit-transition: box-shadow 250ms ease,-webkit-transform 250ms ease;
    transition: box-shadow 250ms ease,-webkit-transform 250ms ease;
    transition: box-shadow 250ms ease,transform 250ms ease;
    transition: box-shadow 250ms ease,transform 250ms ease,-webkit-transform 250ms ease; }
  .show-hover-container:hover .show-hover-item {
    visibility: visible !important; } }

/* all devices */
.show-hover-container-all:not(:hover) .show-hover-item-all {
  visibility: hidden;
  -webkit-transition: box-shadow 250ms ease,-webkit-transform 250ms ease;
  transition: box-shadow 250ms ease,-webkit-transform 250ms ease;
  transition: box-shadow 250ms ease,transform 250ms ease;
  transition: box-shadow 250ms ease,transform 250ms ease,-webkit-transform 250ms ease; }

.show-hover-container-all:hover .show-hover-item-all {
  visibility: visible !important; }

/*

	VIZIBILITY CLASSES, EASIER|INTUITIVE (old bootstrap)
	INSTEAD OF .d-*
	https://getbootstrap.com/docs/4.3/utilities/display/

	MIXIN USED

*/
.hidden-xs-up {
  display: none !important; }

@media (max-width: 575.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

/**

		- Cursors

**/
.cursor-pointer {
  cursor: pointer; }

.cursor-text {
  cursor: text; }

.sortable-handle,
.cursor-move {
  cursor: move;
  cursor: -webkit-grabbing; }

/**

		- Flex

**/
.flex-none {
  -webkit-box-flex: 0 !important;
          flex: none !important; }

.d-flex-align-middle {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center; }

.d-flex-1-0-auto {
  -webkit-box-flex: 1;
          flex: 1 0 auto; }

.d-flex-1-1-auto {
  -webkit-box-flex: 1 !important;
          flex: 1 1 auto !important; }

.d-flex-inherit {
  -webkit-box-flex: inherit !important;
          flex: inherit !important; }

.justify-content-inherit {
  -webkit-box-pack: inherit !important;
          justify-content: inherit !important; }

/**

		:: WORKING : DISABLED|NOT NEEDED ::

		Squared Grid
		:: Chrome, FF, Opera, Safari (IE not tested)


.row-squared {
	display: flex;
	flex-wrap: wrap;
}
	.col-squared {
		width: 100%;
	}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	.col-squared {
		width: 50%;
	}
}
@media (max-width: map-get($grid-breakpoints, "lg") - 1) { // xl: 1200px, lg: 992px, md: 768px
	.col-squared {
		width: 25%;
	}
}
.col-squared:before {
	content: "";
	float: left;
	padding-top: 100%;
}

**/
/**

		- Bordered Grid 			.row-grid

**/
/* bordered grid */
.ul-grid,
.row-grid {
  border-top: 1px solid #e9ecef;
  border-left: 1px solid #e9ecef; }

.ul-grid > li,
.row-grid > div {
  border-bottom: 1px solid #e9ecef;
  padding: 0;
  border-right: 1px solid #e9ecef; }

/*
  Expanded bootstrap : grid 5 columns
*/
.col-5th,
.col-sm-5th,
.col-md-5th,
.col-lg-5th,
.col-xl-5th {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-5th {
  -webkit-box-flex: 0;
  flex: 0 0 20%;
  max-width: 20%; }

@media only screen and (min-width: 576px) {
  .col-sm-5th {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%; } }

@media only screen and (min-width: 768px) {
  .col-md-5th {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 960px) {
  .col-lg-5th {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) {
  .col-xl-5th {
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20%; } }

/*
  Grid Gutters
*/
.gutters-xxs {
  margin-right: -0.125rem;
  margin-left: -0.125rem; }
  .gutters-xxs > [class*="col-"] {
    padding-right: 0.125rem;
    padding-left: 0.125rem; }

.gutters-xs {
  margin-right: -0.25rem;
  margin-left: -0.25rem; }
  .gutters-xs > [class*="col-"] {
    padding-right: 0.25rem;
    padding-left: 0.25rem; }

.gutters-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }
  .gutters-sm > [class*="col-"] {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }

.gutters-md {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }
  .gutters-md > [class*="col-"] {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }

.gutters-lg {
  margin-right: -1.5rem;
  margin-left: -1.5rem; }
  .gutters-lg > [class*="col-"] {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }

.gutters-xl {
  margin-right: -3rem;
  margin-left: -3rem; }
  .gutters-xl > [class*="col-"] {
    padding-right: 3rem;
    padding-left: 3rem; }

/*
  Bordered columns
*/
.row.col-border > div:not(:first-child) {
  border-left: rgba(0, 0, 0, 0.1) 1px solid; }

@media only screen and (min-width: 576px) {
  .row.col-border-sm > div:not(:first-child) {
    border-left: rgba(0, 0, 0, 0.1) 1px solid; } }

@media only screen and (min-width: 768px) {
  .row.col-border-md > div:not(:first-child) {
    border-left: rgba(0, 0, 0, 0.1) 1px solid; } }

@media only screen and (min-width: 992px) {
  .row.col-border-lg > div:not(:first-child) {
    border-left: rgba(0, 0, 0, 0.1) 1px solid; } }

/*
  Grid notes-like
*/
.grid-notebook {
  display: block;
  font-size: 1.0625rem;
  line-height: 2.125rem;
  line-height: 200%;
  padding: 0.455rem 1.5625rem 0 1.5625rem;
  background-image: -webkit-linear-gradient(top, transparent, transparent 2.0625rem, #E7EFF8 0);
  background-size: 100% 2.125rem;
  background-attachment: local; }

.grid-notebook p,
.grid-notebook ul,
.grid-notebook ol {
  margin-bottom: 0 !important; }

.grid-notebook h1,
.grid-notebook h2,
.grid-notebook h3,
.grid-notebook h4,
.grid-notebook h5,
.grid-notebook h6 {
  line-height: 2.125rem !important;
  margin-bottom: 0 !important; }

.grid-notebook h1,
.grid-notebook h2 {
  font-size: 1.5625rem !important; }

.grid-notebook h3 {
  font-size: 1.25rem !important; }

.grid-notebook h4,
.grid-notebook h5,
.grid-notebook h6 {
  font-size: 1.125rem !important; }

/**

		- Line Height

**/
.line-height-0 {
  line-height: 0 !important; }

.line-height-1 {
  line-height: 1 !important; }

.line-height-inherit {
  line-height: inherit !important; }

/**

		- Table

**/
/* bootstrap fix */
.d-table {
  position: relative;
  width: 100%;
  height: 100%; }

/* slightly text shadow */
.text-shadow-dark {
  text-shadow: rgba(0, 0, 0, 0.3) 0 1px 0; }

.text-monospace {
  -webkit-font-smoothing: initial; }

/* text color missing from botostrap */
.text-gray-100 {
  color: #f8f9fa !important; }

.text-gray-200 {
  color: #e9ecef !important; }

.text-gray-300 {
  color: #dee2e6 !important; }

.text-gray-400 {
  color: #ced4da !important; }

.text-gray-500 {
  color: #adb5bd !important; }

.text-gray-600 {
  color: #6c757d !important; }

.text-gray-700 {
  color: #495057 !important; }

.text-gray-800 {
  color: #343a40 !important; }

.text-gray-900 {
  color: #212529 !important; }

/**

		- Text and Link

**/
.link-muted {
  color: #6c757d;
  border-bottom: #97a4af 1px dashed;
  text-decoration: none !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/*

	Links
		.text-
		.text-*-hover

*/
.text-primary-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-primary-hover:focus,
a.text-primary-hover:hover {
  color: #377dff !important; }

.text-secondary-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-secondary-hover:focus,
a.text-secondary-hover:hover {
  color: #6c757d !important; }

.text-success-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-success-hover:focus,
a.text-success-hover:hover {
  color: #6dbb30 !important; }

.text-info-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-info-hover:focus,
a.text-info-hover:hover {
  color: #00dffc !important; }

.text-warning-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-warning-hover:focus,
a.text-warning-hover:hover {
  color: #fad776 !important; }

.text-danger-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-danger-hover:focus,
a.text-danger-hover:hover {
  color: #dc3545 !important; }

.text-light-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-light-hover:focus,
a.text-light-hover:hover {
  color: #f8f9fa !important; }

.text-dark-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-dark-hover:focus,
a.text-dark-hover:hover {
  color: #000000 !important; }

.text-indigo-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-indigo-hover:focus,
a.text-indigo-hover:hover {
  color: #533be2 !important; }

.text-purple-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-purple-hover:focus,
a.text-purple-hover:hover {
  color: #4c2c92 !important; }

.text-pink-hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.text-pink-hover:focus,
a.text-pink-hover:hover {
  color: #e83e8c !important; }

/**

		- Z Index

**/
/* z-index */
.z-index-n1 {
  z-index: -1 !important; }

.z-index-0 {
  z-index: 0 !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-10 {
  z-index: 10 !important; }

.z-index-99 {
  z-index: 99 !important; }

.z-index-100 {
  z-index: 100 !important; }

.z-index-1000 {
  z-index: 1000 !important; }

.z-index-9999 {
  z-index: 9999 !important; }

.z-index-11 {
  z-index: 11 !important; }

/**

		- Rtl Utils 				.text-align-start, .text-align-end, .float-start, .float-end
									.p--0-start, .p--0-end, .m--0-start, .m--0-end
									.b--0-start, .b--0-end, .b--0-start, .b--0-end
									.m-auto-start, .m-auto-end

**/
.text-align-start {
  text-align: left !important; }

.text-align-end {
  text-align: right !important; }

.float-start {
  float: left !important;
  margin-right: 10px; }

.float-end {
  float: right !important;
  margin-left: 10px; }

.p--0-start {
  padding-left: 0 !important; }

.p--0-end {
  padding-right: 0 !important; }

.m--0-start {
  margin-left: 0 !important; }

.m--0-end {
  margin-right: 0 !important; }

.m-auto-start {
  margin-left: auto !important; }

.m-auto-end {
  margin-right: auto !important; }

.b--0-start {
  border-left: 0 !important; }

.b--0-end {
  border-right: 0 !important; }

.hide-ltr {
  display: none !important; }

/**

		- Generic Animations 		.animate-fadein 			CSS: 	animation: __utilFadeIn .25s ease-out forwards;
                                    .animate-fadeinSlow         CSS:    animation: __utilFadeInSlow 2s ease;
									.animate-bouncein 			CSS: 	animation: __utilBounceIn .25s ease-out forwards;
									.animate-bounceinup			CSS: 	animation: __utilBounceInUp .25s ease-out forwards;
									.animate-shine				CSS: 	animation: __utilShine 6s infinite ease-in-out;
									.animate-pulse 				CSS: 	animation: __utilPulse 2s infinite;

									.animate-reveal-up	 		(50px)
									.animate-reveal-down 		(50px)
**/
.animate-none {
  -webkit-animation: none !important;
          animation: none !important; }

/*

	Animate Reveal

*/
.animate-reveal-up-item > .reveal-item,
.animate-reveal-up {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1); }

.animate-reveal-up-item:hover > .reveal-item,
.animate-reveal-up:hover {
  -webkit-transform: translate3d(0, -50px, 0);
          transform: translate3d(0, -50px, 0); }

.animate-reveal-down-item > .reveal-item,
.animate-reveal-down {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1); }

.animate-reveal-down-item:hover > .reveal-item,
.animate-reveal-down:hover {
  -webkit-transform: translate3d(0, 50px, 0);
          transform: translate3d(0, 50px, 0); }

@-webkit-keyframes __utilFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes __utilFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.animate-fadein {
  -webkit-animation: __utilFadeIn .30s ease;
  /* Safari, Chrome and Opera > 12.1 */
  animation: __utilFadeIn .30s ease;
  -webkit-animation-duration: .50s;
          animation-duration: .50s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

/*

	Fade In
	animation: __utilFadeIn .25s ease-out forwards;

*/
@-webkit-keyframes __utilFadeInSlow {
  0% {
    opacity: 0; }
  25% {
    opacity: 0.3; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes __utilFadeInSlow {
  0% {
    opacity: 0; }
  25% {
    opacity: 0.3; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

.animate-fadein__slow {
  -webkit-animation: __utilFadeInSlow 2s ease;
  /* Safari, Chrome and Opera > 12.1 */
  animation: __utilFadeInSlow 2s ease;
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

/*

	Bounce In
	animation: __utilBounceIn .25s ease-out forwards;

*/
@-webkit-keyframes __utilBounceIn {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0; }
  60% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
@keyframes __utilBounceIn {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0; }
  60% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.animate-bouncein {
  -webkit-animation: __utilBounceIn .25s ease-out forwards;
          animation: __utilBounceIn .25s ease-out forwards; }

/*

	Bounce In Up
	animation: __utilBounceInUp .25s ease-out forwards;

*/
@-webkit-keyframes __utilBounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(600px); }
  25% {
    opacity: 1;
    -webkit-transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0); } }

@keyframes __utilBounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(600px);
            transform: translateY(600px); }
  25% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px); }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.animate-bounceinup {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: __utilBounceInUp;
  animation-name: __utilBounceInUp; }

@-webkit-keyframes __utilShine {
  1% {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  25% {
    opacity: 0.1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  50% {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  75% {
    opacity: 0.1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; } }

@keyframes __utilShine {
  1% {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  25% {
    opacity: 0.1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  50% {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  75% {
    opacity: 0.1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition-duration: .1s;
            transition-duration: .1s; } }

.animate-shine:after {
  -webkit-animation: __utilShine 6s infinite ease-in-out;
          animation: __utilShine 6s infinite ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  content: "";
  position: absolute;
  top: -10%;
  left: 0%;
  width: 120%;
  height: 120%;
  opacity: 0;
  z-index: 0;
  border-radius: 50%;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  background: rgba(248, 249, 250, 0.3);
  background: -webkit-gradient(linear, left top, right top, from(rgba(248, 249, 250, 0)), color-stop(50%, rgba(255, 255, 255, 0.3)), to(rgba(248, 249, 250, 0)));
  background: linear-gradient(to right, rgba(248, 249, 250, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(248, 249, 250, 0) 100%); }

.animate-pulse {
  -webkit-animation: __utilPulse 2s infinite;
  animation: __utilPulse 2s infinite; }

@-webkit-keyframes __utilPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.8); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(220, 53, 69, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0); } }

@keyframes __utilPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.8); }
  70% {
    box-shadow: 0 0 0 10px rgba(220, 53, 69, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0); } }

/*

	Smooth Zoom
	https://w3bits.com/labs/css-image-hover-zoom/

*/
.img-hover-zoom {
  background: #222f3e;
  height: 300px;
  overflow: hidden;
  border-radius: .5em;
  border: 5px solid rgba(255, 255, 255, 0.05); }

.img-hover-zoom--empty {
  background-color: rgba(255, 255, 255, 0.1);
  border-width: 0; }

.img-hover-zoom:not(.img-hover-zoom--empty):hover {
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3)); }

.img-hover-zoom--basic img {
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease; }

.img-hover-zoom--basic:hover img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5); }

.img-hover-zoom--quick-zoom img {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: visibility .25s ease-in, -webkit-transform .25s;
  transition: visibility .25s ease-in, -webkit-transform .25s;
  transition: transform .25s, visibility .25s ease-in;
  transition: transform .25s, visibility .25s ease-in, -webkit-transform .25s; }

.img-hover-zoom--quick-zoom:hover img {
  -webkit-transform: scale(2);
          transform: scale(2); }

.img-hover-zoom--point-zoom img {
  -webkit-transform-origin: 65% 75%;
          transform-origin: 65% 75%;
  -webkit-transition: -webkit-transform 1s, -webkit-filter .5s ease-out;
  transition: -webkit-transform 1s, -webkit-filter .5s ease-out;
  transition: transform 1s, filter .5s ease-out;
  transition: transform 1s, filter .5s ease-out, -webkit-transform 1s, -webkit-filter .5s ease-out; }

.img-hover-zoom--point-zoom:hover img {
  -webkit-transform: scale(5);
          transform: scale(5); }

.img-hover-zoom--zoom-n-rotate img {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out; }

.img-hover-zoom--zoom-n-rotate:hover img {
  -webkit-transform: scale(2) rotate(25deg);
          transform: scale(2) rotate(25deg); }

.img-hover-zoom--slowmo img {
  -webkit-transform-origin: 50% 65%;
          transform-origin: 50% 65%;
  -webkit-transition: -webkit-transform 5s, -webkit-filter 3s ease-in-out;
  transition: -webkit-transform 5s, -webkit-filter 3s ease-in-out;
  transition: transform 5s, filter 3s ease-in-out;
  transition: transform 5s, filter 3s ease-in-out, -webkit-transform 5s, -webkit-filter 3s ease-in-out;
  -webkit-filter: brightness(150%);
          filter: brightness(150%); }

.img-hover-zoom--slowmo:hover img {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  -webkit-transform: scale(3);
          transform: scale(3); }

.img-hover-zoom--brightness img {
  -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
  transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
  transition: transform 2s, filter 1.5s ease-in-out;
  transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-filter: brightness(50%);
          filter: brightness(50%); }

.img-hover-zoom--brightness:hover img {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  -webkit-transform: scale(1.3);
          transform: scale(1.3); }

.img-hover-zoom--zoom-n-pan-h img {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0; }

.img-hover-zoom--zoom-n-pan-h:hover img {
  -webkit-transform: scale(1.5) translateX(30%);
          transform: scale(1.5) translateX(30%); }

.img-hover-zoom--zoom-n-pan-v img {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; }

.img-hover-zoom--zoom-n-pan-v:hover img {
  -webkit-transform: scale(1.25) translateY(-30%);
          transform: scale(1.25) translateY(-30%); }

.img-hover-zoom--blur img {
  -webkit-transition: -webkit-transform 1s, -webkit-filter 2s ease-in-out;
  transition: -webkit-transform 1s, -webkit-filter 2s ease-in-out;
  transition: transform 1s, filter 2s ease-in-out;
  transition: transform 1s, filter 2s ease-in-out, -webkit-transform 1s, -webkit-filter 2s ease-in-out;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

.img-hover-zoom--blur:hover img {
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transform: scale(1);
          transform: scale(1); }

.img-hover-zoom--colorize img {
  -webkit-transition: -webkit-transform .5s, -webkit-filter 1.5s ease-in-out;
  transition: -webkit-transform .5s, -webkit-filter 1.5s ease-in-out;
  transition: transform .5s, filter 1.5s ease-in-out;
  transition: transform .5s, filter 1.5s ease-in-out, -webkit-transform .5s, -webkit-filter 1.5s ease-in-out;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }

.img-hover-zoom--colorize:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

/**

		- Responsive (mobile) 		.text-center-xs, .float-none-xs, .d-block-xs
									.m--0-xs, .m*--0-xs,
									.p--0-xs, .p*--0-xs,
									.b--0-xs, .b*--0-xs,

**/
@media only screen and (min-width: 991px) {
  .float-none-lg {
    float: none !important; }
  .b-0-lg,
  .b--0-lg {
    border: 0 !important; }
  .border-bottom-lg {
    border-bottom: #dee2e6 1px solid; }
  .border-top-lg {
    border-top: #dee2e6 1px solid; }
  .m-0-lg,
  .m--0-lg {
    border: 0 !important; }
  .p-0-lg,
  .p--0-lg {
    border: 0 !important; } }

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .text-center-md {
    text-align: center !important; }
  .text-start-md {
    text-align: left !important; }
  .float-none-md {
    float: none !important; }
  .d-block-md {
    display: block !important; }
  /* button fix */
  .btn.d-block-md {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .position-absolute-md {
    position: absolute !important; }
  .position-relative-md {
    position: relative !important; }
  .position-fixed-md {
    position: fixed !important; }
  .shadow-none-md {
    box-shadow: none !important; }
  .m-0-md,
  .m--0-md {
    margin: 0 !important; }
  .mt-0-md,
  .mt--0-md {
    margin-top: 0 !important; }
  .ml-0-md,
  .ml--0-md {
    margin-left: 0 !important; }
  .mr-0-md,
  .mr--0-md {
    margin-right: 0 !important; }
  .mb-0-md,
  .mb--0-md {
    margin-bottom: 0 !important; }
  .m-15-md,
  .m--15-md {
    margin: 15px !important; }
  .p-0-md,
  .p--0-md {
    padding: 0 !important; }
  .pt-0-md,
  .pt--0-md {
    padding-top: 0 !important; }
  .pl-0-md,
  .pl--0-md {
    padding-left: 0 !important; }
  .pr-0-md,
  .pr--0-md {
    padding-right: 0 !important; }
  .pb-0-md,
  .pb--0-md {
    padding-bottom: 0 !important; }
  .p-15-md,
  .p--15-md {
    padding: 15px !important; }
  .b-0-md,
  .b--0-md {
    border: 0 !important; }
  .bt-0-md,
  .bt--0-md {
    border-top: 0 !important; }
  .bl-0-md,
  .bl--0-md {
    border-left: 0 !important; }
  .br-0-md,
  .br--0-md {
    border-right: 0 !important; }
  .bb-0-md,
  .bb--0-md {
    border-bottom: 0 !important; }
  .border-bottom-md {
    border-bottom: #dee2e6 1px solid; }
  .border-top-md {
    border-top: #dee2e6 1px solid; }
  .rounded-0-md,
  .round-0-md,
  .r-0-md,
  .r--0-md {
    border-radius: 0 !important; }
  .w-100-md {
    max-width: 100% !important;
    min-width: 0% !important;
    width: 100% !important; }
  .w-100vh-md {
    max-width: 100% !important;
    width: 100vh !important; }
  .w-auto-md {
    width: auto !important; }
  .h-100-md {
    height: auto;
    min-height: 100% !important; }
  .h-100vh-md {
    height: auto;
    min-height: 100vh !important; }
  .h-auto-md {
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important; }
  .rounded-circle-md {
    border-radius: 50% !important; }
  .overlay-none-md:after {
    display: none !important; }
  .h1-md {
    font-size: 2.65625rem !important; }
  .h2-md {
    font-size: 2.125rem !important; }
  .h3-md {
    font-size: 1.85938rem !important; }
  .h4-md {
    font-size: 1.59375rem !important; }
  .h5-md {
    font-size: 1.32812rem !important; }
  .h6-md {
    font-size: 1.0625rem !important; } }

@media only screen and (max-width: 768px) {
  .text-center-xs {
    text-align: center !important; }
  .text-start-xs {
    text-align: left !important; }
  .float-none-xs {
    float: none !important; }
  .d-block-xs {
    display: block !important; }
  /* button fix */
  .btn.d-block-xs {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .position-absolute-xs {
    position: absolute !important; }
  .position-relative-xs {
    position: relative !important; }
  .position-fixed-xs {
    position: fixed !important; }
  .shadow-none-xs {
    box-shadow: none !important; }
  .m-0-xs,
  .m--0-xs {
    margin: 0 !important; }
  .mt-0-xs,
  .mt--0-xs {
    margin-top: 0 !important; }
  .ml-0-xs,
  .ml--0-xs {
    margin-left: 0 !important; }
  .mr-0-xs,
  .mr--0-xs {
    margin-right: 0 !important; }
  .mb-0-xs,
  .mb--0-xs {
    margin-bottom: 0 !important; }
  .m-15-xs,
  .m--15-xs {
    margin: 15px !important; }
  .p-0-xs,
  .p--0-xs {
    padding: 0 !important; }
  .pt-0-xs,
  .pt--0-xs {
    padding-top: 0 !important; }
  .pl-0-xs,
  .pl--0-xs {
    padding-left: 0 !important; }
  .pr-0-xs,
  .pr--0-xs {
    padding-right: 0 !important; }
  .pb-0-xs,
  .pb--0-xs {
    padding-bottom: 0 !important; }
  .p-15-xs,
  .p--15-xs {
    padding: 15px !important; }
  .b-0-xs,
  .b--0-xs {
    border: 0 !important; }
  .bt-0-xs,
  .bt--0-xs {
    border-top: 0 !important; }
  .bl-0-xs,
  .bl--0-xs {
    border-left: 0 !important; }
  .br-0-xs,
  .br--0-xs {
    border-right: 0 !important; }
  .bb-0-xs,
  .bb--0-xs {
    border-bottom: 0 !important; }
  .border-bottom-xs {
    border-bottom: #dee2e6 1px solid; }
  .border-top-xs {
    border-top: #dee2e6 1px solid; }
  .rounded-0-xs,
  .round-0-xs,
  .r-0-xs,
  .r--0-xs {
    border-radius: 0 !important; }
  .w-100-xs {
    max-width: 100% !important;
    min-width: 0% !important;
    width: 100% !important; }
  .w-100vh-xs {
    max-width: 100% !important;
    width: 100vh !important; }
  .w-auto-xs {
    width: auto !important; }
  .h-100-xs {
    height: auto;
    min-height: 100% !important; }
  .h-100vh-xs {
    height: auto;
    min-height: 100vh !important; }
  .h-auto-xs {
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important; }
  .rounded-circle-xs {
    border-radius: 50% !important; }
  .overlay-none-xs:after {
    display: none !important; }
  .h1-xs {
    font-size: 2.65625rem !important; }
  .h2-xs {
    font-size: 2.125rem !important; }
  .h3-xs {
    font-size: 1.85938rem !important; }
  .h4-xs {
    font-size: 1.59375rem !important; }
  .h5-xs {
    font-size: 1.32812rem !important; }
  .h6-xs {
    font-size: 1.0625rem !important; }
  /*
	  Grid Gutters
	*/
  .gutters-xxs--xs {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important; }
    .gutters-xxs--xs > [class*="col-"] {
      padding-right: 0.125rem !important;
      padding-left: 0.125rem !important; }
  .gutters-xs--xs {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
    .gutters-xs--xs > [class*="col-"] {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
  .gutters-sm--xs {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
    .gutters-sm--xs > [class*="col-"] {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
  .gutters-md--xs {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
    .gutters-md--xs > [class*="col-"] {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; }
  .m-1-xs {
    margin: 0.25rem !important; }
  .m-2-xs {
    margin: 0.5rem !important; }
  .m-3-xs {
    margin: 1rem !important; }
  .m-4-xs {
    margin: 1.5rem !important; }
  .m-5-xs {
    margin: 3rem !important; }
  .mt-1-xs {
    margin-top: 0.25rem !important; }
  .mt-2-xs {
    margin-top: 0.5rem !important; }
  .mt-3-xs {
    margin-top: 1rem !important; }
  .mt-4-xs {
    margin-top: 1.5rem !important; }
  .mt-5-xs {
    margin-top: 3rem !important; }
  .mb-1-xs {
    margin-bottom: 0.25rem !important; }
  .mb-2-xs {
    margin-bottom: 0.5rem !important; }
  .mb-3-xs {
    margin-bottom: 1rem !important; }
  .mb-4-xs {
    margin-bottom: 1.5rem !important; }
  .mb-5-xs {
    margin-bottom: 3rem !important; }
  .p-1-xs {
    padding: 0.25rem !important; }
  .p-2-xs {
    padding: 0.5rem !important; }
  .p-3-xs {
    padding: 1rem !important; }
  .p-4-xs {
    padding: 1.5rem !important; }
  .p-5-xs {
    padding: 3rem !important; } }

.text-red-100 {
  color: #ffcdd2 !important; }

.text-red-200 {
  color: #ef9a9a !important; }

.text-red-300 {
  color: #e57373 !important; }

.text-red-400 {
  color: #ef5350 !important; }

.text-red-500 {
  color: #f44336 !important; }

.text-red-600 {
  color: #e53935 !important; }

.text-red-700 {
  color: #d32f2f !important; }

.text-red-800 {
  color: #c62828 !important; }

.text-red-900 {
  color: #b71c1c !important; }

.text-pink-100 {
  color: #f8bbd0 !important; }

.text-pink-200 {
  color: #f48fb1 !important; }

.text-pink-300 {
  color: #f06292 !important; }

.text-pink-400 {
  color: #ec407a !important; }

.text-pink-500 {
  color: #e91e63 !important; }

.text-pink-600 {
  color: #d81b60 !important; }

.text-pink-700 {
  color: #c2185b !important; }

.text-pink-800 {
  color: #ad1457 !important; }

.text-pink-900 {
  color: #880e4f !important; }

.text-purple-100 {
  color: #e1bee7 !important; }

.text-purple-200 {
  color: #ce93d8 !important; }

.text-purple-300 {
  color: #ba68c8 !important; }

.text-purple-400 {
  color: #ab47bc !important; }

.text-purple-500 {
  color: #9c27b0 !important; }

.text-purple-600 {
  color: #8e24aa !important; }

.text-purple-700 {
  color: #7b1fa2 !important; }

.text-purple-800 {
  color: #6a1b9a !important; }

.text-purple-900 {
  color: #4a148c !important; }

.text-deep-purple-100 {
  color: #d1c4e9 !important; }

.text-deep-purple-200 {
  color: #b39ddb !important; }

.text-deep-purple-300 {
  color: #9575cd !important; }

.text-deep-purple-400 {
  color: #7e57c2 !important; }

.text-deep-purple-500 {
  color: #673ab7 !important; }

.text-deep-purple-600 {
  color: #5e35b1 !important; }

.text-deep-purple-700 {
  color: #512da8 !important; }

.text-deep-purple-800 {
  color: #4527a0 !important; }

.text-deep-purple-900 {
  color: #311b92 !important; }

.text-indigo-100 {
  color: #c5cae9 !important; }

.text-indigo-200 {
  color: #9fa8da !important; }

.text-indigo-300 {
  color: #7986cb !important; }

.text-indigo-400 {
  color: #5c6bc0 !important; }

.text-indigo-500 {
  color: #3f51b5 !important; }

.text-indigo-600 {
  color: #3949ab !important; }

.text-indigo-700 {
  color: #303f9f !important; }

.text-indigo-800 {
  color: #283593 !important; }

.text-indigo-900 {
  color: #1a237e !important; }

.text-blue-100 {
  color: #bbdefb !important; }

.text-blue-200 {
  color: #90caf9 !important; }

.text-blue-300 {
  color: #64b5f6 !important; }

.text-blue-400 {
  color: #42a5f5 !important; }

.text-blue-500 {
  color: #2196f3 !important; }

.text-blue-600 {
  color: #1e88e5 !important; }

.text-blue-700 {
  color: #1976d2 !important; }

.text-blue-800 {
  color: #1565c0 !important; }

.text-blue-900 {
  color: #0d47a1 !important; }

.text-light-blue-100 {
  color: #b3e5fc !important; }

.text-light-blue-200 {
  color: #81d4fa !important; }

.text-light-blue-300 {
  color: #4fc3f7 !important; }

.text-light-blue-400 {
  color: #29b6f6 !important; }

.text-light-blue-500 {
  color: #03a9f4 !important; }

.text-light-blue-600 {
  color: #039be5 !important; }

.text-light-blue-700 {
  color: #0288d1 !important; }

.text-light-blue-800 {
  color: #0277bd !important; }

.text-light-blue-900 {
  color: #01579b !important; }

.text-cyan-100 {
  color: #b2ebf2 !important; }

.text-cyan-200 {
  color: #80deea !important; }

.text-cyan-300 {
  color: #4dd0e1 !important; }

.text-cyan-400 {
  color: #26c6da !important; }

.text-cyan-500 {
  color: #00bcd4 !important; }

.text-cyan-600 {
  color: #00acc1 !important; }

.text-cyan-700 {
  color: #0097a7 !important; }

.text-cyan-800 {
  color: #00838f !important; }

.text-cyan-900 {
  color: #006064 !important; }

.text-teal-100 {
  color: #b2dfdb !important; }

.text-teal-200 {
  color: #80cbc4 !important; }

.text-teal-300 {
  color: #4db6ac !important; }

.text-teal-400 {
  color: #26a69a !important; }

.text-teal-500 {
  color: #009688 !important; }

.text-teal-600 {
  color: #00897b !important; }

.text-teal-700 {
  color: #00796b !important; }

.text-teal-800 {
  color: #00695c !important; }

.text-teal-900 {
  color: #004d40 !important; }

.text-green-100 {
  color: #c8e6c9 !important; }

.text-green-200 {
  color: #a5d6a7 !important; }

.text-green-300 {
  color: #81c784 !important; }

.text-green-400 {
  color: #66bb6a !important; }

.text-green-500 {
  color: #4caf50 !important; }

.text-green-600 {
  color: #43a047 !important; }

.text-green-700 {
  color: #388e3c !important; }

.text-green-800 {
  color: #2e7d32 !important; }

.text-green-900 {
  color: #1b5e20 !important; }

.text-light-green-100 {
  color: #dcedc8 !important; }

.text-light-green-200 {
  color: #c5e1a5 !important; }

.text-light-green-300 {
  color: #aed581 !important; }

.text-light-green-400 {
  color: #9ccc65 !important; }

.text-light-green-500 {
  color: #8bc34a !important; }

.text-light-green-600 {
  color: #7cb342 !important; }

.text-light-green-700 {
  color: #689f38 !important; }

.text-light-green-800 {
  color: #558b2f !important; }

.text-light-green-900 {
  color: #33691e !important; }

.text-lime-100 {
  color: #f0f4c3 !important; }

.text-lime-200 {
  color: #e6ee9c !important; }

.text-lime-300 {
  color: #dce775 !important; }

.text-lime-400 {
  color: #d4e157 !important; }

.text-lime-500 {
  color: #cddc39 !important; }

.text-lime-600 {
  color: #c0ca33 !important; }

.text-lime-700 {
  color: #afb42b !important; }

.text-lime-800 {
  color: #9e9d24 !important; }

.text-lime-900 {
  color: #827717 !important; }

.text-yellow-100 {
  color: #fff9c4 !important; }

.text-yellow-200 {
  color: #fff59d !important; }

.text-yellow-300 {
  color: #fff176 !important; }

.text-yellow-400 {
  color: #ffee58 !important; }

.text-yellow-500 {
  color: #ffeb3b !important; }

.text-yellow-600 {
  color: #fdd835 !important; }

.text-yellow-700 {
  color: #fbc02d !important; }

.text-yellow-800 {
  color: #f9a825 !important; }

.text-yellow-900 {
  color: #f57f17 !important; }

.text-amber-100 {
  color: #ffecb3 !important; }

.text-amber-200 {
  color: #ffe082 !important; }

.text-amber-300 {
  color: #ffd54f !important; }

.text-amber-400 {
  color: #ffca28 !important; }

.text-amber-500 {
  color: #ffc107 !important; }

.text-amber-600 {
  color: #ffb300 !important; }

.text-amber-700 {
  color: #ffa000 !important; }

.text-amber-800 {
  color: #ff8f00 !important; }

.text-amber-900 {
  color: #ff6f00 !important; }

.text-orange-100 {
  color: #ffe0b2 !important; }

.text-orange-200 {
  color: #ffcc80 !important; }

.text-orange-300 {
  color: #ffb74d !important; }

.text-orange-400 {
  color: #ffa726 !important; }

.text-orange-500 {
  color: #ff9800 !important; }

.text-orange-600 {
  color: #fb8c00 !important; }

.text-orange-700 {
  color: #f57c00 !important; }

.text-orange-800 {
  color: #ef6c00 !important; }

.text-orange-900 {
  color: #e65100 !important; }

.text-deep-orange-100 {
  color: #ffccbc !important; }

.text-deep-orange-200 {
  color: #ffab91 !important; }

.text-deep-orange-300 {
  color: #ff8a65 !important; }

.text-deep-orange-400 {
  color: #ff7043 !important; }

.text-deep-orange-500 {
  color: #ff5722 !important; }

.text-deep-orange-600 {
  color: #f4511e !important; }

.text-deep-orange-700 {
  color: #e64a19 !important; }

.text-deep-orange-800 {
  color: #d84315 !important; }

.text-deep-orange-900 {
  color: #bf360c !important; }

.text-brown-100 {
  color: #d7ccc8 !important; }

.text-brown-200 {
  color: #bcaaa4 !important; }

.text-brown-300 {
  color: #a1887f !important; }

.text-brown-400 {
  color: #8d6e63 !important; }

.text-brown-500 {
  color: #795548 !important; }

.text-brown-600 {
  color: #6d4c41 !important; }

.text-brown-700 {
  color: #5d4037 !important; }

.text-brown-800 {
  color: #4e342e !important; }

.text-brown-900 {
  color: #3e2723 !important; }

.text-blue-gray-100 {
  color: #cfd8dc !important; }

.text-blue-gray-200 {
  color: #b0bec5 !important; }

.text-blue-gray-300 {
  color: #90a4ae !important; }

.text-blue-gray-400 {
  color: #78909c !important; }

.text-blue-gray-500 {
  color: #607d8b !important; }

.text-blue-gray-600 {
  color: #546e7a !important; }

.text-blue-gray-700 {
  color: #455a64 !important; }

.text-blue-gray-800 {
  color: #37474f !important; }

.text-blue-gray-900 {
  color: #263238 !important; }

/**

	BOOTSTRAP RTL FIXES
		various positioning

**/
.arrow {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border: 17px solid transparent; }

.arrow-start {
  border-right: solid 10px #f8f9fa;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -30px;
  margin-left: 3px; }

.arrow-left {
  border-right: solid 10px #f8f9fa;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -30px;
  margin-left: 3px; }

.arrow-end {
  border-left: solid 10px #f8f9fa;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -30px;
  margin-right: 3px; }

.arrow-right {
  border-left: solid 10px #f8f9fa;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -30px;
  margin-right: 3px; }

.arrow-top {
  border-bottom: solid 10px #f8f9fa;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  bottom: auto !important;
  top: -27px; }

.arrow-bottom {
  border-top: solid 10px #f8f9fa;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  top: auto !important;
  bottom: -27px; }

/* arrow-lg */
.arrow-lg {
  border: 34px solid transparent; }

.arrow-lg.arrow-start {
  border-right: solid 20px #f8f9fa;
  left: -60px;
  margin-left: 6px; }

.arrow-lg.arrow-left {
  border-right: solid 20px #f8f9fa;
  left: -60px;
  margin-left: 6px; }

.arrow-lg.arrow-end {
  border-left: solid 20px #f8f9fa;
  right: -60px;
  margin-right: 6px; }

.arrow-lg.arrow-right {
  border-left: solid 20px #f8f9fa;
  right: -60px;
  margin-right: 6px; }

.arrow-lg.arrow-top {
  border-bottom: solid 20px #f8f9fa;
  top: -54px; }

.arrow-lg.arrow-bottom {
  border-top: solid 20px #f8f9fa;
  bottom: -54px; }

/* center positioning */
.arrow-center.arrow-top,
.arrow-center.arrow-bottom {
  left: 50% !important;
  margin-left: -17px !important; }

.arrow-center.arrow-start,
.arrow-center.arrow-left,
.arrow-center.arrow-end,
.arrow-center.arrow-right {
  top: 50% !important;
  margin-top: -17px !important; }

/* center positioning -lg */
.arrow-lg.arrow-center.arrow-top,
.arrow-lg.arrow-center.arrow-bottom {
  margin-left: -34px !important; }

.arrow-lg.arrow-center.arrow-start,
.arrow-lg.arrow-center.arrow-left,
.arrow-lg.arrow-center.arrow-end,
.arrow-lg.arrow-center.arrow-right {
  margin-top: -34px !important; }

/**

	Various Blocks

**/
/*

	Image stretch
	Desktop only!

*/
@media only screen and (min-width: 992px) {
  .stretch-end {
    border-radius: 0.25rem;
    overflow: hidden;
    margin-right: -19.6vw !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .stretch-start {
    border-radius: 0.25rem;
    overflow: hidden;
    margin-left: -19.6vw !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; } }

/*

	Images Block

*/
.block-img-start .block-img,
.block-img-end .block-img {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem; }

@media only screen and (min-width: 992px) {
  .block-img-start .block-img-2,
  .block-img-end .block-img-2 {
    margin-top: -400px; }
  .block-img-start .block-img-1 {
    top: -80px;
    left: -78%; }
  .block-img-start .block-img-2 {
    left: -10%; }
  .block-img-start .block-img-3 {
    margin-top: -300px;
    left: -78%; }
  .block-img-end .block-img-1 {
    top: -80px;
    right: -78%; }
  .block-img-end .block-img-2 {
    right: -10%; }
  .block-img-end .block-img-3 {
    margin-top: -300px;
    right: -78%; } }

@media only screen and (max-width: 1199px) {
  .block-img-start .block-img,
  .block-img-end .block-img {
    display: inline-block;
    position: relative;
    width: 50%;
    top: 0;
    left: 0; }
  .block-img-start .block-img-1,
  .block-img-end .block-img-1 {
    left: 0; }
  .block-img-start .block-img-2,
  .block-img-end .block-img-2 {
    left: 25%;
    margin-top: -100%; }
  .block-img-start .block-img-3,
  .block-img-end .block-img-3 {
    left: 50%;
    margin-top: -100%; } }

/*

	Images Block 2
	All devices

*/
.block-img-card {
  display: block;
  position: relative; }

.block-img-card .block-img {
  display: inline-block;
  width: 50%;
  left: 0;
  border-radius: 0.25rem; }

.block-img-card .block-img-1 {
  left: 0;
  top: 0;
  position: relative; }

.block-img-card .block-img-2 {
  left: 25%;
  top: 5%;
  position: absolute; }

.block-img-card .block-img-3 {
  left: 50%;
  top: 10%;
  position: absolute; }

.btn {
  position: relative; }

.btn:not(.bg-transparent):not(.btn-ghost):not(.bg-nogradient):after {
  content: '';
  background-image: linear-gradient(-315deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.08));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.btn.rounded-circle:after {
  border-radius: 50%; }

.btn.btn-pill:after {
  border-radius: 50rem; }

.btn.rounded-xl:after {
  border-radius: .8rem; }

/**

	BUTTONS
		Soft buttons 		: based on bootstrap scheme
		Shadowed buttons 	: shadow on hover;
		Vendor buttons
		Pill buttons
		Icon buttons

			* cancel hover shadow by adding .btn-noshadow

**/
/*

	Icon Spacing|Correction

		Usage - span is required only if icon is present
			<a href="#!" class="btn btn-primary">
				<span>Button Text</span>
				<i class="fi fi-arrow-right fs--13"></i>
			</a>

			<a href="#!" class="btn btn-primary">
				<i class="fi fi-arrow-right fs--13"></i>
				<span>Button Text</span>
			</a>

*/
ul.list-icons > li > i,
.btn:not(.rounded-circle) > i {
  display: inline-block;
  margin-right: 10px; }

/*
		using .group-icon
		or icon is placed last one
	*/
.btn > span + .group-icon,
.btn > .group-icon + span,
.btn:not(.rounded-circle) > span + i {
  display: inline-block;
  margin-left: 10px;
  margin-right: 0; }

.btn + .btn {
  margin-left: .5rem; }

/*

	Modal & Alert Close Buttons

*/
button.close .fi {
  font-size: 18px; }

/*

	Soft buttons
		.btn-soft 			- transition to original button color
		.btn-soft-static 	- no transition, keep same color

*/
.btn-soft-static.btn-primary:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-primary:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #0052ea;
  border-color: #eaf1ff;
  background-color: #eaf1ff; }

.btn-soft-static.btn-secondary:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-secondary:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #494f54;
  border-color: #f0f2f2;
  background-color: #f0f2f2; }

.btn-soft-static.btn-success:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-success:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #4a7e20;
  border-color: #def3cf;
  background-color: #def3cf; }

.btn-soft-static.btn-info:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-info:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #009bb0;
  border-color: #ddfbff;
  background-color: #ddfbff; }

.btn-soft-static.btn-warning:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-warning:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #f7c12c;
  border-color: #fef4d8;
  background-color: #fef4d8; }

.btn-soft-static.btn-danger:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-danger:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #a71d2a;
  border-color: #fbe7e9;
  background-color: #fbe7e9; }

.btn-soft-static.btn-light:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-light:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #cbd3da;
  border-color: white;
  background-color: white; }

.btn-soft-static.btn-dark:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-dark:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: black;
  border-color: #d6d6d6;
  background-color: #d6d6d6; }

.btn-soft-static.btn-indigo:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-indigo:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #311bb6;
  border-color: #efedfc;
  background-color: #efedfc; }

.btn-soft-static.btn-purple:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-purple:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #2d1a57;
  border-color: #b6a2e3;
  background-color: #b6a2e3; }

.btn-soft-static.btn-pink:not([aria-expanded="true"]):not(.active),
.btn-soft.btn-pink:not(:hover):not([aria-expanded="true"]):not(.active) {
  color: #c21766;
  border-color: #f7bed8;
  background-color: #f7bed8; }

/*

	Shadowed buttons
		* cancel hover shadow by adding .btn-noshadow -or- .shadow-none

*/
.btn-primary:not(.btn-noshadow):hover,
.btn-primary:not(.btn-noshadow):not(.btn-soft):focus,
.btn-primary:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35); }

.btn-secondary:not(.btn-noshadow):hover,
.btn-secondary:not(.btn-noshadow):not(.btn-soft):focus,
.btn-secondary:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(108, 117, 125, 0.35); }

.btn-success:not(.btn-noshadow):hover,
.btn-success:not(.btn-noshadow):not(.btn-soft):focus,
.btn-success:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(109, 187, 48, 0.35); }

.btn-info:not(.btn-noshadow):hover,
.btn-info:not(.btn-noshadow):not(.btn-soft):focus,
.btn-info:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(0, 223, 252, 0.35); }

.btn-warning:not(.btn-noshadow):hover,
.btn-warning:not(.btn-noshadow):not(.btn-soft):focus,
.btn-warning:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(250, 215, 118, 0.35); }

.btn-danger:not(.btn-noshadow):hover,
.btn-danger:not(.btn-noshadow):not(.btn-soft):focus,
.btn-danger:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(220, 53, 69, 0.35); }

.btn-light:not(.btn-noshadow):hover,
.btn-light:not(.btn-noshadow):not(.btn-soft):focus,
.btn-light:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35); }

.btn-dark:not(.btn-noshadow):hover,
.btn-dark:not(.btn-noshadow):not(.btn-soft):focus,
.btn-dark:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.35); }

.btn-indigo:not(.btn-noshadow):hover,
.btn-indigo:not(.btn-noshadow):not(.btn-soft):focus,
.btn-indigo:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(83, 59, 226, 0.35); }

.btn-purple:not(.btn-noshadow):hover,
.btn-purple:not(.btn-noshadow):not(.btn-soft):focus,
.btn-purple:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(76, 44, 146, 0.35); }

.btn-pink:not(.btn-noshadow):hover,
.btn-pink:not(.btn-noshadow):not(.btn-soft):focus,
.btn-pink:not(.btn-noshadow):not(.btn-soft):active {
  box-shadow: 0 4px 11px rgba(232, 62, 140, 0.35); }

/*

	Vendor buttons
		* cancel hover shadow by adding .btn-noshadow

*/
.btn-facebook,
.btn-facebook:hover {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998; }

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
  box-shadow: 0 4px 11px rgba(59, 89, 152, 0.35); }

.btn-twitter,
.btn-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2; }

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
  box-shadow: 0 4px 11px rgba(29, 161, 242, 0.35); }

.btn-gplus,
.btn-gplus:hover {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39; }

.btn-gplus:hover,
.btn-gplus:focus,
.btn-gplus:active {
  box-shadow: 0 4px 11px rgba(221, 75, 57, 0.35); }

.btn-instagram,
.btn-instagram:hover {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b; }

.btn-instagram:hover,
.btn-instagram:focus,
.btn-instagram:active {
  box-shadow: 0 4px 11px rgba(63, 114, 155, 0.35); }

.btn-linkedin,
.btn-linkedin:hover {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6; }

.btn-linkedin:hover,
.btn-linkedin:focus,
.btn-linkedin:active {
  box-shadow: 0 4px 11px rgba(0, 123, 182, 0.35); }

.btn-vimeo,
.btn-vimeo:hover {
  color: #fff;
  background-color: #aad450;
  border-color: #aad450; }

.btn-vimeo:hover,
.btn-vimeo:focus,
.btn-vimeo:active {
  box-shadow: 0 4px 11px rgba(170, 212, 80, 0.35); }

.btn-youtube,
.btn-youtube:hover {
  color: #fff;
  background-color: #bb0000;
  border-color: #bb0000; }

.btn-youtube:hover,
.btn-youtube:focus,
.btn-youtube:active {
  box-shadow: 0 4px 11px rgba(187, 0, 0, 0.35); }

.btn-github,
.btn-github:hover {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e; }

.btn-github:hover,
.btn-github:focus,
.btn-github:active {
  box-shadow: 0 4px 11px rgba(36, 41, 46, 0.35); }

.btn-snapchat,
.btn-snapchat:hover {
  color: #fff;
  background-color: #FFFC00;
  border-color: #FFFC00; }

.btn-snapchat:hover,
.btn-snapchat:focus,
.btn-snapchat:active {
  box-shadow: 0 4px 11px rgba(255, 252, 0, 0.35); }

.btn-email,
.btn-email:hover {
  color: #fff;
  background-color: #049fb3;
  border-color: #049fb3; }

.btn-email:hover,
.btn-email:focus,
.btn-email:active {
  box-shadow: 0 4px 11px rgba(4, 159, 179, 0.35); }

/*

	Pill buttons
	Also, we have .rounded-bill in bootstrap

*/
.btn-pill {
  border-radius: 50rem; }

/*

	Icon buttons

*/
/* no rounded - fixed paddings */
.btn.btn-icon {
  padding-left: .75rem;
  padding-right: .75rem; }

/* rounded */
.btn.rounded-circle {
  position: relative;
  line-height: 1.6;
  padding: 1.53rem 1.53rem;
  font-size: 1.0625rem;
  text-align: center; }

.btn.btn-sm.rounded-circle, .btn-group-sm > .btn.rounded-circle {
  padding: 1.22rem 1.22rem;
  font-size: 1.0625rem;
  width: 1.94rem;
  height: 1.94rem; }

.btn.btn-lg.rounded-circle, .btn-group-lg > .btn.rounded-circle {
  padding: 1.83rem 1.83rem;
  font-size: 1.0625rem;
  width: 3.125rem;
  height: 3.125rem; }

.btn.rounded-circle > span:not(.badge),
.btn.rounded-circle > i {
  font-size: 1rem !important;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

@media only screen and (max-width: 991px) {
  /*
	Example usage case

	<a href="#" class="btn btn-sm rounded-circle-xs btn-primary">
		<i class="fi fi-menu-dots"></i>
		<span>Projects</span>
	</a>

 */
  .btn.rounded-circle-xs {
    position: relative;
    line-height: 1.6;
    padding: 1.53rem 1.53rem;
    font-size: 1.0625rem;
    text-align: center; }
  .btn.btn-sm.rounded-circle-xs, .btn-group-sm > .btn.rounded-circle-xs {
    padding: 1.22rem 1.22rem;
    font-size: 1.0625rem;
    width: 1.94rem;
    height: 1.94rem; }
  .btn.btn-lg.rounded-circle-xs, .btn-group-lg > .btn.rounded-circle-xs {
    padding: 1.83rem 1.83rem;
    font-size: 1.0625rem;
    width: 3.125rem;
    height: 3.125rem; }
  .btn.rounded-circle-xs > span,
  .btn.rounded-circle-xs > i {
    font-size: 1rem !important;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    padding: 0 !important;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  /* hide button text, keep the icon only (or group icon) */
  .btn.rounded-circle-xs > .group-icon {
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .btn.rounded-circle-xs > span + span,
  .btn.rounded-circle-xs > i + span {
    display: none; } }

/*

	Toggle Button

*/
.group-icon > span,
.group-icon > i {
  font-style: normal; }

/* by javascript */
.group-icon > span:last-child,
.group-icon > i:last-child {
  display: none; }

.group-icon > span:first-child,
.group-icon > i:first-child {
  display: inline-block; }

/* active */
.active > a > .group-icon > span:last-child,
.active > a > .group-icon > i:last-child,
.active > .group-icon > span:last-child,
.active > .group-icon > i:last-child {
  display: inline-block; }

.active > a > .group-icon > span:first-child,
.active > a > .group-icon > i:first-child,
.active > .group-icon > span:first-child,
.active > .group-icon > i:first-child {
  display: none; }

/* by bootstrap (dropdowns, collapse, etc) */
[aria-expanded="true"] .group-icon > span:first-child,
[aria-expanded="true"] .group-icon > i:first-child {
  display: none; }

[aria-expanded="true"] .group-icon > span:last-child,
[aria-expanded="true"] .group-icon > i:last-child {
  display: inline-block; }

/*

	Burger menu button

*/
.burger-menu {
  display: block;
  border-top: #343a40 2px solid;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1); }

.burger-menu:before {
  content: "";
  display: block;
  position: absolute;
  width: 75%;
  top: 50%;
  margin-top: -2px;
  height: 1px;
  border-top: #343a40 2px solid;
  -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1); }

.burger-menu:after {
  content: "";
  display: block;
  position: absolute;
  width: 25%;
  bottom: 0;
  height: 1px;
  border-bottom: #343a40 2px solid;
  -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1); }

.start-0 .burger-menu:before,
.start-0 .burger-menu:after {
  left: 0; }

.end-0 .burger-menu:before,
.end-0 .burger-menu:after {
  right: 0; }

a.btn-burger-menu:not(.bg-white) .burger-menu,
a.btn-burger-menu:not(.bg-white) .burger-menu:before,
a.btn-burger-menu:not(.bg-white) .burger-menu:after {
  border-color: #fff; }

a.btn-burger-menu:hover .burger-menu:before,
a.btn-burger-menu:hover .burger-menu:after,
a.btn-burger-menu[aria-expanded=true] .burger-menu:before,
a.btn-burger-menu[aria-expanded=true] .burger-menu:after,
a.btn-burger-menu.active:hover .burger-menu:before,
a.btn-burger-menu.active:hover .burger-menu:after {
  width: 100%; }

.breadcrumb-sublime a {
  color: #b2b6c3;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.breadcrumb-sublime a:hover {
  color: inherit; }

.breadcrumb-sublime .breadcrumb-item.active {
  color: #b2b6c3; }

@media (max-width: 575.98px) {
  .card-columns {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3; } }

@media (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4; } }

/*

  Soft badges
    .badge-soft

*/
.badge-soft.badge-primary {
  color: #0052ea;
  background-color: #eaf1ff; }

.bg-primary-soft .badge-soft.badge-primary {
  background-color: #d0e0ff; }

.badge-soft.badge-secondary {
  color: #494f54;
  background-color: #f0f2f2; }

.bg-secondary-soft .badge-soft.badge-secondary {
  background-color: #e3e5e7; }

.badge-soft.badge-success {
  color: #4a7e20;
  background-color: #def3cf; }

.bg-success-soft .badge-soft.badge-success {
  background-color: #d1edba; }

.badge-soft.badge-info {
  color: #009bb0;
  background-color: #ddfbff; }

.bg-info-soft .badge-soft.badge-info {
  background-color: #c4f8ff; }

.badge-soft.badge-warning {
  color: #f7c12c;
  background-color: #fef4d8; }

.bg-warning-soft .badge-soft.badge-warning {
  background-color: #fdedc0; }

.badge-soft.badge-danger {
  color: #a71d2a;
  background-color: #fbe7e9; }

.bg-danger-soft .badge-soft.badge-danger {
  background-color: #f7d1d5; }

.badge-soft.badge-light {
  color: #cbd3da;
  background-color: white; }

.bg-light-soft .badge-soft.badge-light {
  background-color: white; }

.badge-soft.badge-dark {
  color: black;
  background-color: #d6d6d6; }

.bg-dark-soft .badge-soft.badge-dark {
  background-color: #c9c9c9; }

.badge-soft.badge-indigo {
  color: #311bb6;
  background-color: #efedfc; }

.bg-indigo-soft .badge-soft.badge-indigo {
  background-color: #dbd6f9; }

.badge-soft.badge-purple {
  color: #2d1a57;
  background-color: #b6a2e3; }

.bg-purple-soft .badge-soft.badge-purple {
  background-color: #a78edd; }

.badge-soft.badge-pink {
  color: #c21766;
  background-color: #f7bed8; }

.bg-pink-soft .badge-soft.badge-pink {
  background-color: #f4a7ca; }

.badge-ico,
.badge-ico-sm,
.badge-ico-md {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center; }

.badge-ico-sm {
  width: 25px;
  height: 25px; }

.badge-ico,
.badge-ico-md {
  width: 35px;
  height: 35px;
  font-size: 16px; }

/* icon fix */
.badge-ico-sm > i,
.badge-ico-md > i {
  display: inline-block; }

.badge-ico-sm > i:before {
  padding-top: 0; }

/*

	DROPDOWN : GENERAL SETUP

	--

	Bootstrap is "missing" nested|multilevel dropdowns and opening them on mouse over!
	So, instead of developing new plugins reinventing the weel (more code, more issues),
	we use dropdowns for almost everything!

	++++++++++++++++++++++
	NOTE: many rules are splitted on desktop/mobile for specific functions/fixes
	So also check the bottom @media before making changes!
	++++++++++++++++++++++

*/
/* animation on hover, also on mobile (collapsing) */
.dropdown-menu {
  -webkit-animation: fadeIn 0.6s;
          animation: fadeIn 0.6s;
  font-size: 16px;
  text-align: left; }

/* box shadow */
/*.dropdown-menu:not(.shadow-none) {*/
.dropdown-menu {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175); }

/* force disabled link */
.dropdown-link.disabled {
  color: #6c757d !important;
  pointer-events: none; }

/*
	default dropdown link
	hovers : see @media on bottom
*/
.dropdown-link {
  color: #212529; }

.active > .dropdown-link,
.dropdown-link.active {
  color: #377dff;
  background-color: #f8f9fa; }

/* dropdown header */
.dropdown-header {
  font-size: 1.0625rem; }

/* dropdown footer */
.dropdown-footer {
  padding: 10px 15px;
  position: relative;
  display: block; }

a.dropdown-footer,
.dropdown-footer {
  text-decoration: none; }

a.dropdown-footer:not(:hover),
.dropdown-footer > a:not(:hover) {
  color: #212529; }

/* dropdown item */
.dropdown-menu .dropdown-item {
  padding: 0;
  margin: 0; }

/* keep icons in place */
a.dropdown-item {
  position: relative; }

/*
		highlighted/active color
		hovers : see @media
	*/
.dropdown-menu .dropdown-item.show > a {
  color: #377dff; }

.dropdown-menu li:not(.dropdown-item) > a,
.dropdown-menu a.dropdown-item,
.dropdown-menu .dropdown-item > a {
  display: block;
  position: relative;
  padding: 8px 40px 8px 25px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.dropdown-menu a:not([data-toggle="dropdown"]) {
  padding-right: 15px; }

.dropdown-item .row ul:not(.prefix-link-icon) .dropdown-link {
  padding-left: 8px;
  padding-right: 8px; }

/* icons positioning for a nice default look */
.dropdown-menu a.dropdown-item > i,
.dropdown-menu li:not(.dropdown-item) > a > i,
.dropdown-menu .dropdown-item > a > i {
  margin-right: 15px; }

/*

	DROPDOWN : UPPERCASE FORCED
	also font scaled down to avoid too large looking

*/
.dropdown-menu.dropdown-menu-uppercase a {
  text-transform: uppercase;
  font-size: 13px; }

/*

	DROPDOWN : CLEAN
	no background color on hover

*/
.dropdown-menu-clean li.active > a:not(.btn),
.dropdown-menu-clean li.active,
.dropdown-menu-clean a:not(.btn).active,
.dropdown-menu-clean li:focus,
.dropdown-menu-clean li:hover,
.dropdown-menu-clean a:not(.btn):focus,
.dropdown-menu-clean a:not(.btn):hover {
  background-color: transparent !important;
  text-decoration: none; }

.dropdown-menu-clean:not(.prefix-link-icon) a:not(.btn):hover {
  color: #377dff; }

/*

	DROPDOWN : INVERT
	open in oposite direction

*/
.dropdown-menu.dropdown-menu-invert {
  left: auto;
  right: 0; }

/*

	DROPDOWN : NESTED POSITIONING
	again, bootstrap was not developed for this

*/
.dropdown-menu li > .dropdown-menu {
  top: 0 !important;
  left: 100% !important;
  right: auto !important; }

/* deep level icon indicator */
.dropdown-menu:not(.dropdown-menu-invert) li > a[data-toggle="dropdown"]:after {
  font-family: "Flaticon";
  opacity: 0.2;
  opacity: all .3s ease;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  top: 50%;
  margin-top: -6px;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  content: "\f1ae";
  right: 15px; }

.dropdown-menu:not(.dropdown-menu-invert) .dropdown-item.show > a:after,
.dropdown-menu:not(.dropdown-menu-invert) .dropdown-item:hover > a:after {
  opacity: 1; }

/* turn down icon */
@media only screen and (max-width: 991px) {
  /*
				deep links indicator
				turn icon down on active
			*/
  .dropdown-menu:not(.dropdown-menu-invert) .dropdown-item.show > a:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

/*
	DROPDOWN : MEGA
	Dropdown links inside mega menu

	Links are formatted for dropdown.
	We make them fit inside mega menu

	NOTE: Use <ul> instead of <div> for mega dropdowns
	REASON: avoiding grid collisions
*/
.dropdown-mega .row ul:not(.dropdown-menu) {
  padding: 0;
  margin-left: 0px;
  margin-right: 0; }

.dropdown-mega .row ul:not(.dropdown-menu) .dropdown-divider {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 0px; }

/*

	PREFIX ICON|INDICATOR

		Add to .dropdown-menu following classes:
			.prefix-link-icon .prefix-icon-dot 		- link prefix
			.prefix-link-icon .prefix-icon-line 	- link prefix
			.prefix-link-icon .prefix-icon-ico 		- link prefix
			.prefix-link-icon .prefix-icon-arrow 	- link prefix

			.prefix-icon-ignore 					- ignore, do not use on a specific link

*/
/* dropdown header */
.prefix-link-icon .dropdown-header {
  padding: 10px 15px; }

a.prefix-link-icon:not(.prefix-icon-ignore),
.prefix-link-icon a:not(.prefix-icon-ignore) {
  padding-left: 40px !important;
  padding-right: 25px !important; }

/* setup */
a.prefix-link-icon:not(.prefix-icon-ignore):before,
.prefix-link-icon a:not(.prefix-icon-ignore):before {
  content: '';
  color: #e9ecef;
  background-color: #e9ecef;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  position: absolute;
  top: 50%;
  left: 20px; }

/* dot */
a.prefix-icon-dot:not(.prefix-icon-ignore):before,
.prefix-icon-dot a:not(.prefix-icon-ignore):before {
  border-radius: 100%;
  width: 4px;
  height: 4px;
  margin-top: -2px;
  left: 22px; }

/* line */
a.prefix-icon-line:not(.prefix-icon-ignore):before,
.prefix-icon-line a:not(.prefix-icon-ignore):before {
  width: 10px;
  height: 1px;
  margin-top: -1px;
  left: 19px; }

/* square */
a.prefix-icon-square:not(.prefix-icon-ignore):before,
.prefix-icon-square a:not(.prefix-icon-ignore):before {
  width: 4px;
  height: 4px;
  margin-top: -3px;
  left: 21px; }

/* arrow */
a.prefix-icon-arrow:not(.prefix-icon-ignore):before,
.prefix-icon-arrow a:not(.prefix-icon-ignore):before {
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-top: -6px;
  line-height: 1;
  font-family: "Flaticon";
  background-color: transparent;
  content: "\f1ae";
  left: 19px; }

/* hover color */
.prefix-link-icon:not(.prefix-icon-arrow) .dropdown-item.show:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow) .dropdown-item.show > a:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow) .dropdown-item.active:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow) .dropdown-item.active > a:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow) .dropdown-item:hover:not(.prefix-icon-ignore):before,
.prefix-link-icon:not(.prefix-icon-arrow) .dropdown-item:hover > a:not(.prefix-icon-ignore):before {
  background-color: #377dff !important; }

.prefix-link-icon.prefix-icon-arrow .dropdown-item.show:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow .dropdown-item.show > a:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow .dropdown-item.active:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow .dropdown-item.active > a:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow .dropdown-item:hover:not(.prefix-icon-ignore):before,
.prefix-link-icon.prefix-icon-arrow .dropdown-item:hover > a:not(.prefix-icon-ignore):before {
  color: #377dff !important; }

/* desktop only */
@media only screen and (min-width: 991px) {
  /* keep link highlighted on hover */
  .dropdown-menu .dropdown-item:hover > a {
    color: #377dff;
    text-decoration: none; }
  .dropdown-link:hover {
    color: #377dff;
    background-color: #f8f9fa; }
  /* show on mouse over */
  [data-toggle="dropdown"] + .dropdown-menu-hover {
    margin-top: 0 !important;
    /* the margin will cause the dropdown close on mouse over the "space" area */ }
  [data-toggle="dropdown"]:hover + .dropdown-menu-hover,
  [data-toggle="dropdown"]:hover ~ .dropdown-menu-hover,
  .dropdown-menu-hover:hover,
  .dropdown-menu-hover + .dropdown-menu:hover,
  .dropdown-menu-hover:hover + .dropdown-menu {
    display: block; }
  /*
		Dropdown mega menu / full width
	*/
  .dropdown-mega > .dropdown-menu {
    /* first level only */
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    padding: 25px; }
  /*

		DROPDOWN : DARK
	*/
  .dropdown-menu-dark,
  .dropdown-menu-dark .dropdown-menu {
    color: #fff;
    background-color: #333333; }
  /* links */
  .dropdown-menu-dark a {
    color: #cccccc !important; }
  .dropdown-menu-dark a.dropdown-item:hover,
  .dropdown-menu-dark a.dropdown-item.active,
  .dropdown-menu-dark .dropdown-item:hover > a,
  .dropdown-menu-dark .dropdown-item.active > a,
  .dropdown-menu-dark .dropdown-item > a.active,
  .dropdown-menu-dark a:hover {
    color: #fff !important; }
  /* grid */
  .dropdown-menu-dark .dropdown-divider,
  .dropdown-menu-dark .row.col-border > div,
  .dropdown-menu-dark .row.col-border-sm > div,
  .dropdown-menu-dark .row.col-border-md > div,
  .dropdown-menu-dark .row.col-border-lg > div {
    border-color: #4d4d4d; }
  /* multilevel dropdown inside content : multilevel click issue : [hack|overwriting popper.js] */
  .dropdown-menu-block-md .dropdown-item > .dropdown-menu {
    -webkit-transform: none !important;
            transform: none !important; } }

/* mobile only */
@media only screen and (max-width: 991px) {
  /*
		default dropdown link
		do not keep it active on second click
	*/
  a.dropdown-link:hover {
    color: inherit; }
  /*
		Dropdown mega menu / full width
	*/
  /* links inside mega menu */
  .dropdown-mega .row {
    margin-left: 0; }
  .dropdown-mega .row > div:not(:last-child) {
    margin-bottom: 30px; }
  /* multilevel dropdown inside content */
  .dropdown-menu-block-md .dropdown-item > .dropdown-menu {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    /* parent fit */
    background-color: rgba(0, 0, 0, 0.02) !important;
    box-shadow: none !important;
    border-radius: 0; }
  /* fix position of dropdown on navbar */
  .dropdown-menu-navbar-autopos {
    position: fixed;
    bottom: auto;
    top: auto;
    margin-left: 8px;
    margin-right: 8px; } }

table.table-align-middle td,
table.table-align-middle th {
  vertical-align: middle; }

/* checkbox */
table label.form-checkbox,
table label.form-radio {
  width: 20px !important;
  height: 20px !important;
  margin: 0;
  padding: 0; }

table label.form-checkbox > i,
table label.form-radio > i {
  margin-right: 0;
  margin-left: 0; }

table thead th label.form-checkbox,
table thead th label.form-radio,
table tfoot th label.form-checkbox,
table tfoot th label.form-radio {
  margin-top: 3px; }

/* setup */
.table:not(.table-framed):not(.table-bordered) thead > tr > th {
  border-top-width: 0; }

.table thead > tr > th {
  border-bottom-width: 1px; }

.table-framed tfoot th {
  border-bottom: #e9ecef 1px solid; }

/* large */
.table-lg tr > th,
.table-lg tr > td {
  padding: 1.5rem 2rem; }

/* stripped table */
table.table-striped th,
table.table-striped td {
  border: 0; }

/*

	Tabs : bordered;

*/
.nav-tabs-border-top,
.nav-tabs-border-bottom {
  border: 0; }

.nav-tabs-border-top .nav-item:not(:last-child),
.nav-tabs-border-bottom .nav-item:not(:last-child) {
  margin-right: 15px; }

.nav-tabs-border-top .nav-link,
.nav-tabs-border-bottom .nav-link {
  border: 0;
  border-radius: 0;
  padding-left: 3px;
  padding-right: 3px; }

.nav-tabs-border-top .nav-link.active {
  border-top: #377dff 1px solid !important; }

.nav-tabs-border-bottom .nav-link.active {
  border-bottom: #377dff 1px solid !important; }

.nav-pills .nav-link.btn:not(.active) {
  background: #fff !important;
  color: #6c757d !important; }

/**

	SIDEBAR

	Dropped .aside-left & .aside-right
		.aside-start = left  on LTR
		.aside-start = right on RTL

		.aside-end = right  on LTR
		.aside-end = left on RTL



	Header : match sidebar background color
		#header.header-match-aside-primary
		#header.header-match-aside-secondary

**/
aside {
  font-size: 16px;
  position: relative;
  flex-shrink: 0;
  /**  DO NOT SET z-index HERE : MOBILE ONLY * ASIDE STICKY **/ }

aside.aside-start,
aside.aside-end {
  -webkit-transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  height: 100%; }

.aside-width,
body:not(.aside-compact) aside.aside-start,
body:not(.aside-compact) aside.aside-end {
  width: 260px; }

body.aside-compact aside.aside-start,
body.aside-compact aside.aside-end {
  width: 140px; }

aside.aside-start {
  left: 0;
  right: auto; }

aside.aside-end {
  left: auto;
  right: 0; }

/* sidebar (also used by admin) */
.aside-secondary {
  color: #121212;
  background-color: #ffffff; }

.aside-secondary .text-aside {
  color: #377dff; }

.aside-primary {
  color: #dddddd;
  background: #1e1e2d;
  background: -webkit-gradient(linear, left top, left bottom, from(#2e2e45), to(#1e1e2d)) !important;
  background: linear-gradient(to bottom, #2e2e45 0%, #1e1e2d 100%) !important; }

.aside-primary .text-aside {
  color: #ffffff; }

.bg-aside {
  background-color: #1e1e2d; }

/*

	Nav Item
	Paddings
	orig: .5rem 1rem

*/
aside .nav-deep > ul > li.nav-title,
aside .nav-deep > ul > li > a.nav-link {
  padding: .8rem 1rem; }

aside .nav-deep.nav-deep-lg > ul > li.nav-title,
aside .nav-deep.nav-deep-lg > ul > li > a.nav-link {
  padding-top: .8rem;
  padding-bottom: .8rem; }

aside .nav-deep.nav-deep-lg > ul > li li.nav-title,
aside .nav-deep.nav-deep-lg > ul > li ul > li > a {
  padding-top: .5rem;
  padding-bottom: .5rem; }

aside .nav-deep.nav-deep-sm > ul > li.nav-title,
aside .nav-deep.nav-deep-sm > ul > li > a.nav-link {
  padding-top: .6rem;
  padding-bottom: .6rem; }

aside .nav-deep.nav-deep-sm > ul > li li.nav-title,
aside .nav-deep.nav-deep-sm > ul > li ul > li > a {
  padding-top: .4rem;
  padding-bottom: .4rem; }

aside .nav-deep.nav-deep-xs > ul > li.nav-title,
aside .nav-deep.nav-deep-xs > ul > li > a.nav-link {
  padding-top: .3rem;
  padding-bottom: .3rem; }

aside .nav-deep.nav-deep-xs > ul > li li.nav-title,
aside .nav-deep.nav-deep-xs > ul > li ul > li > a {
  padding-top: .3rem;
  padding-bottom: .3rem; }

/*
	hover & active background color
	overwrite _navigations.scss
*/
.aside-secondary nav.nav-deep-hover > ul > li.nav-item:hover,
.aside-secondary nav.nav-deep-hover > ul > li.nav-item.active {
  background-color: #f5f6fc !important; }

.aside-primary nav.nav-deep-hover > ul > li.nav-item:hover,
.aside-primary nav.nav-deep-hover > ul > li.nav-item.active {
  background-color: #31314a !important; }

/*
	disabled links
*/
.aside-secondary a.disabled {
  color: #929292 !important; }

.aside-primary a.disabled {
  color: #919191 !important; }

/*
	spacings
*/
/*

	EXTENDED NAV
	Like a secondary sidebar

*/
aside .nav-item.nav-item-extended > div.aside-item {
  position: fixed;
  top: 95px;
  bottom: 15px;
  font-size: 1.0625rem;
  overflow: hidden;
  color: #121212;
  min-width: 260px;
  max-width: calc(100vw - 15px - 260px);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  left: 260px;
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

/* hide : is inactive */
aside .nav-item.nav-item-extended:not(.active) > div.aside-item {
  display: none !important; }

/* overlay */
aside .nav-item.nav-item-extended:not(.active).overlay-light:after,
aside .nav-item.nav-item-extended:not(.active).overlay-dark:after {
  display: none !important; }

aside .nav-item.nav-item-extended.active.overlay-light:after,
aside .nav-item.nav-item-extended.active.overlay-dark:after {
  position: fixed;
  z-index: -1;
  left: 260px;
  right: 0; }

/* fade in animation */
aside .nav-item.nav-item-extended.active > .aside-item {
  -webkit-animation: __utilFadeIn .25s ease-out forwards;
          animation: __utilFadeIn .25s ease-out forwards; }

/*

	Header : match sidebar
	background color

*/
#header.header-match-aside-primary {
  background: #1e1e2d;
  background: -webkit-gradient(linear, left top, left bottom, from(#2e2e45), to(#1e1e2d)) !important;
  background: linear-gradient(to bottom, #2e2e45 0%, #1e1e2d 100%) !important; }

#header.header-match-aside-secondary {
  background-color: #ffffff; }

/**

	Responsive

**/
@media only screen and (min-width: 992px) {
  /*
		ASIDE : FOCUS
		Push header
	*/
  body.aside-sticky aside {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 11; }
  body:not(.aside-compact).layout-admin #footer {
    padding-left: 260px; }
  body.aside-sticky:not(.aside-compact) #wrapper_content,
  body.aside-sticky:not(.aside-compact) #header,
  body.aside-sticky:not(.aside-compact) #js_header_spacer {
    margin-left: 260px; }
  body.layout-admin.aside-compact #footer {
    padding-left: 140px; }
  body.aside-sticky.aside-compact #wrapper_content,
  body.aside-sticky.aside-compact #header,
  body.aside-sticky.aside-compact #js_header_spacer {
    margin-left: 140px; }
  /**

		FRONTEND DEDICATED

	**/
  body.aside-start:not(.layout-boxed) #wrapper {
    margin-left: 260px; }
  body.aside-end:not(.layout-boxed) #wrapper {
    margin-right: 260px; }
  body.aside-start.header-fixed:not(.layout-boxed) #header,
  body.aside-end.header-fixed:not(.layout-boxed) #header {
    max-width: 100% !important;
    width: auto !important; }
  /**

		ASIDE FLOAT

	**/
  body.aside-float aside {
    z-index: 11;
    position: fixed;
    top: 0;
    bottom: 0; }
  body.aside-start.aside-sticky:not(.header-sticky) #header,
  body.aside-float #header {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  body.aside-float:not(.aside-show) .aside-start:not(.js-aside-show) {
    margin-left: -100%; }
  body.aside-float:not(.aside-show) .aside-end:not(.js-aside-show) {
    margin-right: -100%; } }

@media only screen and (max-width: 991px) {
  aside {
    z-index: 11;
    position: fixed !important;
    top: 0;
    bottom: 0; }
  /*

		HIDE|SHOW

	*/
  aside:not(.js-aside-show).aside-start {
    margin-left: -260px; }
  aside:not(.js-aside-show).aside-end {
    margin-right: -260px; }
  /* extended */
  aside:not(.js-aside-show).aside-start .nav-item-extended > div.aside-item {
    left: -100%; }
  aside:not(.js-aside-show).aside-end .nav-item-extended > div.aside-item {
    right: -100%; }
  /* extended - position forced */
  aside .nav-item.nav-item-extended > div.aside-item {
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    width: auto !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 0.25rem;
    z-index: 9999; } }

/**

	Fullscreen Element

**/
.fullscreen {
  position: fixed !important;
  left: 15px !important;
  right: 15px !important;
  top: 15px !important;
  bottom: 15px !important;
  margin: 0 !important;
  background-color: #fff !important;
  z-index: 1039 !important;
  /* scrollable */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: auto;
  max-height: 100%;
  -ms-box-shadow: 0px 0px 35px 19px rgba(0, 0, 0, 0.75) !important;
  -o-box-shadow: 0px 0px 35px 19px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 0px 35px 19px rgba(0, 0, 0, 0.75) !important; }

/* show element on fullscreen only */
.fullscreen .fullscreen-show {
  display: block !important; }

/* hidden element on fullscreen only */
.fullscreen .fullscreen-hide {
  display: hidden !important; }

/**

	Modals

**/
.modal-full {
  max-width: 100%;
  margin: 15px; }

/**

	PAGINATION : PILL

**/
.pagination-pill > .page-item:first-child > .page-link {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem; }

.pagination-pill > .page-item:last-child > .page-link {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem; }

/*

	Thumbnail : Shadow drop

*/
.thumbnail-shadow-drop {
  position: relative; }

.thumbnail-shadow-drop:after {
  position: absolute;
  width: 56%;
  height: 26%;
  left: 20%;
  border-radius: 50%;
  z-index: 1;
  bottom: 128px;
  margin-bottom: 13%;
  content: "";
  box-shadow: 0 210px 60px rgba(0, 0, 0, 0.2);
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  opacity: 0;
  -webkit-animation: __utilFadeInSlow 2s ease;
  animation: __utilFadeInSlow 2s ease;
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 4s;
          animation-duration: 4s; }

.thumbnail-shadow-drop:before {
  position: absolute;
  width: 41%;
  height: 25%;
  left: 23%;
  border-radius: 50%;
  z-index: 0;
  bottom: 25%;
  margin-bottom: 0%;
  content: "";
  box-shadow: 0 194px 50px rgba(0, 0, 0, 0.3);
  -webkit-transform: rotate(-17deg);
          transform: rotate(-17deg);
  opacity: 0;
  -webkit-animation: __utilFadeInSlow 2s ease;
  animation: __utilFadeInSlow 2s ease;
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 4s;
          animation-duration: 4s; }

@media only screen and (max-width: 768px) {
  .thumbnail-shadow-drop:before {
    bottom: 45%;
    left: 18%; } }

/*

	Timeline

*/
.timeline-container > div,
.timeline-container {
  position: relative; }

.timeline-container:before {
  content: '';
  position: absolute;
  width: 4px;
  top: 7px;
  bottom: 7px;
  height: calc(100% - (4px + 3));
  background-color: #e9ecef;
  left: 100px; }

.timeline-container i.badge {
  width: 17px;
  height: 17px;
  border: transparent 4px solid;
  display: inline-block;
  position: absolute;
  padding: 0;
  top: 7px;
  left: 93.5px; }

.timeline-container i.badge:after {
  content: '';
  width: 25px;
  height: 25px;
  border: #fff 4px solid;
  position: absolute;
  z-index: 0;
  border-radius: 50rem;
  top: -8px;
  left: -8px; }

.timeline-container .timeline-content,
.timeline-container .timeline-title {
  position: relative;
  display: block;
  padding-left: 24px;
  margin-left: 100px;
  border-left: transparent 4px solid; }

/* invert */
.timeline-container.timeline-invert {
  text-align: right; }

.timeline-container.timeline-invert:before {
  left: auto;
  right: 100px; }

.timeline-container.timeline-invert i.badge {
  left: auto;
  right: 93.5px; }

.timeline-container.timeline-invert i.badge:after {
  left: auto;
  right: -8px; }

.timeline-container.timeline-invert .timeline-content,
.timeline-container.timeline-invert .timeline-title {
  padding-left: 0;
  padding-right: 24px;
  margin-left: 0;
  margin-right: 100px;
  border-left: 0;
  border-right: transparent 4px solid; }

/**

    Shape : V

**/
.shape-v {
  position: absolute;
  height: 0px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1; }

.shape-v + section,
.shape-v + div {
  z-index: 9; }

.shape-v:after,
.shape-v:before {
  position: absolute;
  content: '';
  background-color: #fff;
  bottom: 0;
  height: 100px;
  margin-bottom: -100px;
  z-index: 2; }

.shape-v.bg-light:after,
.shape-v.bg-light:before {
  background-color: #f8f9fa; }

.shape-v:before {
  left: 0;
  width: 40%;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: scaleX(2) scaleY(5) rotate(6deg);
  transform: scaleX(2) scaleY(5) rotate(6deg); }

.shape-v:after {
  right: 0;
  width: 60%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleX(2) scaleY(5) rotate(-8deg);
  transform: scaleX(2) scaleY(5) rotate(-8deg); }

/**

    Responsive

**/
@media only screen and (min-width: 992px) {
  /* Shape : V */
  .shape-v.shape-xs:before {
    -webkit-transform: scaleX(2) scaleY(5) rotate(2deg);
    transform: scaleX(2) scaleY(5) rotate(2deg); }
  .shape-v.shape-xs:after {
    -webkit-transform: scaleX(2) scaleY(5) rotate(-3deg);
    transform: scaleX(2) scaleY(5) rotate(-3deg); } }

/**

	FORMS

		1. Pill inputs/selects/textarea
		2. Link over input/textarea
		3. Switch
		4. Radio
		5. Checkbox
		6. Select
		7. Material Design Style
		8. File Upload
		9. Selectors

**/
/*

	1. Pill Form
		.form-tontrol-pill 		- used for each element or for the entire form
		.input-group-pill 		- input group
*/
.form-control-pill,
.input-group-pill .input-group-append > span,
.input-group-pill .input-group-prepend > span,
.input-group-pill input,
.form-control-pill input,
.form-control-pill select,
.input-group-pill select,
.input-group-pill textarea,
.form-control-pill textarea,
.input-group-pill .btn,
.form-control-pill .btn {
  border-radius: 50rem; }

.input-group select {
  border-radius: 0 !important; }

/*

	2. Link over input/textarea

*/
.input-group-over--absolute {
  position: absolute;
  top: 0;
  right: 0; }

.input-group-over {
  position: relative; }

.input-group-over > input,
.input-group-over > textarea {
  padding-right: 60px; }

.input-group-over > a,
.input-group-over > button,
.input-group-over > span {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0; }

/* a div, button, etc */
.el-over-input {
  position: absolute !important;
  top: 0;
  z-index: 5 !important;
  right: 0; }

/*

	3. Switch

*/
/* @toggle switch elements
-------------------------------------------------- */
.form-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  min-height: 26px;
  clear: both;
  padding-left: 73px;
  margin-right: 10px; }

.m-0.form-switch,
.m--0.form-switch {
  padding-left: 58px; }

.form-switch > i {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-style: normal;
  position: absolute;
  left: 0;
  float: left;
  height: 25px;
  width: 58px;
  color: #fff;
  font-size: 11px;
  text-align: center;
  background: #B5C1C7;
  border: 0 solid #B5C1C7;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  border-radius: 2px; }

.form-switch > i:before {
  content: attr(data-off);
  position: absolute;
  top: 3px;
  width: 32px;
  height: 19px;
  line-height: 19px;
  right: 3px; }

.form-switch > i:after {
  content: "";
  margin: 3px;
  width: 19px;
  height: 19px;
  display: block;
  background: #fff;
  border-radius: 2px; }

.form-switch > input {
  display: none !important; }

.form-switch > input:focus + i {
  color: #fff;
  border-color: #a1a6a9;
  background: #a1a6a9; }

.form-switch > input:focus + i:after {
  background: #fff; }

/* @toggle switch normal state
--------------------------------------------------------------- */
.form-switch > input:checked + i {
  border-color: #333;
  background: #333;
  color: #fff;
  padding-left: 33px; }

.form-switch.form-switch-primary > input:checked + i {
  border-color: #377dff;
  background: #377dff; }

.form-switch.form-switch-secondary > input:checked + i {
  border-color: #6c757d;
  background: #6c757d; }

.form-switch.form-switch-success > input:checked + i {
  border-color: #6dbb30;
  background: #6dbb30; }

.form-switch.form-switch-info > input:checked + i {
  border-color: #00dffc;
  background: #00dffc; }

.form-switch.form-switch-warning > input:checked + i {
  border-color: #fad776;
  background: #fad776; }

.form-switch.form-switch-danger > input:checked + i {
  border-color: #dc3545;
  background: #dc3545; }

.form-switch.form-switch-light > input:checked + i {
  border-color: #f8f9fa;
  background: #f8f9fa; }

.form-switch.form-switch-dark > input:checked + i {
  border-color: #000000;
  background: #000000; }

.form-switch.form-switch-indigo > input:checked + i {
  border-color: #533be2;
  background: #533be2; }

.form-switch.form-switch-purple > input:checked + i {
  border-color: #4c2c92;
  background: #4c2c92; }

.form-switch.form-switch-pink > input:checked + i {
  border-color: #e83e8c;
  background: #e83e8c; }

.form-switch > input:checked + i:before {
  content: attr(data-on);
  top: 3px;
  height: 19px;
  line-height: 19px;
  color: #fff;
  font-family: 'Zapf Dingbats', 'font-regular', sans-serif;
  /* mobile fix - because some characters like "â" are emoji rewritten by vedors (apple/android/etc) */
  left: 1px; }

/* @toggle switch normal state focus
--------------------------------------------------------------------------------- */
.form-switch-pill > i {
  border-radius: 13px; }

.form-switch-pill > i:before {
  width: 29px; }

.form-switch-pill > i:after {
  width: 19px;
  height: 19px;
  color: #B5C1C7;
  content: "\2022";
  font: 20px/20px Times, Serif;
  border-radius: 13px;
  line-height: 19px; }

.form-switch-pill > input:checked + i {
  padding-left: 33px; }

.form-switch-pill > input:checked + i:after {
  color: #333; }

/* disabled */
.form-switch input[disabled] + i {
  border-color: #e9ecef !important;
  background: #e9ecef !important; }

/*

	4. Radio

*/
.form-radio {
  position: relative;
  cursor: pointer;
  line-height: 1.3;
  padding-left: 30px;
  padding-right: 15px; }

.form-radio > input[type="radio"] {
  display: none; }

.form-radio > i {
  position: absolute;
  display: inline-block;
  left: 0;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.35);
  cursor: pointer;
  -webkit-transition: background .3s;
  transition: background .3s;
  border-radius: 50%; }

.form-radio-inset {
  margin: 2px 0 0 0;
  padding: 0;
  display: block;
  width: 20px;
  height: 20px;
  overflow: hidden; }

a.form-radio.active > i,
.form-radio > input[type="radio"]:checked + i,
.form-radio.form-radio-dark > input[type="radio"]:checked + i {
  border: none;
  background: #121212; }

.form-radio-bordered > i,
.form-radio-bordered.form-radio-dark > i {
  border: 2px solid #121212; }

a.form-radio.form-radio-primary.active > i,
.form-radio.form-radio-primary > input[type="radio"]:checked + i {
  background: #377dff; }

.form-radio-bordered.form-radio-primary > i {
  border: 2px solid #377dff; }

a.form-radio.form-radio-secondary.active > i,
.form-radio.form-radio-secondary > input[type="radio"]:checked + i {
  background: #6c757d; }

.form-radio-bordered.form-radio-secondary > i {
  border: 2px solid #6c757d; }

a.form-radio.form-radio-success.active > i,
.form-radio.form-radio-success > input[type="radio"]:checked + i {
  background: #6dbb30; }

.form-radio-bordered.form-radio-success > i {
  border: 2px solid #6dbb30; }

a.form-radio.form-radio-info.active > i,
.form-radio.form-radio-info > input[type="radio"]:checked + i {
  background: #00dffc; }

.form-radio-bordered.form-radio-info > i {
  border: 2px solid #00dffc; }

a.form-radio.form-radio-warning.active > i,
.form-radio.form-radio-warning > input[type="radio"]:checked + i {
  background: #fad776; }

.form-radio-bordered.form-radio-warning > i {
  border: 2px solid #fad776; }

a.form-radio.form-radio-danger.active > i,
.form-radio.form-radio-danger > input[type="radio"]:checked + i {
  background: #dc3545; }

.form-radio-bordered.form-radio-danger > i {
  border: 2px solid #dc3545; }

a.form-radio.form-radio-light.active > i,
.form-radio.form-radio-light > input[type="radio"]:checked + i {
  background: #f8f9fa; }

.form-radio-bordered.form-radio-light > i {
  border: 2px solid #f8f9fa; }

a.form-radio.form-radio-dark.active > i,
.form-radio.form-radio-dark > input[type="radio"]:checked + i {
  background: #000000; }

.form-radio-bordered.form-radio-dark > i {
  border: 2px solid #000000; }

a.form-radio.form-radio-indigo.active > i,
.form-radio.form-radio-indigo > input[type="radio"]:checked + i {
  background: #533be2; }

.form-radio-bordered.form-radio-indigo > i {
  border: 2px solid #533be2; }

a.form-radio.form-radio-purple.active > i,
.form-radio.form-radio-purple > input[type="radio"]:checked + i {
  background: #4c2c92; }

.form-radio-bordered.form-radio-purple > i {
  border: 2px solid #4c2c92; }

a.form-radio.form-radio-pink.active > i,
.form-radio.form-radio-pink > input[type="radio"]:checked + i {
  background: #e83e8c; }

.form-radio-bordered.form-radio-pink > i {
  border: 2px solid #e83e8c; }

.was-validated .form-radio > input[type=radio]:invalid ~ span,
.form-radio > input[type=radio].is-invalid ~ span {
  color: #dc3545 !important; }

a.form-radio.active > i:after,
.form-radio > input[type="radio"]:checked + i:after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  background-color: #fff;
  border-radius: 50%; }

/* disabled */
a.form-radio.disabled > i,
.form-radio input[disabled] + i {
  border-color: #e9ecef !important;
  background: #e9ecef !important; }

/*

	5. Checkbox

*/
.form-checkbox {
  position: relative;
  cursor: pointer;
  line-height: 1.3;
  padding-left: 30px;
  padding-right: 15px; }

a.form-checkbox {
  text-decoration: none;
  color: #121212; }

.form-checkbox > input[type="checkbox"] {
  display: none; }

.form-checkbox > i {
  position: absolute;
  display: inline-block;
  left: 0;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.35);
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 2px; }

.was-validated .form-checkbox > input[type=checkbox]:invalid ~ span,
.form-checkbox > input[type=checkbox].is-invalid ~ span {
  color: #dc3545 !important; }

a.form-checkbox.active > i,
.form-checkbox > input[type="checkbox"]:checked + i {
  background: #377dff;
  border: none; }

.form-checkbox-inset {
  margin: 0;
  padding: 0;
  display: block;
  width: 20px;
  height: 20px;
  overflow: hidden; }

.form-checkbox > input[type="checkbox"]:checked + i,
.form-checkbox.form-checkbox-dark > input[type="checkbox"]:checked + i {
  border: none;
  background: #121212; }

.form-checkbox-bordered > i,
.form-checkbox-bordered.form-checkbox-dark > i {
  border: 2px solid #121212; }

a.form-checkbox.form-checkbox-primary.active > i,
.form-checkbox.form-checkbox-primary > input[type="checkbox"]:checked + i {
  background: #377dff; }

.form-checkbox-bordered.form-checkbox-primary > i {
  border: 2px solid #377dff; }

a.form-checkbox.form-checkbox-secondary.active > i,
.form-checkbox.form-checkbox-secondary > input[type="checkbox"]:checked + i {
  background: #6c757d; }

.form-checkbox-bordered.form-checkbox-secondary > i {
  border: 2px solid #6c757d; }

a.form-checkbox.form-checkbox-success.active > i,
.form-checkbox.form-checkbox-success > input[type="checkbox"]:checked + i {
  background: #6dbb30; }

.form-checkbox-bordered.form-checkbox-success > i {
  border: 2px solid #6dbb30; }

a.form-checkbox.form-checkbox-info.active > i,
.form-checkbox.form-checkbox-info > input[type="checkbox"]:checked + i {
  background: #00dffc; }

.form-checkbox-bordered.form-checkbox-info > i {
  border: 2px solid #00dffc; }

a.form-checkbox.form-checkbox-warning.active > i,
.form-checkbox.form-checkbox-warning > input[type="checkbox"]:checked + i {
  background: #fad776; }

.form-checkbox-bordered.form-checkbox-warning > i {
  border: 2px solid #fad776; }

a.form-checkbox.form-checkbox-danger.active > i,
.form-checkbox.form-checkbox-danger > input[type="checkbox"]:checked + i {
  background: #dc3545; }

.form-checkbox-bordered.form-checkbox-danger > i {
  border: 2px solid #dc3545; }

a.form-checkbox.form-checkbox-light.active > i,
.form-checkbox.form-checkbox-light > input[type="checkbox"]:checked + i {
  background: #f8f9fa; }

.form-checkbox-bordered.form-checkbox-light > i {
  border: 2px solid #f8f9fa; }

a.form-checkbox.form-checkbox-dark.active > i,
.form-checkbox.form-checkbox-dark > input[type="checkbox"]:checked + i {
  background: #000000; }

.form-checkbox-bordered.form-checkbox-dark > i {
  border: 2px solid #000000; }

a.form-checkbox.form-checkbox-indigo.active > i,
.form-checkbox.form-checkbox-indigo > input[type="checkbox"]:checked + i {
  background: #533be2; }

.form-checkbox-bordered.form-checkbox-indigo > i {
  border: 2px solid #533be2; }

a.form-checkbox.form-checkbox-purple.active > i,
.form-checkbox.form-checkbox-purple > input[type="checkbox"]:checked + i {
  background: #4c2c92; }

.form-checkbox-bordered.form-checkbox-purple > i {
  border: 2px solid #4c2c92; }

a.form-checkbox.form-checkbox-pink.active > i,
.form-checkbox.form-checkbox-pink > input[type="checkbox"]:checked + i {
  background: #e83e8c; }

.form-checkbox-bordered.form-checkbox-pink > i {
  border: 2px solid #e83e8c; }

a.form-checkbox.active > i:after,
.form-checkbox > input[type="checkbox"]:checked + i:after {
  position: absolute;
  content: "";
  top: 5px;
  width: 12px;
  height: 6px;
  border: 2px solid #fff;
  border-top-style: none;
  border-right-style: none;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 4px; }

/* disabled */
a.form-checkbox.disabled > i,
.form-checkbox input[disabled] + i {
  border-color: #e9ecef !important;
  background: #e9ecef !important; }

/*

	6. Select

*/
.select-form-control,
select.form-control:not([size]):not([multiple]) {
  background: url('data:image/svg+xml;utf8,<svg width="15" viewBox="0 0 129 129" xmlns="http://www.w3.org/2000/svg"><path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/></svg>') no-repeat;
  background-color: #fff;
  -moz-appearance: none;
  background-position: center right 15px; }

select.form-control:not([size]):not([multiple]) {
  padding-right: 40px; }

/*

	7. Material Design Style

*/
.form-control-clean {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important; }

.form-label-group {
  position: relative; }

.form-label-group > .form-control + label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.88rem 1rem;
  margin-bottom: 0;
  color: #a1a8ae;
  pointer-events: none;
  cursor: text;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.form-label-group > .form-control:-ms-input-placeholder,
.form-label-group > .form-control::-ms-input-placeholder {
  color: transparent !important; }

.form-label-group > .form-control::-webkit-input-placeholder {
  color: transparent !important; }

.form-label-group > .form-control::-moz-placeholder {
  color: transparent !important; }

.form-label-group > .form-control:-ms-input-placeholder {
  color: transparent !important; }

.form-label-group > .form-control::-ms-input-placeholder {
  color: transparent !important; }

.form-label-group > .form-control::placeholder {
  color: transparent !important; }

.form-label-group > .form-control:not(:placeholder-shown) {
  padding-top: 19px;
  padding-bottom: 6px; }

.form-label-group > .form-control:not(:placeholder-shown) ~ label {
  padding-top: 3px;
  padding-bottom: 0;
  font-size: 12px; }

/* select */
.form-label-group > select.form-control:not(:placeholder-shown) {
  font-weight: 200; }

.form-label-group > select.form-control:valid:not(:placeholder-shown) {
  color: #495057;
  font-weight: inherit; }

.form-label-group > select:invalid.form-control-lg {
  font-size: 16px;
  font-weight: inherit;
  color: #a1a8ae;
  padding-top: 18px;
  height: 48px; }

.form-label-group > select:valid.form-control-lg {
  padding-top: 16px;
  font-size: 1.25rem;
  padding-bottom: 0; }

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none; }
  .form-label-group .form-control:-ms-input-placeholder,
  .form-label-group .form-control::-ms-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::-webkit-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::-moz-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control:-ms-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::-ms-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::placeholder {
    color: #a1a8ae; } }

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-label-group > label {
    display: none; }
  .form-label-group .form-control:-ms-input-placeholder,
  .form-label-group .form-control::-ms-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::-webkit-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::-moz-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control:-ms-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::-ms-input-placeholder {
    color: #a1a8ae; }
  .form-label-group .form-control::placeholder {
    color: #a1a8ae; } }

/*

	8. File Upload
	Bootstrap extended - colors & custom-file-lg

*/
.custom-file-primary .custom-file-label::after {
  color: #fff;
  background-color: #377dff; }

.custom-file-secondary .custom-file-label::after {
  color: #fff;
  background-color: #6c757d; }

.custom-file-success .custom-file-label::after {
  color: #fff;
  background-color: #6dbb30; }

.custom-file-info .custom-file-label::after {
  color: #fff;
  background-color: #00dffc; }

.custom-file-warning .custom-file-label::after {
  color: #fff;
  background-color: #fad776; }

.custom-file-danger .custom-file-label::after {
  color: #fff;
  background-color: #dc3545; }

.custom-file-light .custom-file-label::after {
  color: #fff;
  background-color: #f8f9fa; }

.custom-file-dark .custom-file-label::after {
  color: #fff;
  background-color: #000000; }

.custom-file-indigo .custom-file-label::after {
  color: #fff;
  background-color: #533be2; }

.custom-file-purple .custom-file-label::after {
  color: #fff;
  background-color: #4c2c92; }

.custom-file-pink .custom-file-label::after {
  color: #fff;
  background-color: #e83e8c; }

/* large */
.custom-file-lg.custom-file-lg,
.custom-file-lg .custom-file-input,
.custom-file-lg .custom-file-label,
.custom-file-lg .custom-file-label::after {
  height: calc(1.5em + 2.18rem + 2px);
  font-size: 1.0625rem; }

.custom-file-lg .custom-file-label {
  line-height: 1.5;
  padding: 1.09rem 1rem;
  overflow: hidden; }

.custom-file-lg .custom-file-input,
.custom-file-lg .custom-file-label::after {
  line-height: 1.5;
  padding: 1.09rem 1rem; }

/* small */
.custom-file-sm.custom-file-sm,
.custom-file-sm .custom-file-input,
.custom-file-sm .custom-file-label,
.custom-file-sm .custom-file-label::after {
  height: calc(1.5em + 0.92rem + 2px);
  font-size: 1.0625rem; }

.custom-file-sm .custom-file-label {
  line-height: 1.5;
  padding: 0.46rem 1rem;
  overflow: hidden; }

.custom-file-sm .custom-file-input,
.custom-file-sm .custom-file-label::after {
  line-height: 1.5;
  padding: 0.46rem 1rem; }

/*

	9. Selectors
	color/size/etc

*/
.form-selector {
  position: relative;
  border: #e9ecef 1px solid;
  cursor: pointer;
  min-width: 35px;
  height: 35px;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-bottom: .25rem;
  margin-right: .25rem; }

.form-selector-lg {
  border-radius: 4px;
  min-width: 46px;
  height: 46px; }

.form-selector-xl {
  border-radius: 4px;
  min-width: 66px;
  height: 66px; }

.form-selector.rounded-circle {
  border-radius: 50rem; }

.form-selector > input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0.0001;
  z-index: -1; }

.form-selector > span {
  min-width: 35px;
  min-height: 35px;
  display: inline-block;
  padding: .56rem 0.5rem;
  line-height: 1;
  border-radius: 0.25rem;
  font-size: 16px;
  text-align: center;
  background-color: #ffffff; }

.form-selector-lg > span {
  padding: .89rem 0;
  line-height: 1;
  border-radius: 4px;
  min-width: 46px;
  min-height: 46px; }

.form-selector.rounded-circle > span {
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50rem; }

.form-selector > i {
  display: inline-block;
  position: absolute;
  border-radius: 0.25rem;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px; }

.form-selector > span:before {
  content: '';
  position: absolute;
  border-radius: 0.25rem;
  border: transparent 1px solid;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.form-selector.form-selector-lg > span:before,
.form-selector.form-selector-xl > span:before {
  border-radius: 4px; }

.form-selector.rounded-circle > span:before {
  border-radius: 50rem; }

.form-selector > i:before {
  content: '';
  border: transparent 1px solid;
  border-radius: 0.25rem;
  position: absolute;
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px; }

.form-selector.rounded-circle > i:before,
.form-selector.rounded-circle > i {
  border-radius: 50rem; }

.form-selector input:checked + span {
  color: #fff;
  background-color: #343a40; }

.form-selector:not(.rounded-circle) input:checked + span {
  border-color: #343a40; }

.form-selector > input:checked + i:before,
.form-selector.rounded-circle input:checked + i {
  border-color: #343a40; }

.form-selector input:checked + i:not(.bg-cover):after {
  content: "\f117";
  font-family: 'Flaticon';
  position: absolute;
  font-size: 12px;
  color: #fff;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -9px; }

.was-validated .form-selector > input:invalid + span:before,
.was-validated .form-selector > input:invalid + i:before {
  border-color: #dc3545 !important; }

.form-selector input[disabled] + span,
.form-selector input[disabled] + i {
  background-color: #e9ecef !important;
  opacity: 0.6;
  cursor: initial; }

.form-selector input[disabled] + i:before {
  background-color: #fff;
  opacity: 0.5 !important; }

/**

	DEFAULT BOOTSTRAP NAVIGATION

**/
/*:not(nav.navbar-dark) a.nav-link:not(.active):not(:hover) {*/
nav.navbar-light a.nav-link:not(.active):not(:hover) {
  color: #212529; }

/* -- -- -- */
/**

	DEEP NAVIGATION
		1. Inside content (mobile collasible)
		2. Sidebar (including admin)

**/
/* group icon indicatorn */
nav.nav-deep .nav-item .group-icon {
  font-size: 12px;
  margin-top: 3px; }

nav.nav-deep-dark .nav-item .group-icon {
  color: #dddddd !important; }

nav.nav-deep-dark-contrast .nav-item .group-icon {
  color: #ffffff !important; }

nav.nav-deep-light .nav-item .group-icon {
  color: #121212 !important; }

/* light */
nav.nav-deep-light .nav-item > a.nav-link {
  color: #121212; }

nav.nav-deep-light .nav-item:hover > .nav-link,
nav.nav-deep-light .nav-item.active > .nav-link {
  color: #377dff; }

/* hover background color */
nav.nav-deep-light.nav-deep-hover > ul > li.nav-item:hover,
nav.nav-deep-light.nav-deep-hover > ul > li.nav-item.active {
  background-color: rgba(0, 0, 0, 0.05); }

/* hover & active background color */
nav.nav-deep-dark .nav-item > a.nav-link {
  color: #dddddd; }

nav.nav-deep-dark-contrast .nav-item > a.nav-link {
  color: #ffffff; }

nav.nav-deep-dark .nav-item:hover > .nav-link,
nav.nav-deep-dark .nav-item.active > .nav-link {
  color: #ffffff; }

nav.nav-deep-light .nav-item.active > .nav-link,
nav.nav-deep-dark .nav-item.active > .nav-link {
  font-weight: 500; }

/* hover background color */
nav.nav-deep-dark.nav-deep-hover > ul > li.nav-item:hover,
nav.nav-deep-dark.nav-deep-hover > ul > li.nav-item.active {
  background-color: rgba(0, 0, 0, 0.05); }

/*
	Dot indicator
	!sidebar only!
*/
aside nav.nav-deep-indicator-dot ul li .nav-item.active > .nav-link {
  position: relative; }

aside nav.nav-deep-light.nav-deep-indicator-dot ul li .nav-item.active > .nav-link:before,
aside nav.nav-deep-dark.nav-deep-indicator-dot ul li .nav-item.active > .nav-link:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -2px;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  background-color: #ffffff;
  left: 0; }

aside nav.nav-deep-light.nav-deep-indicator-dot ul li .nav-item.active > .nav-link:before {
  background-color: #121212; }

/*
	Bordered nav
*/
.nav-deep-bordered > ul > li {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid; }

/*
	Icons
*/
/* main link icon */
nav.nav-deep > ul > li > a.nav-link > i.nav-icon {
  width: 20px;
  /* bigger than font-size to set a nice looking on bigger webfonts */
  font-size: 16px;
  margin-top: -.2rem;
  float: left;
  margin-right: 10px;
  text-align: left; }

/* childs main link icon (and no main icon, but there is an icon) */
nav.nav-deep > ul > li > a.nav-link > i:not(.nav-icon),
nav.nav-deep > ul ul > li > a.nav-link > i {
  margin-right: 10px; }

/* childs */
.nav-deep ul > li:not(.active) > ul {
  display: none; }

/* all childs setup */
.nav-deep > ul ul {
  display: block;
  font-weight: 300; }

/* 1. first level childs */
.nav-deep > ul > li > ul {
  padding-left: 34px; }

/* icons alignment */
.nav-deep > ul > li > ul > li > a > i {
  margin-right: 8px;
  margin-left: -27px; }

/* 2. all other deep level childs */
.nav-deep > ul > li > ul ul {
  padding-left: 15px; }

/**

	Simple quick default navigation
	Nav : Default
	For any list

		<ul class="list-unstyled nav-default shadow-xs">

			<li class="active">
				<a href="#!">
					<i class="fi fi-check"></i>
					<span>Lorem ipsum</span>
				</a>
			</li>

		</ul>

**/
.nav-default a {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
          flex-grow: 1;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.54rem 1rem;
  cursor: pointer;
  -webkit-transition: all 0.33s;
  transition: all 0.33s;
  text-decoration: none; }

.nav-default li.active > a span,
.nav-default a.active span,
.nav-default a:hover span,
.nav-default li.active > a,
.nav-default a.active,
.nav-default a:hover {
  color: #377dff !important; }

.nav-default li.active > a,
.nav-default a.active,
.nav-default a:hover {
  background-color: #f8f9fa; }

.nav-default a > i,
.nav-default a > span.group-icon {
  -webkit-box-flex: 0;
          flex: 0 0 36px;
  font-size: 1.25rem;
  line-height: 0;
  color: #6c757d; }

.nav-default a > span:not(.group-icon) {
  -webkit-box-flex: 1;
          flex-grow: 1;
  font-size: 1rem;
  color: #495057; }

/**

	Responsive

**/
@media only screen and (min-width: 992px) {
  /*	nav.nav-deep-light .nav-item a,
	nav.nav-deep-dark .nav-item a,
	nav.nav-deep-light .nav-item,
	nav.nav-deep-dark .nav-item {
		@include vendors-generate(transition, all .2s ease);
	}
*/ }

/** STAR (STAR)
 *************************************************** **/
.rating-0_0,
.rating-0,
.rating-0_5,
.rating-1,
.rating-1_0,
.rating-1_5,
.rating-2,
.rating-2_0,
.rating-2_5,
.rating-3,
.rating-3_0,
.rating-3_5,
.rating-4,
.rating-4_0,
.rating-4_5,
.rating-5,
.rating-5_0 {
  font: normal normal normal 18px/1 "Flaticon";
  letter-spacing: 3px; }

.rating-0_0:before,
.rating-0:before {
  content: "\f173 \f173 \f173 \f173 \f173 ";
  display: inline-block; }

.rating-0_5:before {
  content: "\f191 \f173 \f173 \f173 \f173 ";
  display: inline-block; }

.rating-1:before,
.rating-1_0:before {
  content: "\f190 \f173 \f173 \f173 \f173 ";
  display: inline-block; }

.rating-1_5:before {
  content: "\f190 \f191 \f173 \f173 \f173 ";
  display: inline-block; }

.rating-2:before,
.rating-2_0:before {
  content: "\f190 \f190 \f173 \f173 \f173 ";
  display: inline-block; }

.rating-2_5:before {
  content: "\f190 \f190 \f191 \f173 \f173 ";
  display: inline-block; }

.rating-3:before,
.rating-3_0:before {
  content: "\f190 \f190 \f190 \f173 \f173 ";
  display: inline-block; }

.rating-3_5:before {
  content: "\f190 \f190 \f190 \f191 \f173 ";
  display: inline-block; }

.rating-4:before,
.rating-4_0:before {
  content: "\f190 \f190 \f190 \f190 \f173 ";
  display: inline-block; }

.rating-4_5:before {
  content: "\f190 \f190 \f190 \f190 \f191 ";
  display: inline-block; }

.rating-5:before,
.rating-5_0:before {
  content: "\f190 \f190 \f190 \f190 \f190 ";
  display: inline-block; }

/** FLAGS
	https://www.flag-sprites.com/
 *************************************************** **/
.flag {
  width: 16px;
  height: 11px;
  background: url("../../images//flags.png") no-repeat;
  display: inline-block; }

/* fixes */
.bootstrap-select button .flag,
.bootstrap-select .dropdown-menu .flag {
  margin-right: 10px; }

.flag.flag-ad {
  background-position: -16px 0; }

.flag.flag-ae {
  background-position: -32px 0; }

.flag.flag-af {
  background-position: -48px 0; }

.flag.flag-ag {
  background-position: -64px 0; }

.flag.flag-ai {
  background-position: -80px 0; }

.flag.flag-al {
  background-position: -96px 0; }

.flag.flag-am {
  background-position: -112px 0; }

.flag.flag-an {
  background-position: -128px 0; }

.flag.flag-ao {
  background-position: -144px 0; }

.flag.flag-ar {
  background-position: -160px 0; }

.flag.flag-as {
  background-position: -176px 0; }

.flag.flag-at {
  background-position: -192px 0; }

.flag.flag-au {
  background-position: -208px 0; }

.flag.flag-aw {
  background-position: -224px 0; }

.flag.flag-az {
  background-position: -240px 0; }

.flag.flag-ba {
  background-position: 0 -11px; }

.flag.flag-bb {
  background-position: -16px -11px; }

.flag.flag-bd {
  background-position: -32px -11px; }

.flag.flag-be {
  background-position: -48px -11px; }

.flag.flag-bf {
  background-position: -64px -11px; }

.flag.flag-bg {
  background-position: -80px -11px; }

.flag.flag-bh {
  background-position: -96px -11px; }

.flag.flag-bi {
  background-position: -112px -11px; }

.flag.flag-bj {
  background-position: -128px -11px; }

.flag.flag-bm {
  background-position: -144px -11px; }

.flag.flag-bn {
  background-position: -160px -11px; }

.flag.flag-bo {
  background-position: -176px -11px; }

.flag.flag-br {
  background-position: -192px -11px; }

.flag.flag-bs {
  background-position: -208px -11px; }

.flag.flag-bt {
  background-position: -224px -11px; }

.flag.flag-bv {
  background-position: -240px -11px; }

.flag.flag-bw {
  background-position: 0 -22px; }

.flag.flag-by {
  background-position: -16px -22px; }

.flag.flag-bz {
  background-position: -32px -22px; }

.flag.flag-ca {
  background-position: -48px -22px; }

.flag.flag-catalonia {
  background-position: -64px -22px; }

.flag.flag-cd {
  background-position: -80px -22px; }

.flag.flag-cf {
  background-position: -96px -22px; }

.flag.flag-cg {
  background-position: -112px -22px; }

.flag.flag-ch {
  background-position: -128px -22px; }

.flag.flag-ci {
  background-position: -144px -22px; }

.flag.flag-ck {
  background-position: -160px -22px; }

.flag.flag-cl {
  background-position: -176px -22px; }

.flag.flag-cm {
  background-position: -192px -22px; }

.flag.flag-cn {
  background-position: -208px -22px; }

.flag.flag-co {
  background-position: -224px -22px; }

.flag.flag-cr {
  background-position: -240px -22px; }

.flag.flag-cu {
  background-position: 0 -33px; }

.flag.flag-cv {
  background-position: -16px -33px; }

.flag.flag-cw {
  background-position: -32px -33px; }

.flag.flag-cy {
  background-position: -48px -33px; }

.flag.flag-cz {
  background-position: -64px -33px; }

.flag.flag-de {
  background-position: -80px -33px; }

.flag.flag-dj {
  background-position: -96px -33px; }

.flag.flag-dk {
  background-position: -112px -33px; }

.flag.flag-dm {
  background-position: -128px -33px; }

.flag.flag-do {
  background-position: -144px -33px; }

.flag.flag-dz {
  background-position: -160px -33px; }

.flag.flag-ec {
  background-position: -176px -33px; }

.flag.flag-ee {
  background-position: -192px -33px; }

.flag.flag-eg {
  background-position: -208px -33px; }

.flag.flag-eh {
  background-position: -224px -33px; }

.flag.flag-england {
  background-position: -240px -33px; }

.flag.flag-er {
  background-position: 0 -44px; }

.flag.flag-es {
  background-position: -16px -44px; }

.flag.flag-et {
  background-position: -32px -44px; }

.flag.flag-eu {
  background-position: -48px -44px; }

.flag.flag-fi {
  background-position: -64px -44px; }

.flag.flag-fj {
  background-position: -80px -44px; }

.flag.flag-fk {
  background-position: -96px -44px; }

.flag.flag-fm {
  background-position: -112px -44px; }

.flag.flag-fo {
  background-position: -128px -44px; }

.flag.flag-fr {
  background-position: -144px -44px; }

.flag.flag-ga {
  background-position: -160px -44px; }

.flag.flag-gb {
  background-position: -176px -44px; }

.flag.flag-gd {
  background-position: -192px -44px; }

.flag.flag-ge {
  background-position: -208px -44px; }

.flag.flag-gf {
  background-position: -224px -44px; }

.flag.flag-gg {
  background-position: -240px -44px; }

.flag.flag-gh {
  background-position: 0 -55px; }

.flag.flag-gi {
  background-position: -16px -55px; }

.flag.flag-gl {
  background-position: -32px -55px; }

.flag.flag-gm {
  background-position: -48px -55px; }

.flag.flag-gn {
  background-position: -64px -55px; }

.flag.flag-gp {
  background-position: -80px -55px; }

.flag.flag-gq {
  background-position: -96px -55px; }

.flag.flag-gr {
  background-position: -112px -55px; }

.flag.flag-gs {
  background-position: -128px -55px; }

.flag.flag-gt {
  background-position: -144px -55px; }

.flag.flag-gu {
  background-position: -160px -55px; }

.flag.flag-gw {
  background-position: -176px -55px; }

.flag.flag-gy {
  background-position: -192px -55px; }

.flag.flag-hk {
  background-position: -208px -55px; }

.flag.flag-hm {
  background-position: -224px -55px; }

.flag.flag-hn {
  background-position: -240px -55px; }

.flag.flag-hr {
  background-position: 0 -66px; }

.flag.flag-ht {
  background-position: -16px -66px; }

.flag.flag-hu {
  background-position: -32px -66px; }

.flag.flag-ic {
  background-position: -48px -66px; }

.flag.flag-id {
  background-position: -64px -66px; }

.flag.flag-ie {
  background-position: -80px -66px; }

.flag.flag-il {
  background-position: -96px -66px; }

.flag.flag-im {
  background-position: -112px -66px; }

.flag.flag-in {
  background-position: -128px -66px; }

.flag.flag-io {
  background-position: -144px -66px; }

.flag.flag-iq {
  background-position: -160px -66px; }

.flag.flag-ir {
  background-position: -176px -66px; }

.flag.flag-is {
  background-position: -192px -66px; }

.flag.flag-it {
  background-position: -208px -66px; }

.flag.flag-je {
  background-position: -224px -66px; }

.flag.flag-jm {
  background-position: -240px -66px; }

.flag.flag-jo {
  background-position: 0 -77px; }

.flag.flag-jp {
  background-position: -16px -77px; }

.flag.flag-ke {
  background-position: -32px -77px; }

.flag.flag-kg {
  background-position: -48px -77px; }

.flag.flag-kh {
  background-position: -64px -77px; }

.flag.flag-ki {
  background-position: -80px -77px; }

.flag.flag-km {
  background-position: -96px -77px; }

.flag.flag-kn {
  background-position: -112px -77px; }

.flag.flag-kp {
  background-position: -128px -77px; }

.flag.flag-kr {
  background-position: -144px -77px; }

.flag.flag-kurdistan {
  background-position: -160px -77px; }

.flag.flag-kw {
  background-position: -176px -77px; }

.flag.flag-ky {
  background-position: -192px -77px; }

.flag.flag-kz {
  background-position: -208px -77px; }

.flag.flag-la {
  background-position: -224px -77px; }

.flag.flag-lb {
  background-position: -240px -77px; }

.flag.flag-lc {
  background-position: 0 -88px; }

.flag.flag-li {
  background-position: -16px -88px; }

.flag.flag-lk {
  background-position: -32px -88px; }

.flag.flag-lr {
  background-position: -48px -88px; }

.flag.flag-ls {
  background-position: -64px -88px; }

.flag.flag-lt {
  background-position: -80px -88px; }

.flag.flag-lu {
  background-position: -96px -88px; }

.flag.flag-lv {
  background-position: -112px -88px; }

.flag.flag-ly {
  background-position: -128px -88px; }

.flag.flag-ma {
  background-position: -144px -88px; }

.flag.flag-mc {
  background-position: -160px -88px; }

.flag.flag-md {
  background-position: -176px -88px; }

.flag.flag-me {
  background-position: -192px -88px; }

.flag.flag-mg {
  background-position: -208px -88px; }

.flag.flag-mh {
  background-position: -224px -88px; }

.flag.flag-mk {
  background-position: -240px -88px; }

.flag.flag-ml {
  background-position: 0 -99px; }

.flag.flag-mm {
  background-position: -16px -99px; }

.flag.flag-mn {
  background-position: -32px -99px; }

.flag.flag-mo {
  background-position: -48px -99px; }

.flag.flag-mp {
  background-position: -64px -99px; }

.flag.flag-mq {
  background-position: -80px -99px; }

.flag.flag-mr {
  background-position: -96px -99px; }

.flag.flag-ms {
  background-position: -112px -99px; }

.flag.flag-mt {
  background-position: -128px -99px; }

.flag.flag-mu {
  background-position: -144px -99px; }

.flag.flag-mv {
  background-position: -160px -99px; }

.flag.flag-mw {
  background-position: -176px -99px; }

.flag.flag-mx {
  background-position: -192px -99px; }

.flag.flag-my {
  background-position: -208px -99px; }

.flag.flag-mz {
  background-position: -224px -99px; }

.flag.flag-na {
  background-position: -240px -99px; }

.flag.flag-nc {
  background-position: 0 -110px; }

.flag.flag-ne {
  background-position: -16px -110px; }

.flag.flag-nf {
  background-position: -32px -110px; }

.flag.flag-ng {
  background-position: -48px -110px; }

.flag.flag-ni {
  background-position: -64px -110px; }

.flag.flag-nl {
  background-position: -80px -110px; }

.flag.flag-no {
  background-position: -96px -110px; }

.flag.flag-np {
  background-position: -112px -110px; }

.flag.flag-nr {
  background-position: -128px -110px; }

.flag.flag-nu {
  background-position: -144px -110px; }

.flag.flag-nz {
  background-position: -160px -110px; }

.flag.flag-om {
  background-position: -176px -110px; }

.flag.flag-pa {
  background-position: -192px -110px; }

.flag.flag-pe {
  background-position: -208px -110px; }

.flag.flag-pf {
  background-position: -224px -110px; }

.flag.flag-pg {
  background-position: -240px -110px; }

.flag.flag-ph {
  background-position: 0 -121px; }

.flag.flag-pk {
  background-position: -16px -121px; }

.flag.flag-pl {
  background-position: -32px -121px; }

.flag.flag-pm {
  background-position: -48px -121px; }

.flag.flag-pn {
  background-position: -64px -121px; }

.flag.flag-pr {
  background-position: -80px -121px; }

.flag.flag-ps {
  background-position: -96px -121px; }

.flag.flag-pt {
  background-position: -112px -121px; }

.flag.flag-pw {
  background-position: -128px -121px; }

.flag.flag-py {
  background-position: -144px -121px; }

.flag.flag-qa {
  background-position: -160px -121px; }

.flag.flag-re {
  background-position: -176px -121px; }

.flag.flag-ro {
  background-position: -192px -121px; }

.flag.flag-rs {
  background-position: -208px -121px; }

.flag.flag-ru {
  background-position: -224px -121px; }

.flag.flag-rw {
  background-position: -240px -121px; }

.flag.flag-sa {
  background-position: 0 -132px; }

.flag.flag-sb {
  background-position: -16px -132px; }

.flag.flag-sc {
  background-position: -32px -132px; }

.flag.flag-scotland {
  background-position: -48px -132px; }

.flag.flag-sd {
  background-position: -64px -132px; }

.flag.flag-se {
  background-position: -80px -132px; }

.flag.flag-sg {
  background-position: -96px -132px; }

.flag.flag-sh {
  background-position: -112px -132px; }

.flag.flag-si {
  background-position: -128px -132px; }

.flag.flag-sk {
  background-position: -144px -132px; }

.flag.flag-sl {
  background-position: -160px -132px; }

.flag.flag-sm {
  background-position: -176px -132px; }

.flag.flag-sn {
  background-position: -192px -132px; }

.flag.flag-so {
  background-position: -208px -132px; }

.flag.flag-somaliland {
  background-position: -224px -132px; }

.flag.flag-sr {
  background-position: -240px -132px; }

.flag.flag-ss {
  background-position: 0 -143px; }

.flag.flag-st {
  background-position: -16px -143px; }

.flag.flag-sv {
  background-position: -32px -143px; }

.flag.flag-sx {
  background-position: -48px -143px; }

.flag.flag-sy {
  background-position: -64px -143px; }

.flag.flag-sz {
  background-position: -80px -143px; }

.flag.flag-tc {
  background-position: -96px -143px; }

.flag.flag-td {
  background-position: -112px -143px; }

.flag.flag-tf {
  background-position: -128px -143px; }

.flag.flag-tg {
  background-position: -144px -143px; }

.flag.flag-th {
  background-position: -160px -143px; }

.flag.flag-tibet {
  background-position: -176px -143px; }

.flag.flag-tj {
  background-position: -192px -143px; }

.flag.flag-tk {
  background-position: -208px -143px; }

.flag.flag-tl {
  background-position: -224px -143px; }

.flag.flag-tm {
  background-position: -240px -143px; }

.flag.flag-tn {
  background-position: 0 -154px; }

.flag.flag-to {
  background-position: -16px -154px; }

.flag.flag-tr {
  background-position: -32px -154px; }

.flag.flag-tt {
  background-position: -48px -154px; }

.flag.flag-tv {
  background-position: -64px -154px; }

.flag.flag-tw {
  background-position: -80px -154px; }

.flag.flag-tz {
  background-position: -96px -154px; }

.flag.flag-ua {
  background-position: -112px -154px; }

.flag.flag-ug {
  background-position: -128px -154px; }

.flag.flag-um {
  background-position: -144px -154px; }

.flag.flag-us {
  background-position: -160px -154px; }

.flag.flag-uy {
  background-position: -176px -154px; }

.flag.flag-uz {
  background-position: -192px -154px; }

.flag.flag-va {
  background-position: -208px -154px; }

.flag.flag-vc {
  background-position: -224px -154px; }

.flag.flag-ve {
  background-position: -240px -154px; }

.flag.flag-vg {
  background-position: 0 -165px; }

.flag.flag-vi {
  background-position: -16px -165px; }

.flag.flag-vn {
  background-position: -32px -165px; }

.flag.flag-vu {
  background-position: -48px -165px; }

.flag.flag-wales {
  background-position: -64px -165px; }

.flag.flag-wf {
  background-position: -80px -165px; }

.flag.flag-ws {
  background-position: -96px -165px; }

.flag.flag-xk {
  background-position: -112px -165px; }

.flag.flag-ye {
  background-position: -128px -165px; }

.flag.flag-yt {
  background-position: -144px -165px; }

.flag.flag-za {
  background-position: -160px -165px; }

.flag.flag-zanzibar {
  background-position: -176px -165px; }

.flag.flag-zm {
  background-position: -192px -165px; }

.flag.flag-zw {
  background-position: -208px -165px; }

@font-face {
  font-family: "Flaticon";
  src: local("Flaticon"), url("../../fonts/flaticon/Flaticon.woff2") format("woff2"), url("../../fonts/flaticon/Flaticon.woff") format("woff"), url("../../fonts/flaticon/Flaticon.ttf") format("truetype"), url("../../fonts/flaticon/Flaticon.eot") format("embedded-opentype"), url("../../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"), url("../../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../fonts/flaticon/Flaticon.svg#Flaticon") format("svg"); } }

.fi:before {
  font-family: "Flaticon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1.5;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

.fi.d-middle {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
          justify-content: center !important; }

.fi.d-middle:before {
  margin: 0 !important;
  padding: 0 !important; }

.iconsPreview a {
  color: #666;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 0.25rem;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out; }

.iconsPreview a:hover {
  color: #000000;
  background-color: #f8f9fa; }

.iconsPreview a .preview-copy-clipboard {
  padding-top: 5px; }

/* fixes */
.btn:not(.rounded-circle):not(.btn-sm) i.fi {
  font-size: 15px; }

.btn-sm:not(.rounded-circle) i.fi, .btn-group-sm > .btn:not(.rounded-circle) i.fi {
  font-size: 13px; }

.fi {
  vertical-align: middle;
  display: inline-block; }

h1 .fi:before,
h2 .fi:before,
h3 .fi:before,
h4 .fi:before,
h5 .fi:before,
h6 .fi:before {
  line-height: 1.18; }

button.close {
  font-size: 1.0625rem; }

.fi-spin:before {
  display: inline-block;
  -webkit-animation: spin 1.3s infinite linear;
  animation: spin 1.3s infinite linear; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.mdi-close:before {
  content: "\e900"; }

.mdi-format_align_center:before {
  content: "\e901"; }

.mdi-format_align_justify:before {
  content: "\e902"; }

.mdi-format_align_left:before {
  content: "\e903"; }

.mdi-format_align_right:before {
  content: "\e904"; }

.mdi-format_bold:before {
  content: "\e905"; }

.mdi-format_clear:before {
  content: "\e906"; }

.mdi-format_color_fill:before {
  content: "\e907"; }

.mdi-format_color_reset:before {
  content: "\e908"; }

.mdi-format_color_text:before {
  content: "\e909"; }

.mdi-format_indent_decrease:before {
  content: "\e90a"; }

.mdi-format_indent_increase:before {
  content: "\e90b"; }

.mdi-format_italic:before {
  content: "\e90c"; }

.mdi-format_line_spacing:before {
  content: "\e90d"; }

.mdi-format_list_bulleted:before {
  content: "\e90e"; }

.mdi-format_list_numbered:before {
  content: "\e90f"; }

.mdi-filter_1:before {
  content: "\e910"; }

.mdi-filter_2:before {
  content: "\e911"; }

.mdi-filter_3:before {
  content: "\e912"; }

.mdi-filter_4:before {
  content: "\e913"; }

.mdi-filter_5:before {
  content: "\e914"; }

.mdi-filter_6:before {
  content: "\e915"; }

.mdi-filter_7:before {
  content: "\e916"; }

.mdi-filter_8:before {
  content: "\e917"; }

.mdi-filter_9:before {
  content: "\e918"; }

.mdi-check:before {
  content: "\e91a"; }

.fi-user-female:before {
  content: "\f1ad"; }

.fi-eq-vertical:before {
  content: "\f16b"; }

.fi-arrow-down:before {
  content: "\f1c4"; }

.fi-heart-full:before {
  content: "\f18d"; }

.fi-eye:before {
  content: "\f18b"; }

.fi-shape-hexa:before {
  content: "\f143"; }

.fi-layers:before {
  content: "\f16d"; }

.fi-start-half:before {
  content: "\f191"; }

.fi-drop:before {
  content: "\f11c"; }

.fi-colors:before {
  content: "\f105"; }

.fi-menu-dots:before {
  content: "\f1e2"; }

.fi-percent:before {
  content: "\f139"; }

.fi-mic-disabled:before {
  content: "\f14e"; }

.fi-thrash:before {
  content: "\f1ba"; }

.fi-eye-disabled:before {
  content: "\f18a"; }

.fi-round-close:before {
  content: "\f1a8"; }

.fi-brand-apple:before {
  content: "\f1e1"; }

.fi-mollecules:before {
  content: "\f17c"; }

.fi-minus:before {
  content: "\f196"; }

.fi-support-1:before {
  content: "\f123"; }

.fi-arrow-up:before {
  content: "\f19b"; }

.fi-shield-ok:before {
  content: "\f12d"; }

.fi-task-list:before {
  content: "\f15b"; }

.fi-print:before {
  content: "\f102"; }

.fi-heart-half:before {
  content: "\f18e"; }

.fi-chat:before {
  content: "\f11f"; }

.fi-dots-vertical:before {
  content: "\f152";
  /* corrections */
  display: inherit;
  margin-top: -2px; }

.fi-arrow-upload:before {
  content: "\f1da"; }

.fi-direction:before {
  content: "\f14b"; }

.fi-cart-1:before {
  content: "\f1a1"; }

.fi-layers-middle:before {
  content: "\f1e3"; }

.fi-circle-spin:before {
  content: "\f1e0"; }

.fi-play-empty:before {
  content: "\f188"; }

.fi-search:before {
  content: "\f1b6"; }

.fi-envelope:before {
  content: "\f1a0"; }

.fi-arrow-up-slim:before {
  content: "\f197"; }

.fi-expand:before {
  content: "\f19f"; }

.fi-arrow-right-full:before {
  content: "\f1bc"; }

.fi-cog-full:before {
  content: "\f13b"; }

.fi-pencil:before {
  content: "\f1b2"; }

.fi-graduate:before {
  content: "\f1c6"; }

.fi-smartphone:before {
  content: "\f159"; }

.fi-like:before {
  content: "\f1b7"; }

.fi-arrow-left-full:before {
  content: "\f1bd"; }

.fi-folder-full:before {
  content: "\f15d"; }

.fi-smartphone-chat:before {
  content: "\f122"; }

.fi-squared-info:before {
  content: "\f125"; }

.fi-eco:before {
  content: "\f11a"; }

.fi-cog:before {
  content: "\f1b8"; }

.fi-heart-empty:before {
  content: "\f18f"; }

.fi-round-target:before {
  content: "\f16f"; }

.fi-bell:before {
  content: "\f163"; }

.fi-squared-minus:before {
  content: "\f194"; }

.fi-share:before {
  content: "\f17e"; }

.fi-recycle:before {
  content: "\f11b"; }

.fi-hourglass:before {
  content: "\f16e"; }

.fi-power:before {
  content: "\f133"; }

.fi-phone-24:before {
  content: "\f128"; }

.fi-plus:before {
  content: "\f195"; }

.fi-shape-abstract-cube:before {
  content: "\f106"; }

.fi-round-info-full:before {
  content: "\f13e"; }

.fi-layerstore:before {
  content: "\f11e"; }

.fi-envelope-2:before {
  content: "\f1cf"; }

.fi-database:before {
  content: "\f176"; }

.fi-sound-on:before {
  content: "\f1cc"; }

.fi-unlink:before {
  content: "\f158"; }

.fi-home:before {
  content: "\f1a9"; }

.fi-check:before {
  content: "\f117";
  /* corrections */
  font-size: 93%;
  display: inline-block;
  padding-top: 0.06rem; }

.fi-users:before {
  content: "\f148"; }

.fi-box:before {
  content: "\f156"; }

.fi-heart-slim:before {
  content: "\f174"; }

.fi-arrow-download:before {
  content: "\f1db"; }

.fi-phone:before {
  content: "\f1a3"; }

.fi-orbit:before {
  content: "\f1de"; }

.fi-bell-full-active:before {
  content: "\f1d6"; }

.fi-brand-android:before {
  content: "\f1d1"; }

.fi-smart-devices:before {
  content: "\f100"; }

.fi-pin-2:before {
  content: "\f171"; }

.fi-arrow-down-slim:before {
  content: "\f198"; }

.fi-box-checked:before {
  content: "\f1c9"; }

.fi-atom:before {
  content: "\f179"; }

.fi-arrow-right-3:before {
  content: "\f1b9"; }

.fi-star-empty:before {
  content: "\f1b3"; }

.fi-loading-dots:before {
  content: "\f131"; }

.fi-arrow-up-full:before {
  content: "\f1be"; }

.fi-eq-horizontal:before {
  content: "\f16a"; }

.fi-round-play:before {
  content: "\f187"; }

.fi-list-checked:before {
  content: "\f1c7"; }

.fi-star-empty-radius:before {
  content: "\f173"; }

.fi-target-simple:before {
  content: "\f112"; }

.fi-road:before {
  content: "\f12c"; }

.fi-mic:before {
  content: "\f14f"; }

.fi-round-list:before {
  content: "\f181"; }

.fi-brand-windows:before {
  content: "\f1df"; }

.fi-cart-2:before {
  content: "\f1a2"; }

.fi-product-tag:before {
  content: "\f155"; }

.fi-globe:before {
  content: "\f130"; }

.fi-cart-3:before {
  content: "\f129"; }

.fi-colors-2:before {
  content: "\f134"; }

.fi-lightbulb:before {
  content: "\f109"; }

.fi-round-question-full:before {
  content: "\f1d3"; }

.fi-bars-2:before {
  content: "\f116"; }

.fi-attachment:before {
  content: "\f13d"; }

.fi-checklist-squared:before {
  content: "\f1ce"; }

.fi-graph:before {
  content: "\f110"; }

.fi-selectable:before {
  content: "\f104"; }

.fi-cloud-upload:before {
  content: "\f138"; }

.fi-mdeal:before {
  content: "\f12b"; }

.fi-24:before {
  content: "\f127"; }

.fi-cart-4:before {
  content: "\f10c"; }

.fi-list:before {
  content: "\f1dd"; }

.fi-user-plus:before {
  content: "\f119"; }

.fi-truck-speed:before {
  content: "\f12f"; }

.fi-arrow-down-full:before {
  content: "\f1bf"; }

.fi-cogs:before {
  content: "\f13c"; }

.fi-dashboard:before {
  content: "\f140"; }

.fi-arrow-right-slim:before {
  content: "\f19a"; }

.fi-lightning:before {
  content: "\f11d"; }

.fi-dots-horizontal:before {
  content: "\f153"; }

.fi-loading:before {
  content: "\f1a5"; }

.fi-exit:before {
  content: "\f151"; }

.fi-plus-slim:before {
  content: "\f114"; }

.fi-umbrella:before {
  content: "\f169"; }

.fi-gps:before {
  content: "\f14c"; }

.fi-locked:before {
  content: "\f145"; }

.fi-close:before {
  content: "\f103";
  /* corrections */
  font-size: 82%;
  padding-left: 1px;
  padding-top: 0.156rem;
  display: inherit; }

.btn .fi-close:before {
  padding-top: 0 !important; }

.fi-link:before {
  content: "\f15a"; }

.fi-shape-3dots:before {
  content: "\f17d"; }

.fi-round-question:before {
  content: "\f165"; }

.fi-image:before {
  content: "\f18c"; }

.fi-round-plus:before {
  content: "\f186"; }

.fi-support:before {
  content: "\f10f"; }

.fi-go-back:before {
  content: "\f111"; }

.fi-play:before {
  content: "\f137"; }

.fi-dislike:before {
  content: "\f1a4"; }

.fi-chart-up:before {
  content: "\f1c5"; }

.fi-map:before {
  content: "\f172"; }

.fi-dots-triangle:before {
  content: "\f142"; }

.fi-dots-horizontal-full:before {
  content: "\f1d2"; }

.fi-reload:before {
  content: "\f113"; }

.fi-bookmark:before {
  content: "\f118"; }

.fi-shape-abstract-dots:before {
  content: "\f1cd"; }

.fi-arrow-left:before {
  content: "\f1ac"; }

.fi-bars:before {
  content: "\f17b"; }

.fi-speaker-mute:before {
  content: "\f1cb"; }

.fi-send:before {
  content: "\f149"; }

.fi-brain:before {
  content: "\f13f"; }

.fi-bell-active:before {
  content: "\f162"; }

.fi-arrow-left-slim:before {
  content: "\f199"; }

.fi-folder-empty:before {
  content: "\f15e"; }

.fi-cart-5:before {
  content: "\f10b"; }

.fi-shrink:before {
  content: "\f19c"; }

.fi-pin:before {
  content: "\f124"; }

.fi-squares:before {
  content: "\f141"; }

.fi-bell-full:before {
  content: "\f1d8"; }

.fi-squared-graph:before {
  content: "\f136"; }

.fi-squared-dots:before {
  content: "\f154"; }

.fi-gift:before {
  content: "\f164"; }

.fi-star:before {
  content: "\f192"; }

.fi-time:before {
  content: "\f15f"; }

.fi-cloud-download:before {
  content: "\f1dc"; }

.fi-shape-star:before {
  content: "\f1ca"; }

.fi-loud:before {
  content: "\f178"; }

.fi-dots-vertical-full:before {
  content: "\f1d0";
  /* corrections */
  display: inherit;
  margin-top: -2px; }

.fi-user-male:before {
  content: "\f147"; }

.fi-star-full:before {
  content: "\f190"; }

.fi-help:before {
  content: "\f168"; }

.fi-fingerprint:before {
  content: "\f167"; }

.fi-plus-squared:before {
  content: "\f193"; }

.fi-round-lightning:before {
  content: "\f12a"; }

.fi-pie-chart:before {
  content: "\f1b1"; }

.fi-arrow-right:before {
  content: "\f1ae"; }

.fi-calendar:before {
  content: "\f108"; }

.fi-shape-squared-unequal:before {
  content: "\f135"; }

.fi-cart-6:before {
  content: "\f1d7"; }

.fi-support-headphones:before {
  content: "\f120"; }

.fi-code:before {
  content: "\f14d"; }

.fi-brand-amazon:before {
  content: "\e004"; }

.fi-social-facebook:before {
  content: "\e028"; }

.fi-social-linkedin:before {
  content: "\e04c"; }

.fi-social-pinterest:before {
  content: "\e063"; }

.fi-social-rss:before {
  content: "\e071"; }

.fi-social-snapchat:before {
  content: "\e076"; }

.fi-social-twitter:before {
  content: "\e08d"; }

.fi-social-vimeo:before {
  content: "\e093"; }

.fi-social-youtube:before {
  content: "\e0a5"; }

.fi-social-unsplash:before {
  content: "\e91b"; }

.fi-homeadvisor:before {
  content: "\e91c"; }

.fi-movie:before {
  content: "\e921"; }

.fi-cart-7:before {
  content: "\e93a"; }

.fi-emoji-happy:before {
  content: "\e9e0"; }

.fi-emoji-smile:before {
  content: "\e9e2"; }

.fi-emoji-tongue:before {
  content: "\e9e4"; }

.fi-emoji-sad:before {
  content: "\e9e6"; }

.fi-emoji-wink:before {
  content: "\e9e8"; }

.fi-emoji-grin:before {
  content: "\e9ea"; }

.fi-emoji-cool:before {
  content: "\e9ec"; }

.fi-emoji-angry:before {
  content: "\e9ee"; }

.fi-emoji-evil:before {
  content: "\e9f0"; }

.fi-emoji-shocked:before {
  content: "\e9f2"; }

.fi-emoji-baffled:before {
  content: "\e9f4"; }

.fi-emoji-confused:before {
  content: "\e9f6"; }

.fi-emoji-neutral:before {
  content: "\e9f8"; }

.fi-emoji-hipster:before {
  content: "\e9fa"; }

.fi-emoji-wondering:before {
  content: "\e9fc"; }

.fi-emoji-sleepy:before {
  content: "\e9fe"; }

.fi-emoji-frustrated:before {
  content: "\ea00"; }

.fi-emoji-crying:before {
  content: "\ea02"; }

.fi-brand-paypal:before {
  content: "\ead8"; }

.fi-social-twitch:before {
  content: "\e920"; }

.fi-social-spotify:before {
  content: "\e91d"; }

.fi-social-instagram:before {
  content: "\e91e"; }

.fi-social-github:before {
  content: "\e922"; }

.fi-social-soundcloud:before {
  content: "\e91f"; }

.fi-arrow-start:before {
  content: "\f1ac"; }

.fi-arrow-end:before {
  content: "\f1ae"; }

.fi-arrow-start-slim:before {
  content: "\f199"; }

.fi-arrow-end-slim:before {
  content: "\f19a"; }

.fi-arrow-start-full:before {
  content: "\f1bd"; }

.fi-arrow-end-full:before {
  content: "\f1bc"; }

/**

	DEFAULTS

**/
#footer a {
  color: #121212; }

/**

	LAYOUT :: FOOTER

**/
#footer.footer-dark {
  color: #ffffff;
  background: #313131;
  background: -webkit-gradient(linear, left top, left bottom, from(#454545), to(#313131));
  background: linear-gradient(to bottom, #454545 0%, #313131 100%); }

#footer.footer-dark .footer-svg-shape > svg {
  fill: #3e3e3e !important; }

#footer.footer-dark a {
  color: #ffffff; }

#footer.footer-dark .bg-distinct {
  background-color: rgba(0, 0, 0, 0.1); }

#footer.footer-dark hr {
  border-color: rgba(108, 117, 125, 0.3); }

#footer.footer-light {
  color: #212529;
  background: #ebebeb;
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(#ebebeb));
  background: linear-gradient(to bottom, white 0%, #ebebeb 100%); }

#footer.footer-light .footer-svg-shape > svg {
  fill: #f8f8f8 !important; }

#footer.footer-light .bg-distinct {
  background-color: rgba(0, 0, 0, 0.03); }

/**

	Responsive

**/
@media only screen and (max-width: 992px) {
  #footer .breadcrumb-item {
    padding-left: 0;
    padding-right: 0;
    font-size: 17px;
    float: none !important;
    display: block;
    margin-top: 3px;
    width: 100%;
    text-align: center; }
  #footer .breadcrumb-item a {
    padding: 8px !important;
    display: block;
    background-color: rgba(255, 255, 255, 0.02);
    text-decoration: none;
    border-radius: 0.25rem; }
  #footer .breadcrumb .breadcrumb-item:hover a {
    background-color: rgba(255, 255, 255, 0.03); }
  #footer:not(.footer-dark) .breadcrumb-item a {
    background-color: rgba(0, 0, 0, 0.02); }
  #footer:not(.footer-dark) .breadcrumb-item:hover a {
    background-color: rgba(0, 0, 0, 0.03); }
  #footer .breadcrumb-item + .breadcrumb-item::before {
    display: none !important;
    padding: 0; } }

/**

	LAYOUT :: SIDEBAR

**/
/**

	Responsive

**/
/**

	LAYOUT :: GLOBALS

**/
#middle {
  position: relative;
  max-width: 100% !important; }

.container {
  position: relative; }

/**

	Responsive

**/
@media only screen and (min-width: 992px) {
  /**

		Boxed Layout
		header-* classes not supported at this time!

	**/
  body:not(.header-over):not(.header-sticky):not(.header-scroll-reveal):not(.layout-admin).layout-boxed {
    background-color: #f2f3f8; }
  body:not(.header-over):not(.header-sticky):not(.header-scroll-reveal):not(.layout-admin).layout-boxed #wrapper {
    margin: 60px auto;
    max-width: 1200px;
    background-color: #fff;
    -ms-box-shadow: 0 0 35px rgba(140, 152, 164, 0.15);
    -o-box-shadow: 0 0 35px rgba(140, 152, 164, 0.15);
    box-shadow: 0 0 35px rgba(140, 152, 164, 0.15);
    border-radius: 0.25rem; }
  body:not(.header-over):not(.header-sticky):not(.header-scroll-reveal):not(.layout-admin).layout-boxed #header,
  body:not(.header-over):not(.header-sticky):not(.header-scroll-reveal):not(.layout-admin).layout-boxed #header > #top_bar {
    -moz-border-top-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -moz-border-top-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  body:not(.header-over):not(.header-sticky):not(.header-scroll-reveal):not(.layout-admin).layout-boxed #footer {
    -moz-border-bottom-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    -moz-border-bottom-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; } }

/**

	LAYOUT :: MISC

**/
/**

	Responsive

**/
/**

	Swiper Slider
	_vendors/vendor.swiper.scss is dynamically loaded
	we need it here to be instantly loaded (because is added to core.min.css)

	Flickity - exactly the same!

*/
.flickity-preloader:not(.flickity-enabled) > div:not(:first-child),
.flickity-preloader:not(.flickity-enabled) > img:not(:first-child),
.flickity-preloader:not(.flickity-enabled) > a:not(:first-child),
.swiper-container:not(.swiper-container-initialized) .swiper-slide:not(:first-child) {
  display: none; }

.flickity-preloader:not(.flickity-enabled),
.swiper-container.swiper-preloader:not(.swiper-container-initialized) {
  position: relative; }

.flickity-preloader:not(.flickity-enabled):after,
.swiper-container.swiper-preloader:not(.swiper-container-initialized):after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; }

.flickity-preloader:not(.flickity-enabled) > div:first-child,
.flickity-preloader:not(.flickity-enabled) > img:first-child,
.flickity-preloader:not(.flickity-enabled) > a:first-child,
.swiper-container.swiper-preloader:not(.swiper-container-initialized) .swiper-slide:first-child {
  opacity: 0.2; }

.flickity-preloader:not(.flickity-enabled):before,
.swiper-container.swiper-preloader:not(.swiper-container-initialized):before {
  content: "\f1e0";
  font-family: 'Flaticon';
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 50%;
  margin-left: -15px;
  font-size: 30px;
  z-index: 2;
  color: #ffffff;
  -webkit-animation: spin 1.3s infinite linear;
  animation: spin 1.3s infinite linear; }

.slimScrollBar {
  z-index: 9 !important; }

.slimScrollRail {
  z-index: 8 !important; }

section.jarallax {
  -webkit-transition: none !important;
  transition: none !important; }

/**

	LAYOUT :: ADMIN

	We set here very few important & general rules to
	avoid issues on fixed sidebar/header

**/
body.layout-admin {
  background-color: #f9f9fc;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

body.layout-admin #wrapper {
  -webkit-box-flex: 1;
          flex: 1; }

body.layout-admin #header {
  z-index: 10; }

body.layout-admin #middle {
  /* general rules for #middle are added to global.scss */
  padding: 30px;
  /*min-height: calc(100vh - 80px); */ }

/*

	.m--negative
	No container margins if #middle has margins!
	+ .row margins fix

*/
/*body.layout-admin #middle>section>.row,*/
body.layout-admin #middle > .m--negative {
  margin-left: -30px !important;
  margin-right: -30px !important;
  border-radius: 0 !important; }

body.layout-admin #middle > section:first-child.m--negative {
  margin-top: -30px !important; }

/* pagetitle, extended options */
body.layout-admin #middle > .page-title {
  padding: 3px 15px;
  background-color: #ffffff;
  margin-top: -30px !important;
  margin-bottom: 30px !important;
  margin-left: -30px !important;
  margin-right: -30px !important;
  border-top: #e9ecef 1px solid;
  z-index: 2; }

/* section settings */
body.layout-admin #middle section {
  color: #444444;
  margin-bottom: 30px;
  font-size: 1.0625rem;
  border: 0;
  min-width: 0;
  /* in case .d-flex is used */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

body.layout-admin #middle section:not(.section-clean) {
  padding: 15px;
  background-color: #ffffff;
  -ms-box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  -o-box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  border-radius: 0.25rem; }

/* section header */
body.layout-admin #middle section > header,
body.layout-admin #middle .section-header {
  position: relative;
  word-break: normal;
  /* make it full */
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  /* readjust spacings */
  padding: 15px;
  margin-bottom: 15px;
  /* rounded */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

body.layout-admin #middle section > header > .ui-options,
body.layout-admin #middle .section-header > .ui-options {
  padding-right: 15px;
  margin-right: -15px;
  margin-left: 15px; }

body.layout-admin #middle section > header > .ui-options > a,
body.layout-admin #middle header > .ui-options > a,
body.layout-admin #middle .section-header > .ui-options > a {
  color: #313131;
  text-decoration: none;
  margin-left: 10px; }

body.layout-admin #middle section > header:not(.bg-light):not(.b-0):not(.b--0):not(.border-0):after,
body.layout-admin #middle .section-header:not(.bg-light):not(.b-0):not(.b--0):not(.border-0):after {
  content: '';
  display: block;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  position: absolute;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0; }

body.layout-admin #middle > section:last-child {
  margin-bottom: 0; }

/* body section */
body.layout-admin #middle .section-body {
  position: relative; }

/* footer */
body.layout-admin #footer a {
  color: #dddddd;
  text-decoration: none; }

body.layout-admin #footer a.active,
body.layout-admin #footer .active > a {
  color: #ffffff; }

body.layout-admin #header.bg-dark {
  background: #454545 !important; }

body.layout-admin #footer.bg-dark {
  background: #313131 !important; }

/* portlet : flex */
.portlet {
  background: #fff;
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1);
  font-size: 16px;
  color: #495057;
  border-radius: 0.25rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%; }

.portlet-header {
  padding: 1rem;
  width: 100%;
  align-self: baseline; }
  .portlet-header > span {
    display: block;
    font-weight: 500;
    color: #6c757d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.portlet-body {
  padding: 1rem;
  height: 100%;
  width: 100%;
  align-self: baseline; }

.portlet-footer {
  padding: 1rem;
  display: -webkit-box;
  display: flex;
  align-self: baseline;
  width: 100%; }

.portlet .border:not(.medium-editor),
.portlet .border-bottom,
.portlet .border-top {
  border-color: #eff1f3 !important; }

/**

		Not fully supported by old browsers!
		But is ok for admin!

		https://caniuse.com/#feat=css-sticky

	**/
@supports ((position: -webkit-sticky) or (position: sticky)) {
  /* sticky : header */
  body.layout-admin.header-sticky:not(.header-fixed):not(.header-hide) #header {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
  /* sticky : footer */
  body.layout-admin.footer-sticky #footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0; } }

/*
	Fixes
*/
body.layout-admin #header ul.navbar-nav a.nav-link i + span {
  padding-left: 10px; }

body.layout-admin #middle.dropdown-menu {
  font-size: 14px; }

body.layout-admin .dropdown-mega > .dropdown-menu {
  max-width: 1200px; }

aside.overlay-dark:after {
  border-radius: 0.25rem; }

/**

	Responsive

**/
@media only screen and (min-width: 992px) {
  /* logo width on aside-focus */
  body.layout-admin:not(.aside-focus) #header .navbar-brand.aside-width {
    width: 260px !important;
    margin: 0 !important;
    margin-left: -15px !important;
    padding-left: 15px !important; }
  /**

		LAYOUT : PADDED

	**/
  body.layout-admin.layout-padded {
    padding: 30px; }
  body.layout-admin.layout-padded .aside-start,
  body.layout-admin.layout-padded .aside-end {
    top: 30px !important;
    bottom: 30px !important;
    height: auto !important;
    border-radius: 0.25rem;
    -webkit-transition: none !important;
    transition: none !important; }
  body.layout-admin.layout-padded .aside-wrapper {
    border-radius: 0.25rem; }
  body.layout-admin.layout-padded .aside-start {
    left: 30px; }
  body.layout-admin.layout-padded .aside-end {
    right: 30px; }
  body.layout-admin.layout-padded #header {
    margin-left: 290px !important; }
  body.layout-admin.layout-padded #middle {
    padding-right: 0; }
  body.layout-admin.layout-padded #middle > .page-title {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  body.layout-admin.layout-padded #footer {
    padding-left: 290px !important; } }

@media only screen and (max-width: 992px) {
  body.layout-admin #header .navbar .navbar-brand:not(.w-auto),
  body.layout-admin #header .navbar .navbar-brand:not(.w-auto) > img {
    max-width: 80px !important; }
  body.layout-admin section > .container {
    padding-left: 0;
    padding-right: 0; }
  body.layout-admin #header ul.navbar-nav a.nav-link > span {
    background-color: transparent !important; }
  body.layout-admin .navbar-collapse.show li.nav-item {
    width: 100%; } }

/**

    Load all variables,
    we need them!

**/
/*

	Styling
	Ajax Loading Icons

*/
#js_loading_icon {
  width: auto;
  height: auto;
  border-radius: 0.25rem;
  box-shadow: 0 0 25px rgba(140, 152, 164, 0.1); }

#js_loading_icon:after {
  border-radius: 0.25rem; }

/* inside forms */
form.js-ajax {
  position: relative; }

form.js-ajax #js_loading_icon {
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px; }

/**

    Load all variables,
    we need them!

**/
/**

	Stepofeb Header
	www.stepofweb.com

	Part of:
		GULP/src/plugins/sow_pack/sow_header.js

**/
/*body:not(.header-sticky) #header,*/
body.header-over #header,
body.header-scroll-reveal #header {
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out; }

body:not(.layout-admin).header-sticky #header {
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear; }

/**

	HEADER : FIXED

**/
body.header-fixed #header {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 10;
  top: 0; }

/**

    HEADER : SCROLL REVEAL

**/
body.header-scroll-reveal #header.header-down {
  top: 0; }

body.header-scroll-reveal #header.header-up {
  top: -100%; }

body.header-scroll-reveal:not(.header-is-on-top) #header.header-down #top_bar,
body.header-scroll-reveal:not(.header-is-on-top) #header.header-up #top_bar {
  display: none; }

/**

    HEADER : BOTTOM
    not recommended, bad conversions

**/
body.header-bottom #header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

body.header-bottom #header .nav-item > .dropdown-menu,
body.header-bottom header.main-nav .nav-item > .dropdown-menu {
  top: auto;
  bottom: 80px; }

body.header-bottom #header .navbar-nav .nav-link.dropdown-toggle:not(.nav-link-caret-hide):before,
body.header-bottom header.main-nav .navbar-nav .nav-link.dropdown-toggle:not(.nav-link-caret-hide):before {
  content: "\f19b"; }

/**

    HEADER : NAV BRAND

**/
#header .navbar-brand > img:last-child {
  display: none; }

#header .navbar-brand > img:first-child {
  display: inherit; }

body:not(.user-scrolled-down) #header .navbar-brand > img:first-child {
  display: none; }

body:not(.user-scrolled-down) #header .navbar-brand > img:last-child {
  display: inline-block; }

/**

    Load all variables,
    we need them!

**/
/**

	Stepofeb Search Suggest
	www.stepofweb.com

	Part of:
		GULP/src/plugins/sow_pack/sow_search_suggest.js

**/
form.sow-search {
  position: relative; }

form.sow-search.sow-search-over {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
  z-index: 9999;
  -webkit-animation: fadeIn 0.6s;
  animation: fadeIn 0.6s; }

form.sow-search.hide {
  display: none !important; }

form.sow-search .sow-search-backdrop {
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10; }

form.sow-search .sow-search-input,
form.sow-search .sow-search-container {
  position: relative;
  z-index: 100; }

form.sow-search .sow-search-container {
  top: 100%;
  position: absolute;
  color: #000000;
  border-radius: 0.25rem; }

form.sow-search .sow-search-container > .sow-search-container-wrapper {
  background-color: #fff;
  border-radius: 0.25rem;
  width: 100%; }

form.sow-search ul.list-suggestion > li > a {
  color: #a1a8ae;
  position: relative;
  text-decoration: none;
  background-color: transparent;
  display: block;
  padding: 8px 15px; }

form.sow-search ul.list-suggestion > li > a > i {
  margin-right: 6px; }

form.sow-search ul.list-suggestion > li.active > a,
form.sow-search ul.list-suggestion > li:hover > a {
  background-color: #f8f9fa; }

form.sow-search .sow-search-container:before {
  content: '';
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -10px;
  left: 5%; }

/* styling : floating layout; */
form.sow-search.sow-search-over .sow-search-input > .input-group-over {
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px; }

form.sow-search.sow-search-over .sow-search-input > .input-group-over .sow-search-buttons {
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 6px;
  padding-right: 6px; }

form.sow-search .sow-search-container {
  top: 66px; }

form.sow-search.sow-search-over .sow-search-container {
  top: 96px; }

/* make sure will stay the same on form size change */
form.sow-search input.form-control-sow-search.form-control-lg:not(.form-control-custom) {
  height: 50px !important;
  padding-top: .58rem !important;
  padding-bottom: .58rem !important;
  font-size: 1.3rem !important;
  font-weight: 200; }

form.sow-search input.form-control-sow-search:not(.form-control-custom):not(.form-control-lg) {
  height: 50px !important;
  font-size: 1rem !important; }

/* theme color customize */
/* highlighted term (if JS fuzzysearch plugin used) */
.sow-search-content a > em {
  font-style: normal;
  color: #000000; }

/*
	MOBILE
*/
@media only screen and (max-width: 991px) {
  form.sow-search:not(.sow-search-mobile) {
    display: none; }
  form.sow-search-mobile {
    position: fixed !important;
    top: 18px !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999;
    margin: 0 !important; }
  form.sow-search-mobile .sow-search-container {
    position: fixed;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
    top: 96px;
    bottom: 15px; }
  form.sow-search-mobile .sow-search-input,
  form.sow-search-mobile .sow-search-container {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px) !important; }
  form.sow-search-mobile .sow-search-input input {
    background-color: #fff !important;
    padding-right: 87px; }
  /* white, top fixed layout instead of floating */
  form.sow-search-mobile .sow-search-input > .input-group-over {
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  form.sow-search-mobile input.form-control-sow-search,
  form.sow-search-mobile input.form-control-sow-search.form-control-lg {
    font-size: 1rem !important;
    color: #121212 !important;
    border: 0 !important;
    padding-left: 10px; }
  form.sow-search-mobile input.form-control-sow-search.text-white::-webkit-input-placeholder {
    color: #a1a8ae !important; }
  form.sow-search-mobile input.form-control-sow-search.text-white::-moz-placeholder {
    color: #a1a8ae !important; }
  form.sow-search-mobile .sow-search-input > .input-group-over .sow-search-buttons,
  form.sow-search-mobile .sow-search-input > .input-group-over .sow-search-buttons a > i,
  form.sow-search-mobile .sow-search-input > .input-group-over .sow-search-buttons button > i {
    margin: 0 !important;
    padding: 0 !important;
    color: #6c757d !important; }
  form.sow-search-mobile .sow-search-input > .input-group-over .sow-search-buttons > .btn-sow-search-toggler {
    background-color: transparent !important;
    border: 0 !important;
    width: 52px !important;
    text-align: center;
    border-radius: 0 !important;
    border-left: #f8f9fa 1px solid !important; }
  /* fix button icon spacing - default added by components */
  form.sow-search-mobile .sow-search-input > .input-group-over .sow-search-buttons > .btn-sow-search-toggler > i {
    margin: 0 !important; } }

img.lazy {
  -webkit-transition: -webkit-filter 0.3s cubic-bezier(0.4, 0.8, 0.74, 1);
  transition: -webkit-filter 0.3s cubic-bezier(0.4, 0.8, 0.74, 1);
  transition: filter 0.3s cubic-bezier(0.4, 0.8, 0.74, 1);
  transition: filter 0.3s cubic-bezier(0.4, 0.8, 0.74, 1), -webkit-filter 0.3s cubic-bezier(0.4, 0.8, 0.74, 1);
  -webkit-filter: blur(0);
  filter: blur(0); }
